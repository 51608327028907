import { Children } from 'interfaces'

interface Props extends Children {
  isTrue: boolean
}
export const RenderIf = (props: Props) => {
  const { isTrue, children } = props
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isTrue ? <>{children}</> : null
}
