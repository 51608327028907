import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Translation } from 'interfaces'
import { errorToastMessage, successToastMessage } from 'utils'
import { routePaths } from 'routes'
import { messagesService, MessageUserType } from '../index'

export const messageAction = (queryClient: QueryClient, t: Translation) => async (
  { request }:ActionFunctionArgs
) => {
  try {
    const { method } = request
    const formData = await request.formData()

    const message = formData.get('message') as string
    const userType = formData.get('userType') as MessageUserType
    let toastMessage = ''

    if (method === 'POST') {
      await messagesService.createMessage(message, userType)
      toastMessage = 'messageHasBeenCreated'
    }

    if (method === 'PATCH') {
      const id = formData.get('id') as string
      await messagesService.updateMessage(id, message, userType)
      toastMessage = 'messageHasBeenUpdated'
    }

    if (method === 'DELETE') {
      const id = formData.get('id') as string
      await messagesService.deleteMessage(id)
      toastMessage = 'messageHasBeenDeleted'
    }

    await queryClient.invalidateQueries(['messages'])
    successToastMessage(t(toastMessage))
    if (userType === 'Driver') return redirect(`${routePaths.messaging}/${routePaths.driverToClient}`)
    return { response: 'ok' }
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}

export const cancellationMessageAction = (queryClient: QueryClient, t: Translation) => async (
  { request }:ActionFunctionArgs
) => {
  try {
    const { method } = request
    const formData = await request.formData()

    const message = formData.get('message') as string
    let toastMessage = ''

    if (method === 'POST') {
      await messagesService.createCancellationMessages(message)
      toastMessage = 'messageHasBeenCreated'
    }

    if (method === 'PATCH') {
      const id = formData.get('id') as string
      await messagesService.updateCancellationMessages(id, message)
      toastMessage = 'messageHasBeenUpdated'
    }

    if (method === 'DELETE') {
      const id = formData.get('id') as string
      await messagesService.deleteCancellationMessages(id)
      toastMessage = 'messageHasBeenDeleted'
    }

    await queryClient.invalidateQueries(['cancellationMessages'])
    successToastMessage(t(toastMessage))
    return { response: 'ok' }
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}
