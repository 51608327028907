import { driverStatusColor } from '../../utils/driver-status-color'
import { DriverStatus } from '../../interfaces/driver'

interface Props {
  status: DriverStatus
}
export const DriverStatusIndicator = (props:Props) => {
  const { status } = props
  return (
    <div className="flex items-center gap-2">
      <div className={`rounded-full h-[18px] w-[18px] ${driverStatusColor(status)}`} />
      <p className="lowercase">
        {status}
      </p>
    </div>
  )
}
