import axios from 'axios'
import { tokenUtils } from 'features/authentication'
import { toast } from 'react-toastify'

interface ResponseInterceptorTypes {
  logout: () => void
  toastMsg: string
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

axiosInstance.interceptors.request.use(
  (request) => {
    if (!request.url?.includes('token')) {
      const token = tokenUtils.accessToken()
      if (token && request.headers) {
        request.headers.Authorization = `Bearer ${token}`
      }
    }
    return request
  },
  (error) => Promise.reject(error)
)

export const responseInterceptor = ({ logout, toastMsg }: ResponseInterceptorTypes) => {
  axiosInstance.interceptors.response.use(
    (response) => response,

    (error) => {
      const originalConfig = error.config
      if (originalConfig.url !== '/token/' && error.response) {
        // eslint-disable-next-line no-underscore-dangle
        if (error.response.status === 401 && !originalConfig._retry) {
          // eslint-disable-next-line no-underscore-dangle
          originalConfig._retry = true
          logout()
          toast.error(toastMsg, {
            toastId: 'logoutId'
          })
        }
      }
      return Promise.reject(error)
    }
  )
}
