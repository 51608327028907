import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { errorToastMessage, successToastMessage } from 'utils'
import { AxiosError } from 'axios'
import { routePaths } from 'routes'
import { Translation } from 'interfaces'
import { toast } from 'react-toastify'
import { authService, LoginFormData, tokenUtils } from '../index'

export const loginAction = (t: Translation) => async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData()
  const values = Object.fromEntries(formData) as LoginFormData
  try {
    await authService.sendCaptcha(values.captchaToken)

    await authService.login(values)
    return redirect(routePaths.home)
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    tokenUtils.deleteTokens()
    return null
  }
}

export const forgotPasswordAction = (t: Translation) => async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData()
  const email = formData.get('email') as string
  try {
    await authService.forgotPassword(email)
    successToastMessage(t('anEmailHasBeenSentToYourEmail'))
    return redirect(`${routePaths.resetPassword}?email=${email}`)
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    tokenUtils.deleteTokens()
    return null
  }
}

export const resetPasswordAction = (t: Translation) => async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData()
  const isResendCode = formData.has('email')
  try {
    if (isResendCode) {
      const email = formData.get('email') as string
      await authService.forgotPassword(email)
      successToastMessage(t('anEmailHasBeenSentToYourEmail'))
      return null
    }
    const code = formData.get('code') as string
    const password = formData.get('password') as string
    await authService.resetPassword(code, password)
    successToastMessage(t('yourPasswordHasBeenChanged'))
    return redirect(routePaths.login)
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    tokenUtils.deleteTokens()
    return null
  }
}
