import { useNavigation, useSubmit, LoaderFunctionArgs, redirect, Link } from 'react-router-dom'
import { Form, FormGroup } from 'components/form'
import { PrimaryButton, Text, Title } from 'components/ui'
import { routePaths } from 'routes'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { validationSchema } from '../utils/validation-schema'
import { LoginFormData } from '../interfaces/login-form-data'
import { isAlreadyAuthenticated } from '../utils/user-utils'
import { useWidth } from '../../../hooks'

export const loader = ({ request }: LoaderFunctionArgs) => {
  const toastMessageId = 'message-id'
  const message = new URL(request.url).searchParams.get('message')
  if (message) {
    toast.warn(message, {
      toastId: toastMessageId
    })
  }

  if (isAlreadyAuthenticated()) return redirect(routePaths.home)

  return null
}

export const LoginForm = () => {
  const submit = useSubmit()
  const navigation = useNavigation()
  const { width } = useWidth()

  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const formik = useFormik<LoginFormData>({
    initialValues: {
      email: '',
      password: '',
      captchaToken: ''
    },
    validationSchema: validationSchema.loginForm,
    onSubmit: async (values) => {
      if (values.captchaToken?.trim() === '') {
        toast.warning(t('pleaseCompleteCaptcha'))
        return
      }
      submit(values, { method: 'post' })
    }
  })

  useEffect(() => {
    queryClient.clear()
  }, [queryClient])

  const onCaptchaError = () => {
    formik.setFieldValue('captchaToken', '')
  }

  return (
    <div className="p-5 bg-light-gray rounded-t-[40px] sm:w-1/2 sm:rounded-r-[64px] sm:rounded-tl-[0px] flex flex-col items-center justify-center">
      <div className="flex flex-col gap-2 mb-5 sm:mb-14">
        <Title title="welcomeBack" size="lg" />
        <Text text="enterDetails" size="xl" color="gray" />
      </div>
      <Form
        method="post"
        onSubmit={formik.handleSubmit}
        className="max-w-[485px] w-full flex flex-col gap-6"
      >
        <FormGroup
          id="email"
          label="email"
          placeholder={t('insertEmail')}
          hasError={!!(formik.touched.email && formik.errors.email)}
          errorText={formik.errors.email}
          labelSizes="lg"
          inputSizes="lg"
          {...formik.getFieldProps('email')}
        />
        <FormGroup
          id="password"
          label="password"
          type="password"
          placeholder={t('insertPassword')}
          hasError={!!(formik.touched.password && formik.errors.password)}
          errorText={formik.errors.password}
          labelSizes="lg"
          inputSizes="lg"
          {...formik.getFieldProps('password')}
        />
        <div className="flex items-center justify-center">
          <HCaptcha
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
            onExpire={onCaptchaError}
            size={width && width <= 375 ? 'compact' : 'normal'}
            onError={onCaptchaError}
            onClose={onCaptchaError}
            onVerify={(token) => formik.setFieldValue('captchaToken', token)}
          />
        </div>

        <PrimaryButton
          text="login"
          type="submit"
          isLoading={navigation.state === 'submitting'}
          size="lg"
        />
        <Link
          to={routePaths.forgotPassword}
          className="mx-auto text-primary-black"
        >
          {t('forgotPassword')}
        </Link>
      </Form>
    </div>
  )
}
