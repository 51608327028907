import { BackButton, Card, RenderIf } from 'components/ui'
import { MessagesModal } from 'features/common'
import { Ride } from '../../interfaces/ride'
import { RideStatusTitle } from './RideStatusTitle'
import { RideAddresses } from './RideAddresses'
import { RideCost } from './RideCost'
import { RideDetails } from './RideDetails'
import { RideRating } from './RideRating'

interface Props {
  ride: Ride
}
export const DisplayRide = (props:Props) => {
  const { ride } = props
  return (
    <>
      <MessagesModal />
      <Card className="p-7 mb-[30px] flex justify-between gap-5 flex-wrap sm:flex-nowrap">
        <div className="w-full flex gap-2 flex-wrap flex-col">
          <div className="flex items-center flex-wrap gap-1">
            <RideStatusTitle status={ride.status} rideId={ride.id} />
            <RenderIf isTrue={ride.status === 'Cancelled'}>
              <div>
                <p>
                  <span className="text-dark-red">{`by ${ride.cancelled_by}`}</span>
                  {' '}
                  -
                  {' '}
                  <span className="text-gray">
                    {`Reason: ${ride.cancelled_reason}`}
                  </span>
                </p>
              </div>
            </RenderIf>
          </div>
          <div className="mt-10 flex lg:flex-row flex-col gap-10">
            <RideAddresses
              arrivedToPickUp={ride.arrived_to_pick_up}
              arrivedToDropOff={ride.arrived_to_drop_off}
              pickUpAddress={ride.pick_up_address}
              dropOffAddress={ride.drop_off_address}
              acceptedTime={ride.accepted_time}
              cancelledTime={ride.cancelled_time}
              paymentTime={ride.payment_time}
              scheduledTime={ride.scheduled_datetime}
              createdTime={ride.created}
              isScheduled={ride.is_scheduled}
              isCancelledByDriver={ride.cancelled_by === 'Driver'}
            />
            <RideCost
              total={ride.price}
              receiptLink={ride.payment?.[0]?.receipt_link}
              discount={ride.discount}
              amountBeforeDiscount={ride.amount_before_discount}
            />
            <RideDetails
              driversFullName={`${ride.driver?.user?.first_name ?? '-'} ${ride.driver?.user?.last_name ?? '-'}`}
              clientFullName={`${ride.client.user?.first_name ?? '-'} ${ride.client.user?.last_name ?? '-'}`}
              routeId={ride.id}
              passengers={ride.passengers_number ?? 0}
              luggage={ride.luggage_number ?? 0}
              messageCount={ride.message_count}
            />
          </div>
        </div>
        <RideRating rating={ride.driver_rating} />
      </Card>
      <BackButton />
    </>
  )
}
