import { FaCar, FaIdCard, FaRoad, FaTelegramPlane, FaUserFriends } from 'react-icons/fa'
import { UserAnalytics } from '../../interfaces/analytics'
import AnalyticsCard from './AnalyticsCard'
import { RenderIf } from '../../../../components/ui'
import { isAdmin } from '../../../authentication/utils/user-utils'

interface Props {
  analytics: UserAnalytics
}

export const DisplayDashboardAnalytics = (props: Props) => {
  const { analytics } = props

  return (
    <div className="xl:flex xl:justify-between grid md:grid-cols-2 gap-5 sm:gap-8 ">
      <AnalyticsCard icon={FaIdCard} count={analytics?.total_drivers ?? 0} label="totalDrivers" />
      <AnalyticsCard icon={FaCar} count={analytics?.drivers_online_count ?? 0} label="driversOnline" />
      <AnalyticsCard icon={FaTelegramPlane} count={analytics?.routes_count ?? 0} label="totalRides" />
      <RenderIf isTrue={!isAdmin()}>
        <AnalyticsCard icon={FaRoad} count={analytics?.drivers_on_route_count ?? 0} label="driversOnRoute" />
      </RenderIf>
      <RenderIf isTrue={isAdmin()}>
        <AnalyticsCard icon={FaUserFriends} count={analytics?.clients_count ?? 0} label="totalClients" />
      </RenderIf>
    </div>
  )
}
