import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { Employer } from '../../interfaces/employer'
import { LocalApiError } from '../../../../components/ui'
import { TableSkeleton } from '../../../../components/skeletons'
import { EmployersTable } from './EmployersTable'
import { ModalProvider } from '../../../common'
import { useEmployers } from '../../../../hooks/useEmployers'

export const EmployersLoader = () => {
  const { employers } = useLoaderData() as { employers: Employer[] }
  const { data } = useEmployers(employers)
  return (
    <Suspense fallback={<TableSkeleton />}>
      <Await resolve={employers} errorElement={<LocalApiError message="failedToLoadEmployers" />}>
        <ModalProvider>
          <EmployersTable employers={data!} />
        </ModalProvider>
      </Await>
    </Suspense>
  )
}
