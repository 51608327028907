import { useTranslation } from 'react-i18next'
import { TransactionPaymentMethod } from './TransactionPaymentMethod'

interface Props {
  total: string
  cardType: string | null
  paymentMethod: string | null
}
export const TransactionCost = (props:Props) => {
  const { total, cardType, paymentMethod } = props

  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-7 md:pr-10 md:border-r md:border-r-[#a9a9a9] last:border-0 w-full">
      <div className="flex flex-col gap-2 justify-between flex-wrap h-max">
        <h3 className="text-[20px] font-bold">{t('fareBreakdown')}</h3>
        <TransactionPaymentMethod paymentMethod={paymentMethod} cardType={cardType} />
      </div>
      <div className="flex items-center justify-between">
        <p className="text-gray">{t('total')}</p>
        <p className="text-[20px] font-bold">
          &euro;
          {total}
        </p>
      </div>
    </div>
  )
}
