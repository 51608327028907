import { AxiosResponse } from 'axios'
import { BaseApi } from '../../../services'
import { EmployersServiceInterface } from './employers-service.interface'
import { Employer } from '../interfaces/employer'

class EmployersService extends BaseApi implements EmployersServiceInterface {
  readonly endpoint = '/auth/employer/'

  public async getEmployers():Promise<Employer[]> {
    return this.get<Employer[]>(this.endpoint)
  }

  public async getEmployer(id: string):Promise<Employer> {
    return this.get<Employer>(`${this.endpoint}${id}/`)
  }

  public async createEmployer(formData:FormData):Promise<AxiosResponse> {
    return this.post(this.endpoint, formData)
  }

  public async updateEmployer(formData:FormData, id: string):Promise<AxiosResponse> {
    formData.delete('password')
    formData.delete('email')
    const employerFilesKeys = ['photo', 'iban_file', 'identification_card']
    // Delete files if they are not instances of File
    employerFilesKeys.forEach((key) => {
      if (!(formData.get(key) instanceof File)) formData.delete(key)
    })

    return this.patch(`${this.endpoint}${id}/`, formData)
  }

  public async deleteEmployer(id: string):Promise<AxiosResponse> {
    return await this.delete(`/auth/user/${id}/`)
  }

  public employerTableExport = async ():Promise<AxiosResponse> => this.post(`${this.endpoint}export/`, {}, {
    responseType: 'blob'
  })
}

export default new EmployersService()
