import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSubmit } from 'react-router-dom'
import { ChangeEvent, useEffect, useMemo } from 'react'
import { Promotion, PromotionFormValues } from '../interfaces/promotion'
import { Form, FormActions, FormCheckbox, FormGroup, FormReactSelect } from '../../../components/form'
import { validationSchema } from '../index'
import { Client } from '../../clients'

interface Props {
  promotion? :Promotion
  clients?: Client[]
  method: 'post' | 'patch'
}

interface SelectOption { value: number, label: string }
export const PromotionForm = (props:Props) => {
  const { promotion, method, clients } = props
  const { t } = useTranslation()
  const submit = useSubmit()
  const formik = useFormik<PromotionFormValues>({
    initialValues: {
      title: promotion?.title ?? '',
      startDate: promotion?.promotion_start_date ?? '',
      endDate: promotion?.promotion_end_date ?? '',
      discount: promotion?.discount ?? '',
      usesLimit: promotion?.redemption_limit === -1 ? '' : promotion?.redemption_limit ?? '',
      promotion_client: promotion?.promotion_client?.map((client) => client.client.id) ?? [],
      noEndDate: promotion ? !promotion?.promotion_end_date : true,
      unlimited: promotion ? promotion.redemption_limit === -1 : true
    },
    validationSchema: validationSchema.promotion,
    onSubmit: (values) => {
      submit({ values: JSON.stringify(values) }, { method })
    }
  })

  const { setFieldValue } = formik
  const { endDate, usesLimit } = formik.values
  useEffect(() => {
    if (endDate) {
      setFieldValue('noEndDate', false)
    }
    if (usesLimit) {
      setFieldValue('unlimited', false)
    }
  }, [setFieldValue, endDate, usesLimit])

  const transformedClients = useMemo(() => clients?.map((client) => ({
    value: client.id,
    label: `${client.user.first_name} ${client.user.last_name}`
  })) ?? [], [clients])

  return (
    <Form
      method={method}
      onSubmit={formik.handleSubmit}
      className="grid gap-5 sm:gap-12"
    >
      <div className="flex w-full gap-5 md:gap-12 flex-col md:flex-row">
        <FormGroup
          label="title"
          placeholder={t('title')}
          hasError={!!(formik.errors.title && formik.touched.title)}
          errorText={formik.errors.title}
          helperText="promotionTitleHelperText"
          {...formik.getFieldProps('title')}
        />
        <div className="w-full flex items-end gap-5 flex-col sm:flex-row">
          <FormGroup
            type="date"
            label="duration"
            hasError={!!(formik.touched.startDate
                    && formik.errors.startDate)}
            errorText={formik.errors.startDate}
            {...formik.getFieldProps('startDate')}
          />
          <div className="w-full flex flex-col gap-2 flex-end sm:translate-y-7">
            <FormGroup
              type="date"
              hasError={!!(formik.touched.endDate
                    && formik.errors.endDate)}
              errorText={formik.errors.endDate}
              {...formik.getFieldProps('endDate')}
            />
            <FormCheckbox
              id="noEndDate"
              label="noEndDate"
              checked={formik.values.noEndDate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setFieldValue('endDate', '')
                }

                setFieldValue('noEndDate', e.target.checked)
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-5 md:gap-12  flex-col sm:flex-row">
        <FormGroup
          type="number"
          min={0}
          max={100}
          label="discount"
          placeholder={t('discount')}
          hasError={!!(formik.errors.discount && formik.touched.discount)}
          errorText={formik.errors.discount}
          helperText="percentageOff"
          {...formik.getFieldProps('discount')}
          className="md:w-[301px] w-full"
        />
        <div className="w-full flex flex-col gap-2">
          <FormGroup
            type="number"
            min={1}
            label="codeRedemptionLimit"
            placeholder={t('timeOfUsage')}
            hasError={!!(formik.errors.usesLimit && formik.touched.usesLimit)}
            errorText={formik.errors.usesLimit}
            {...formik.getFieldProps('usesLimit')}
            className="md:w-[301px] w-full"
            errorSpacing="xl"
          />
          <FormCheckbox
            id="unlimited"
            label="unlimited"
            checked={formik.values.unlimited}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                setFieldValue('usesLimit', '')
              }

              setFieldValue('unlimited', e.target.checked)
            }}
          />
        </div>
      </div>
      <div className="sm:w-1/2 lg:w-1/3">
        <FormReactSelect
          options={transformedClients}
          placeholder={t('chooseFromClientList')}
          isMulti
          value={transformedClients?.filter(
            (client: SelectOption) => formik.values.promotion_client.includes(client.value)
          )}
          onChange={(value) => {
            setFieldValue('promotion_client', (value as SelectOption[]).map((item) => item.value))
          }}
        />
      </div>
      <FormActions<PromotionFormValues> resetForm={formik.resetForm} />
    </Form>
  )
}
