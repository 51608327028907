import { defer, LoaderFunctionArgs } from 'react-router-dom'
import { QueryClient } from '@tanstack/react-query'
import { promotionQuery, promotionsQuery } from './queries'
import { isAlreadyAuthenticated } from '../../authentication'
import { authRedirect } from '../../../utils'
import { isAdmin } from '../../authentication/utils/user-utils'

export const promotionsLoader = (queryClient:QueryClient) => () => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    promotions: queryClient.ensureQueryData(promotionsQuery())
  })
}

export const promotionLoader = (queryClient:QueryClient) => ({ params }:LoaderFunctionArgs) => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()
  const { id } = params as { id: string }
  return defer({
    promotion: queryClient.ensureQueryData(promotionQuery(id))
  })
}
