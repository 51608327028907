import { ChangeEvent, ReactElement, RefObject, useRef, useState } from 'react'
import { useMutateData } from 'hooks'
import feesService from '../services/fees-service'

interface Props {
  id: number
  value: string;
  children(data:RefObject<HTMLInputElement>): ReactElement
}
export const PriceInput = (props:Props) => {
  const { id, value, children } = props
  const [inputValue, setInputValue] = useState<string>(Number(value).toFixed(2))
  const ref = useRef<HTMLInputElement>(null)

  const { mutate: updateZonePrice } = useMutateData({
    key: 'zones',
    mutationFn: feesService.updateZonePrice,
    successMessage: 'zonePriceHasBeenUpdated'
  })
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Remove all characters except numbers and dot
    const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '')
    setInputValue(sanitizedValue)
  }
  return (
    <div className="flex items-center w-full">
      <input
        ref={ref}
        className="text-center w-[55px] bg-transparent outline-primary-black"
        value={inputValue}
        onBlur={() => setInputValue(value)}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event?.key === 'Enter') {
            if (Number(inputValue).toFixed(2) !== Number(value).toFixed(2)) {
              updateZonePrice({ id, price: Number(inputValue).toFixed(2) })
            }
          }
        }}
      />
      {children(ref)}
    </div>
  )
}
