const NewRidesCardSkeleton = () => {
  const classes = 'bg-light-gray min-h-[24px] w-full animate-pulse rounded-[10px]'
  return (
    <div className="h-[137.5px] p-5 w-full bg-white shadow-card rounded-[10px] animate-pulse">
      <div className="grid grid-cols-2 items-center flex-wrap gap-3 w-full">
        <div className="w-full grid gap-1  max-w-[150px]">
          <div className={classes} />
          <div className={classes} />
        </div>
        <div className="w-full  max-w-[60px] justify-self-end">
          <div className={classes} />
        </div>
      </div>
      <div className="flex justify-between items-center border-t border-t-[#f6f6f6] pt-3 mt-3">
        <div className="flex items-center gap-[10px] w-full">
          <div className={`${classes} max-w-[150px]`} />
          <div className={`${classes} max-w-[40px]`} />
        </div>
        <div className={`${classes} max-w-[80px]`} />
      </div>
    </div>
  )
}
export const DisplayNewRidesSkeleton = () => (
  <div className="flex flex-col gap-5">
    <NewRidesCardSkeleton />
    <NewRidesCardSkeleton />
    <NewRidesCardSkeleton />
  </div>
)
