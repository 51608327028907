import { Card } from 'components/ui'

const SkeletonItem = ({ width = 'w-[150px]', height = 'h-[20px]' }
:{ width?: string, height?: string }) => <div className={`${width} ${height} rounded-[10px] bg-light-gray animate-pulse" />`} />

const SkeletonFileItem = () => (
  <div className="lg:gap-0 px-6 text-gray lg:border-r-2 lg:border-r-light-gray last:md:border-r-0">
    <SkeletonItem width="w-[230px]" height="h-[30px]" />
    <div className="w-full aspect-[3/2] my-6 rounded-[10px] bg-light-gray animate-pulse" />
    <div className="grid gap-5">
      <SkeletonItem width="w-[200px]" height="h-[25px]" />
      <SkeletonItem width="w-[200px]" height="h-[25px]" />
      <SkeletonItem width="w-[200px]" height="h-[25px]" />
    </div>
  </div>
)
export const DriverApplicationSkeleton = () => (
  <div className="grid gap-6">
    <Card className="p-6 animate-pulse min-h-[188px]">
      <div className="flex items-center justify-between flex-wrap gap-2">
        <SkeletonItem width="w-[200px]" height="h-[35px]" />
        <SkeletonItem />
      </div>
      <div className="flex items-center justify-between mt-5 gap-2 flex-wrap-reverse">
        <div className="flex gap-2 md:gap-5 lg:gap-20 flex-wrap">
          <SkeletonItem width="w-[280px]" height="h-[35px]" />
          <SkeletonItem width="w-[280px]" height="h-[35px]" />
        </div>
        <div className="w-[75px] h-[75px] rounded-full bg-light-gray animate-pulse" />
      </div>
    </Card>

    <Card className="grid md:grid-cols-2 gap-6 p-6 animate-pulse min-h-[152px]">
      <div className="md:border-r-2 md:border-r-light-gray">
        <SkeletonItem width="w-[200px]" height="h-[35px]" />
        <div className="grid xl:grid-cols-2 gap-y-3 mt-4">
          {Array.from({ length: 3 }, (_, i) => <SkeletonItem key={i} />)}
        </div>
      </div>
      <div>
        <SkeletonItem width="w-[200px]" height="h-[35px]" />
        <div className="grid gap-y-3 mt-4">
          <SkeletonItem />
          <SkeletonItem />
        </div>
      </div>
    </Card>

    <Card className="animate-pulse py-6 grid sm:grid-cols-2 lg:grid-cols-4 gap-y-6 lg:gap-y-0">
      {Array.from({ length: 4 }, (_, i) => <SkeletonFileItem key={i} />)}
    </Card>
  </div>
)
