import * as yup from 'yup'
import { passwordRegex } from '../../../helpers/password-regex'

export const validationSchema = {
  adminForm: (method: 'post' | 'patch') => yup.object({
    firstName: yup.string().trim().required('firstNameRequired'),
    lastName: yup.string().trim().required('lastNameRequired'),
    email: yup.string().trim().email('invalidEmail').required('emailRequired'),
    phone: yup.string().trim().required('phoneRequired'),
    password: method === 'post' ? yup.string().trim().matches(passwordRegex, 'passwordRegex').required('passwordRequired') : yup.string().trim().optional()
  }),
  editAdmin: {}
}
