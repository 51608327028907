interface Props {
  title:string
}
export const CardTitle = (props:Props) => {
  const { title } = props
  return (
    <h2 className="text-[30px] font-semibold">
      {title}
    </h2>
  )
}
