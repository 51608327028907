import React from 'react'
import { RenderIf } from './RenderIf'

interface Props {
  id?: number
  children: React.ReactNode
}
export const SinglePageIdWrapper = (props:Props) => {
  const { id, children } = props
  return (
    <div className="flex gap-2">
      {children}
      <RenderIf isTrue={!!id}>
        <p className="text-xl sm:text-[26px] font-bold text-primary-black">{`#${id}`}</p>
      </RenderIf>
    </div>
  )
}
