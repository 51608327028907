import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Driver } from '../../interfaces/driver'
import { TableSkeleton } from '../../../../components/skeletons'
import { LocalApiError } from '../../../../components/ui'
import { ModalProvider } from '../../../common'
import { DriversTable } from './DriversTable'
import { routePaths } from '../../../../routes'
import { deniedDriversQuery } from '../../helpers/queries'
import { driversService } from '../../index'

export const DeniedDriversLoader = () => {
  const { deniedDrivers } = useLoaderData() as { deniedDrivers: Driver[] }
  const { data } = useQuery({ ...deniedDriversQuery(), initialData: deniedDrivers })

  return (
    <Suspense fallback={<TableSkeleton />}>
      <Await resolve={deniedDrivers} errorElement={<LocalApiError message="failedToLoadDrivers" />}>
        <ModalProvider>
          <DriversTable
            path={routePaths.drivers}
            drivers={data}
            exportAction={driversService.driverTableExport}
          />
        </ModalProvider>
      </Await>
    </Suspense>
  )
}
