import { useTranslation } from 'react-i18next'
import { FaStar } from 'react-icons/fa'
import { CiStar } from 'react-icons/ci'

interface Props {
  rating: number | null
}
export const RideRating = (props:Props) => {
  const { rating } = props
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-center gap-3">
      <p className="whitespace-nowrap text-gray text-lg font-['Space_Grotesk']">{t('rideRating')}</p>
      <div className="h-[75px] w-[75px] bg-[#F2F1F1] rounded-full flex items-center justify-center">
        {!!rating && <FaStar color="#0E0A09" size={32} />}
        {!rating && <CiStar className="text-gray" size={40} />}
      </div>
      <p>{rating?.toFixed(1) ?? '-'}</p>
    </div>
  )
}
