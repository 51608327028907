import { useMemo } from 'react'
import { routePaths } from 'routes'
import { Driver } from 'features/drivers'
import { UnderlineLink } from 'components/ui'
import { NewDriverCard } from './NewDriverCard'

interface Props {
  drivers: Driver[]
}
const DisplayNewDrivers = (props:Props) => {
  const { drivers = [] } = props

  // Sort drivers by date_joined descending
  const sortedFromNewestDrivers = useMemo(
    () => drivers.sort(
      (b, a) => a.user.date_joined.localeCompare(b.user.date_joined)
    ).slice(0, 5),
    [drivers]
  )

  return (
    <>
      <div className="flex flex-col gap-5">
        {sortedFromNewestDrivers.map((driver) => (
          <NewDriverCard
            key={driver.id}
            id={driver.id}
            firstName={driver.user.first_name}
            lastName={driver.user.last_name}
            email={driver.user.email}
            phone={driver.user.phone_number}
            status={driver.status}
          />
        ))}
      </div>
      <div className="flex justify-end mt-3">
        <UnderlineLink path={`${routePaths.drivers}`} label="viewAll" />
      </div>
    </>

  )
}

export default DisplayNewDrivers
