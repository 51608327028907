import { ConfirmationModal, ModalProvider } from 'features/common'
import { CancellationMessage } from '../../interfaces/cancellation-message'
import { MessageForm } from '../default-messages/MessageForm'
import { CancellationMessageCard } from './CancellationMessageCard'

interface Props {
  cancellationMessages: CancellationMessage[]
}
export const CancellationMessagingList = (props:Props) => {
  const { cancellationMessages = [] } = props
  return (
    <ModalProvider>
      <div>
        <MessageForm helperText="cancellationHelperText">
          {(handleUpdateMessage) => (
            <div className="mt-8 grid gap-5">
              {cancellationMessages.map((message) => (
                <CancellationMessageCard
                  message={message}
                  handleUpdateMessage={handleUpdateMessage}
                  key={message.id}
                />
              ))}
            </div>
          )}
        </MessageForm>
      </div>
      <ConfirmationModal />
    </ModalProvider>
  )
}
