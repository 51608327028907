import { dashboardService } from '../index'

export const analyticsQuery = () => ({
  queryKey: ['analytics'],
  queryFn: () => dashboardService.getAnalytics()
})

export const employerAnalyticsQuery = () => ({
  queryKey: ['employerAnalytics'],
  queryFn: () => dashboardService.getEmployerAnalytics()
})
