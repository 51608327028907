import { NavigationDesktop } from './NavigationDesktop'
import { NavigationMobile } from './NavigationMobile'
import { NavMenu } from '../interfaces/nav-menu'
import { userUtils } from '../../authentication'

export const Navigation = (props:NavMenu) => {
  const { isOpen, setIsOpen } = props
  const authUser = userUtils.getUser()
  const isEmployer = authUser?.user.user_type === 'Employer'
  return (
    <>
      {/* Desktop-Laptop: min-width: 768px */}
      <NavigationDesktop isEmployer={isEmployer} />

      {/* Mobile-Tablet:  max-width: 768px */}
      <NavigationMobile isEmployer={isEmployer} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}
