import { useNavigate } from 'react-router-dom'
import { routePaths } from '../routes'

export const useLogout = () => {
  const navigate = useNavigate()
  const onLogoutHandler = () => {
    localStorage.clear()
    navigate(routePaths.login)
  }

  return {
    logout: onLogoutHandler
  }
}
