import { RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Slide, ToastContainer } from 'react-toastify'

import { RenderIf } from 'components/ui'
import { routes } from 'routes'

import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 20000
    }
  }
})

const App = () => {
  const { t } = useTranslation()

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={routes({ queryClient, t })} />
      <ToastContainer
        position="top-center"
        autoClose={2500}
        pauseOnFocusLoss={false}
        transition={Slide}
      />
      <RenderIf isTrue={process.env.NODE_ENV === 'development'}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </RenderIf>
    </QueryClientProvider>
  )
}

export default App
