import { Outlet } from 'react-router-dom'
import { Client } from '../../interfaces/client'
import { BackButton } from '../../../../components/ui'
import { SingleClientTabs } from './SingleClientTabs'
import { SingleClientPersonalDetails } from './SingleClientPersonalDetails'

interface Props {
  client: Client
}

export const DisplaySingleClient = (props:Props) => {
  const { client } = props
  return (
    <div className="grid">
      <SingleClientPersonalDetails client={client} />
      <SingleClientTabs />
      <div className="overflow-x-auto mb-7">
        <Outlet context={client} />
      </div>
      <BackButton />
    </div>
  )
}
