import { TabBar, TabLink } from 'features/navigation'
import { routePaths } from 'routes'

export const RidesTabBar = () => (
  <TabBar>
    <TabLink path={routePaths.rides} label="history" />
    <TabLink path={routePaths.active} label="active" />
    <TabLink path={routePaths.scheduled} label="scheduled" />
  </TabBar>
)
