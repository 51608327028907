import { useTranslation } from 'react-i18next'

interface Props {
  onClick: () => void
  label: string
}

export const UncheckedDriversRangeButton = (props:Props) => {
  const { onClick, label } = props

  const { t } = useTranslation()
  return (
    <button
      onClick={onClick}
      type="button"
      className="text-sm underline outline-none focus:outline-none"
    >
      {t(label)}
    </button>
  )
}
