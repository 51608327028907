import { Children } from 'interfaces'
import { Form as RouterForm } from 'react-router-dom'

interface Props extends Children {
  method: 'post' | 'patch' | 'delete'
  [key:string]: any
}
export const Form = (props: Props) => {
  const { method, children, ...restProps } = props
  return (
    <RouterForm
      method={method}
      {...restProps}
    >
      {children}
    </RouterForm>
  )
}
