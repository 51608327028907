import { Await, useLoaderData } from 'react-router-dom'
import { CancellationMessage, cancellationMessagesQuery, CancellationMessagingList } from 'features/messages'
import { Suspense } from 'react'
import { MessagingSkeleton } from 'components/skeletons'
import { useQuery } from '@tanstack/react-query'
import { LocalApiError } from '../../../../components/ui'

export const CancellationMessagesLoader = () => {
  const {
    cancellationMessages
  } = useLoaderData() as { cancellationMessages: CancellationMessage[] }
  const { data } = useQuery({
    ...cancellationMessagesQuery(),
    initialData: cancellationMessages
  })
  return (
    <Suspense fallback={<div className="mt-10"><MessagingSkeleton /></div>}>
      <Await resolve={cancellationMessages} errorElement={<LocalApiError message="failedToLoadMessages" />}>
        <CancellationMessagingList cancellationMessages={data} />
      </Await>
    </Suspense>
  )
}
