import { LogoMenu } from 'components/ui'
import { NavigationLink } from './NavigationLink'
import { paths } from '../helpers/paths'

interface Props {
  isEmployer: boolean
}

export const NavigationDesktop = (props:Props) => {
  const { isEmployer } = props
  return (
    <div
      className="flex-col gap-12 2xl:p-10 rounded-r-[20px] z-10 shadow-navigation bg-white max-w-[55px] hidden md:flex w-full 2xl:max-w-[330px]"
    >
      <div className=" border-b border-b-main-bg px-2 py-8 2xl:pb-12 2xl:pt-2 2xl:px-8">
        <LogoMenu />
      </div>
      <nav className="text-lg px-4 2xl:px-8 flex flex-col gap-11 2xl:gap-10">
        {paths.filter((path) => (
          isEmployer ? !path.isAdminOnly : true)).map((path) => (
            <NavigationLink
              key={path.id}
              path={path.path}
              label={path.label}
              icon={path.icon}
              activeIcon={path.activeIcon}
            />
        ))}
      </nav>
    </div>
  )
}
