import { Formik } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { DriverFormValues } from '../../../interfaces/driver-form'
import { FormGroup } from '../../../../../components/form'
import { UploadDocumentButton } from '../UploadDocumentButton'

interface Props extends Formik<DriverFormValues> {}
export const VehicleRegistrationFields = (props:Props) => {
  const { formik } = props
  const { t } = useTranslation()
  return (
    <>
      <div className="driver-form-child-container">
        <FormGroup
          label="vehicleRegistrationLicense"
          placeholder={t<string>('vehicleRegistrationLicense')}
          hasError={!!(formik.touched.vehicle_registration_license_number
            && formik.errors.vehicle_registration_license_number)}
          errorText={formik.errors.vehicle_registration_license_number}
          {...formik.getFieldProps('vehicle_registration_license_number')}
        />
        <FormGroup
          label="registrationPlateNumber"
          placeholder={t<string>('registrationPlateNumber')}
          hasError={!!(formik.touched.registration_plate_number
            && formik.errors.registration_plate_number)}
          errorText={formik.errors.registration_plate_number}
          {...formik.getFieldProps('registration_plate_number')}
        />
        <FormGroup
          label="model"
          placeholder={t<string>('model')}
          hasError={!!(formik.touched.car_model && formik.errors.car_model)}
          errorText={formik.errors.car_model}
          {...formik.getFieldProps('car_model')}
        />
      </div>
      <div className="mt-5">
        <UploadDocumentButton
          label="uploadDocument"
          formik={formik}
          formikKey="vehicle_registration_license"
          acceptedFormats=".jpg, .jpeg, .png, .pdf"
          supportedFilesText="supportedFile"
        />
      </div>
    </>
  )
}
