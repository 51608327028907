import { QueryClient } from '@tanstack/react-query'
import { defer } from 'react-router-dom'
import { authRedirect } from 'utils'
import { cancellationMessagesQuery, messagesQuery } from './queries'
import { isAlreadyAuthenticated } from '../../authentication'
import { isAdmin } from '../../authentication/utils/user-utils'

export const messagesLoader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()
  return defer({
    messages: queryClient.ensureQueryData(messagesQuery())
  })
}

export const cancellationMessagesLoader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()
  return defer({
    cancellationMessages: queryClient.ensureQueryData(cancellationMessagesQuery())
  })
}
