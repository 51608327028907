import { driversService } from '../index'

export const driversQuery = () => ({
  queryKey: ['drivers'],
  queryFn: () => driversService.getDrivers()
})

export const deniedDriversQuery = () => ({
  queryKey: ['deniedDrivers'],
  queryFn: () => driversService.getDeniedDrivers()
})

export const driverQuery = (id: string) => ({
  queryKey: ['driver', id],
  queryFn: () => driversService.getDriver(id)
})

export const uncheckedDriversQuery = () => ({
  queryKey: ['uncheckedDrivers'],
  queryFn: () => driversService.getUncheckedDrivers()
})

export const driverRatingHistoryQuery = (id: string) => ({
  queryKey: ['driverRatingHistory', id],
  queryFn: () => driversService.getRatingHistory(id)
})

export const driverRideHistoryQuery = (id: string) => ({
  queryKey: ['driverRideHistory', id],
  queryFn: () => driversService.getDriverRideHistory(id)
})

export const driverTransactionsHistoryQuery = (id: string) => ({
  queryKey: ['driverTransactionsHistory', id],
  queryFn: () => driversService.getDriverTransactionsHistory(id)
})
