import { FiLogOut } from 'react-icons/fi'
import { AxiosResponse } from 'axios'
import { useMutateData } from '../../hooks'

interface Props {
  exportAction: () => Promise<AxiosResponse>
}
export const ExportButton = (props:Props) => {
  const { exportAction } = props
  const { mutate } = useMutateData({
    key: '',
    mutationFn: () => exportAction()
  })

  const onMutate = () => {
    mutate({}, {
      onSuccess: (response: any) => {
        // response.data is csv how to export it?
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.csv')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  return (
    <button
      type="button"
      onClick={onMutate}
      className="flex items-center justify-center bg-white rounded-[10px] min-w-[55px] min-h-[55px] shadow-search"
    >
      <FiLogOut size={25} color="#545454" />
    </button>
  )
}
