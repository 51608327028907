import { useMutateData } from 'hooks'
import { AxiosResponse } from 'axios'
import { MouseEvent } from 'react'
import { useModalContext } from '../../features/common'

interface Props {
  queryKey: string | string[]
  id:number
  apiCall: (id:number) => Promise<AxiosResponse>
  isChecked: boolean
  apiMessage?: string
  dimensions?: string
  circleDimensions?: string
  circleAnimationDistance?: string
  onConfirmation?: (action: () => void)=> void
}
export const Switch = (props:Props) => {
  const {
    id,
    apiCall,
    isChecked,
    apiMessage,
    queryKey,
    dimensions = 'w-[31px] h-[17px]',
    circleDimensions = 'w-[13px] h-[13px]',
    circleAnimationDistance = 'translate-x-[12px]',
    onConfirmation } = props

  const { onClose } = useModalContext()

  const { isLoading, mutate
  } = useMutateData({
    key: queryKey,
    mutationFn: apiCall,
    successMessage: apiMessage ?? undefined,
    onSuccessCallback: onClose
  })

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (isLoading) return
    if (!onConfirmation) {
      mutate(id)
      return
    }

    onConfirmation(() => mutate(id))
  }

  const isCheckedClasses = isChecked ? `${circleAnimationDistance} bg-primary-black` : 'translate-x-0 bg-white'

  return (
    <button type="button" onClick={onClickHandler} className={`${dimensions} cursor-pointer flex items-center p-[2px] rounded-full border border-[#3E3E3E]`}>
      <div className={`${circleDimensions} block rounded-full border border-[#3E3E3E] ${isCheckedClasses} transition-all duration-300`} />
    </button>
  )
}
