import employersService from '../services/employers-service'

export const employersQuery = () => ({
  queryKey: ['employers'],
  queryFn: () => employersService.getEmployers()
})

export const employerQuery = (id: string) => ({
  queryKey: ['employer', id],
  queryFn: () => employersService.getEmployer(id)
})
