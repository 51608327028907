import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table'
import { useLocation, useNavigate } from 'react-router-dom'
import { routePaths } from '../../routes'

interface Props {
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<object> | undefined
  ) => TableBodyProps
  page: Row<object>[]
  prepareRow: (row: Row<object>) => void
  path?: string
}

export const TableBody = (props: Props) => {
  const { getTableBodyProps, page, prepareRow, path } = props
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const disabledRow = pathname === routePaths.fees
      || pathname.startsWith(routePaths.manageUsers) || pathname === routePaths.promotions

  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row, indexRow) => {
        prepareRow(row)
        const { id } = row.original as { id: number }

        const handleNavigation = () => {
          if (path) {
            navigate(`${path}/${id}`)
            return
          }
          if (pathname.startsWith(routePaths.rides)) {
            navigate(`${routePaths.rides}/${id}`)
            return
          }
          navigate(`${pathname}/${id}`)
        }
        return (
          <tr
            onClick={() => !disabledRow && handleNavigation()}
            {...row.getRowProps()}
            className={`bg-white ${!disabledRow ? 'hover:bg-[rgba(0,0,0,0.01)] transition-colors duration-300 cursor-pointer' : ''}`}
          >
            {row.cells.map((cell) => (
              <td
                className={`p-0 text-primary-black first:pl-[25px] last:pr-[25px] ${indexRow === 0 ? 'first:rounded-tl-[10px] last:rounded-tr-[10px]' : ''} `}
                {...cell.getCellProps()}
              >
                <span className="flex items-center border-b border-b-border-gray h-[65px] ">{cell.render('Cell')}</span>
              </td>
            ))}
          </tr>
        )
      })}
    </tbody>
  )
}
