import {
  ModalActionTypes,
  InitialState
} from './modal-ctx.interface'

export const modalReducer = (
  state: InitialState,
  action: ModalActionTypes
) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        showModal: true,
        message: action.payload.message,
        resourceId: action.payload.resourceId,
        callback: action.payload?.callback ?? null,
        userType: action.payload?.userType ?? null
      }
    case 'CLOSE_MODAL':
      return {
        ...state,
        showModal: false,
        message: '',
        resourceId: null,
        userType: null,
        callback: null
      }
    default:
      return state
  }
}
