import { useNavigate, useRouteError } from 'react-router-dom'
import { routePaths } from 'routes'
import { ErrorUi } from './ErrorUi'

export const ApplicationError = () => {
  const error = useRouteError() as { status: number, statusText: string }
  const navigate = useNavigate()

  if (error.status === 404) {
    return (
      <ErrorUi
        status={error.status}
        statusText={error.statusText}
        buttonText="backToHomePage"
        errorMessage="notFoundMessage1"
        errorMessage2="notFoundMessage2"
        onClickHandler={() => navigate(routePaths.home)}

      />
    )
  }

  return (
    <ErrorUi
      status={error.status}
      statusText={error.statusText}
      buttonText="reload"
      errorMessage="appErrorMessage"
      onClickHandler={() => navigate(0)}
    />
  )
}
