interface Props {
  couponCode: string
}
export const CouponCode = (props:Props) => {
  const { couponCode } = props
  return (
    <div className="rounded-[10px] bg-light-green px-5 py-2">
      <p className="text-online">{couponCode}</p>
    </div>
  )
}
