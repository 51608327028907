import ReactDOM from 'react-dom'
import { useEffect, useState } from 'react'
import { Switch } from 'components/ui'
import { useTranslation } from 'react-i18next'
import driversService from '../../services/drivers-service'
import { useModalContext } from '../../../common'

interface Props {
  driverId: number
  isBlocked: boolean
}

export const BlockDriver = (props:Props) => {
  const { driverId, isBlocked } = props
  const { t } = useTranslation()
  const { onOpen } = useModalContext()
  const [domReady, setDomReady] = useState<boolean>(false)
  useEffect(() => {
    setDomReady(true)
  }, [])

  const blockDriver = document.getElementById('block-driver') as HTMLElement
  return domReady ? ReactDOM.createPortal(
    <div className="flex items-center gap-4">
      <p>{t(isBlocked ? 'blockedUpper' : 'blockDriver')}</p>
      <Switch
        queryKey={['drivers', 'driver', driverId.toString()]}
        id={driverId}
        isChecked={isBlocked}
        apiCall={driversService.blockDriver}
        dimensions="w-[47px] h-[26px]"
        circleDimensions="w-[20px] h-[20px]"
        circleAnimationDistance="translate-x-[20px]"
        onConfirmation={(action: () => void) => onOpen({
          message: isBlocked ? 'unblockMessage' : 'blockMessage',
          resourceId: driverId,
          callback: action
        })}
      />
    </div>,
    blockDriver
  ) : null
}
