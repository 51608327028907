import { useTranslation } from 'react-i18next'
import { Formik } from 'interfaces'
import { DriverFormValues } from '../../../interfaces/driver-form'
import { VehicleRegistrationFields } from './VehicleRegistrationFields'
import { DrivingLicenseFields } from './DrivingLicenseFields'
import { ProofOfIdFields } from './ProofOfIdFields'
import { VehicleInsuranceContractFields } from './VehicleInsuranceContractFields'

interface Props extends Formik<DriverFormValues> {}
export const DriverLicenseInfoFields = (props:Props) => {
  const { formik } = props
  const { t } = useTranslation()
  return (
    <div>
      <h2 className="text-xl mb-5">{t('licenseInfo')}</h2>
      <VehicleRegistrationFields formik={formik} />
      <DrivingLicenseFields formik={formik} />
      <div className="driver-form-child-container mt-6">
        <ProofOfIdFields<DriverFormValues> formik={formik} />
      </div>
      <VehicleInsuranceContractFields formik={formik} />
    </div>
  )
}
