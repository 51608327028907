import { IoChevronForwardOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { Card } from 'components/ui'
import { routePaths } from '../../../../routes'
import { DriverStatus, DriverStatusIndicator } from '../../../drivers'
import { isAdmin } from '../../../authentication/utils/user-utils'

interface Props {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  status: DriverStatus
}
export const NewDriverCard = (props:Props) => {
  const { id, firstName, lastName, email, phone, status } = props
  const hoverLinkClasses = 'hover:opacity-80 transition-opacity duration-300'
  return (
    <Card className="text-sm shadow-card p-5 gap-5 md:gap-1 grid grid-cols-2 md:grid-cols-4 items-center justify-between">
      <div className="capitalize flex gap-1 flex-wrap">
        <p>{firstName}</p>
        <p>{lastName}</p>
      </div>
      {isAdmin() && <a href={`mailto:${email}`} className={`${hoverLinkClasses}  lowercase break-all `}>{email}</a>}
      <a href={`tel:${phone}`} className={`${isAdmin() && 'md:justify-self-end'} ${hoverLinkClasses}`}>{phone}</a>
      {!isAdmin() && <DriverStatusIndicator status={status} />}
      <Link to={`${routePaths.drivers}/${id}`} className="md:justify-self-end bg-[#F2F1F1] rounded-full w-7 h-7 flex justify-center items-center hover:bg-primary-black hover:text-white transition-colors duration-300">
        <IoChevronForwardOutline />
      </Link>
    </Card>
  )
}
