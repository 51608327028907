import { Card } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { Driver } from '../../interfaces/driver'
import { DriverFileInfo } from './DriverFileInfo'

interface FileItemProps {
  title: string
  label: string | null
}

const FileItem = (props:FileItemProps) => {
  const { title, label } = props
  const { t } = useTranslation()
  return (
    <div className="flex items-center flex-wrap gap-1">
      <p>
        {t(title)}
        :
      </p>
      <p className="font-bold">{t(label ?? '-') }</p>
    </div>
  )
}

interface Props {
  driver: Driver
}
export const DriverApplicationFiles = (props:Props) => {
  const { driver } = props

  const transformDate = (date:string | null) => {
    if (!date) return '-'
    const dateArray = date.split('-')
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
  }

  return (
    <Card className="py-6 grid sm:grid-cols-2 lg:grid-cols-4 gap-y-6 lg:gap-y-0">
      <DriverFileInfo driverFileUrl={driver.driving_license} title="drivingLicenseDetails">
        <FileItem title="licenseNumber" label={driver.driving_license_number} />
        <FileItem title="category" label={driver.license_category} />
        <FileItem title="cityOfIssue" label={driver.license_city_of_issue} />
        <FileItem title="dateOfIssue" label={transformDate(driver.license_date_of_issue)} />
        <FileItem title="dateOfExpiry" label={transformDate(driver.license_date_of_expiry)} />
      </DriverFileInfo>
      <DriverFileInfo driverFileUrl={driver.vehicle_registration_license} title="vehicleRegistrationLicense">
        <FileItem title="registrationLicenceNo" label={driver.vehicle_registration_license_number} />
        <FileItem title="registrationPlateNo" label={driver.registration_plate_number} />
        <FileItem title="model" label={driver.car_model} />
      </DriverFileInfo>
      <DriverFileInfo driverFileUrl={driver.identification_card} title="identificationProof">
        <FileItem title="idCardNumber" label={driver.identification_card_number} />
      </DriverFileInfo>
      <DriverFileInfo driverFileUrl={driver.vehicle_insurance_contract} title="vehicleInsurance">
        <FileItem title="contractNumber" label={driver.vehicle_insurance_contract_number} />
        <FileItem title="company" label={driver.vehicle_insurance_company} />
        <FileItem title="dateOfIssue" label={transformDate(driver.vehicle_insurance_date_of_issue)} />
        <FileItem title="dateOfExpiry" label={transformDate(driver.vehicle_insurance_date_of_expiry)} />
      </DriverFileInfo>
    </Card>
  )
}
