import { DashboardAnalyticsLoader } from './dashboard-analytics/DashboardAnalyticsLoader'
import { NewDriversLoader } from './new-drivers/NewDriversLoader'
import { NewRidesLoader } from './new-rides/NewRidesLoader'

export const DashboardLoader = () => (
  <div className="flex flex-col gap-10 mt-5 sm:mt-10 h-max">
    <DashboardAnalyticsLoader />
    <div className="grid xl:grid-cols-2 gap-7">
      <NewDriversLoader />
      <NewRidesLoader />
    </div>
  </div>
)
