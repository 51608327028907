import { IconType } from 'react-icons'
import { useTranslation } from 'react-i18next'
import { Card } from 'components/ui'

interface Props {
  icon: IconType
  count: number
  label: string
}
const AnalyticsCard = (props:Props) => {
  const { icon: Icon, count, label } = props
  const { t } = useTranslation()
  return (
    <Card className="w-full gap-10  p-10 pr-3 flex items-center">
      <Icon size={45} />
      <div className="flex flex-col gap-2 font-bold">
        <h3 className="text-[44px] leading-[40px] ">{count}</h3>
        <p className="text-lg">{t(label)}</p>
      </div>
    </Card>
  )
}

export default AnalyticsCard
