import { useTranslation } from 'react-i18next'

const text = {
  lg: 'text-3xl sm:text-[36px]',
  md: 'text-xl sm:text-[26px]'
}

interface Props {
  title: string
  size: 'lg' | 'md'
}
export const Title = (props:Props) => {
  const { title, size } = props
  const { t } = useTranslation()
  return (
    <h1 className={`${text[size]}  whitespace-nowrap font-bold text-primary-black`}>{t(title)}</h1>
  )
}
