import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { BiSearch } from 'react-icons/bi'
import { AxiosResponse } from 'axios'
import { LoadingSpinner, RenderIf } from '../ui'
import { ExportButton } from '../ui/ExportButton'

interface SearchInputProps {
  setFilter: (value: string) => void
}

const SearchInput = (props:SearchInputProps) => {
  const { setFilter } = props
  const { t } = useTranslation()
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const classes = 'flex items-center justify-between bg-white transition-all duration-200 border border-transparent hover:border-dark-gray shadow-search py-[14px] px-5 rounded-[10px] max-w-[342px] w-full'

  const handleChange = useAsyncDebounce((val) => {
    setFilter(val || undefined)
    setIsSearching(false)
  }, 500)

  return (
    <div className={classes}>
      <input
        type="text"
        onChange={(event) => {
          setIsSearching(true)
          handleChange(event.target.value?.trim())
        }}
        placeholder={t<string>('search')}
        className="bg-transparent w-full outline-none placeholder:text-lg placeholder:font-['Space_Grotesk'] placeholder:text-[#929395]"
      />
      {isSearching && <LoadingSpinner width="w-5" height="h-5" alignment="end" />}
      {!isSearching && <BiSearch size={22} className="text-[#929395]" />}
    </div>
  )
}

interface Props {
  setFilter: (value: string) => void
  isNestedSearch: boolean
  exportAction?: () => Promise<AxiosResponse>
}

export const TableSearch = (props: Props) => {
  const { setFilter, isNestedSearch, exportAction } = props
  const [domReady, setDomReady] = useState<boolean>(false)

  useEffect(() => {
    if (isNestedSearch) return
    setDomReady(true)
  }, [isNestedSearch])

  const tableSearch = document.getElementById('table-search') as HTMLElement

  if (isNestedSearch) {
    return (
      <div className="flex items-center gap-3 max-w-[410px] w-full">
        <SearchInput setFilter={setFilter} />
        <RenderIf isTrue={!!exportAction}>
          <ExportButton exportAction={exportAction!} />
        </RenderIf>
      </div>
    )
  }

  return domReady ? ReactDOM.createPortal(
    <div className="flex items-center gap-3">
      <SearchInput setFilter={setFilter} />
      <RenderIf isTrue={!!exportAction}>
        <ExportButton exportAction={exportAction!} />
      </RenderIf>
    </div>,

    tableSearch
  ) : null
}
