import { IoReloadCircle } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface Props {
  message: string
}
export const LocalApiError = (props:Props) => {
  const { message } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const reload = () => {
    navigate(0)
  }

  return (
    <div className=" h-[50%] text-center flex flex-col items-center justify-center font-bold">
      <IoReloadCircle onClick={reload} title="Reload" size={40} className="hover:opacity-80 duration-300 transition-opacity cursor-pointer" />
      <p>{t(message)}</p>
    </div>
  )
}
