import { Await, useLoaderData, useParams } from 'react-router-dom'
import { Suspense } from 'react'
import { LocalApiError, SinglePageIdWrapper } from 'components/ui'
import { Driver } from '../../interfaces/driver'
import { useDriver } from '../../hooks/useDriver'
import { DriversForm } from '../driver-form/DriversForm'
import { DriversHeader } from '../DriversHeader'

export const EditDriverLoader = () => {
  const params = useParams()
  const { driver } = useLoaderData() as { driver: Driver }
  const { driver: data } = useDriver({ id: params.id!, initialData: driver })
  return (
    <Suspense>
      <SinglePageIdWrapper id={data?.id}>
        <DriversHeader label="editDriver" showSearch={false} />
      </SinglePageIdWrapper>
      <Await resolve={driver} errorElement={<LocalApiError message="failedToLoadDriver" />}>
        <DriversForm method="patch" driver={data} />
      </Await>
    </Suspense>
  )
}
