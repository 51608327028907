import { useTranslation } from 'react-i18next'
import { getPromotionStatus } from '../../utils/get-promotion-status'

interface Props {
  startDate: string
  endDate: string | null
}
export const PromotionStatus = (props:Props) => {
  const { startDate, endDate } = props
  const { t } = useTranslation()
  const { circleColor, status } = getPromotionStatus(startDate, endDate)

  return (
    <div className="flex items-center gap-2">
      <div className={`w-[7px] h-[7px] rounded-full ${circleColor}`} />
      <p>{t(status)}</p>
    </div>
  )
}
