import { transactionsService } from '../index'

export const transactionsQuery = () => ({
  queryKey: ['transactions'],
  queryFn: () => transactionsService.getTransactions()
})

export const transactionQuery = (id: string) => ({
  queryKey: ['transaction', id],
  queryFn: () => transactionsService.getTransaction(id)
})
