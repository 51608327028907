import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { TableSkeleton } from 'components/skeletons'
import { LocalApiError } from 'components/ui'
import { Ride } from '../../interfaces/ride'
import { useRides } from '../../hooks/useRides'
import { RidesTable } from './RidesTable'
import { ridesService } from '../../index'

export const RidesLoader = () => {
  const { rides } = useLoaderData() as { rides: Ride[] }
  const { rides: data } = useRides({ initialData: rides })

  return (
    <Suspense fallback={<TableSkeleton />}>
      <Await resolve={rides} errorElement={<LocalApiError message="failedToLoadRides" />}>
        <RidesTable
          rides={data}
          exportAction={() => ridesService.ridesTableExport('history')}
        />
      </Await>
    </Suspense>
  )
}
