import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MouseEvent, useMemo } from 'react'
import { Employer } from '../../interfaces/employer'
import { ConfirmationModal, useModalContext } from '../../../common'
import { ReactTable, TableActions } from '../../../../components/table'
import { OriginalRow, RowValue } from '../../../../interfaces'
import { routePaths } from '../../../../routes'
import employersService from '../../services/employers-service'

interface Props {
  employers: Employer[]
}
export const EmployersTable = (props:Props) => {
  const { employers = [] } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onOpen } = useModalContext()

  const columns = useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id'
      },
      {
        Header: t('firstname'),
        accessor: 'user.first_name',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('lastname'),
        accessor: 'user.last_name',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('email'),
        accessor: 'user.email'
      },
      {
        Header: t('telephone'),
        accessor: 'user.phone_number',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('companyName'),
        accessor: 'company_name'
      },
      {
        Header: t('drivers'),
        accessor: 'driver_count'
      },
      {
        Header: ' ',
        Cell: ({ row: { original } }:OriginalRow<Employer>) => (
          <TableActions
            onEditHandler={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              navigate(`${routePaths.editEmployer}/${original.id}`)
            }}
            onDeleteHandler={(event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              onOpen({
                message: 'deleteEmployerMessage',
                resourceId: original.user.id
              })
            }}
          />
        )
      }
    ],
    [t, navigate, onOpen]
  )
  return (
    <>
      <ConfirmationModal />
      <ReactTable
        columns={columns}
        data={employers}
        exportAction={employersService.employerTableExport}
      />
    </>
  )
}
