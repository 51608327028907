import { BaseApi } from 'services'
import { Admin } from 'interfaces'
import { AxiosResponse } from 'axios'
import { AdminsServiceInterface } from './admins-service.interface'
import { AdminFormData } from '../interfaces/admin-form'

class AdminsService extends BaseApi implements AdminsServiceInterface {
  readonly endpoint = '/auth/admin/'

  public async getAdmins(): Promise<Admin[]> {
    return await this.get<Admin[]>(this.endpoint)
  }

  public async createAdmin(values: AdminFormData): Promise<AxiosResponse> {
    const data = {
      email: values.email,
      password: values.password,
      first_name: values.firstName,
      last_name: values.lastName,
      phone_number: values.phone
    }
    return await this.post(this.endpoint, data)
  }

  public async updateAdmin(values: AdminFormData): Promise<AxiosResponse> {
    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      phone_number: values.phone
    }
    return await this.patch(`${this.endpoint}${values.id}/`, data)
  }

  public async deleteAdmin(id: string): Promise<AxiosResponse> {
    return await this.delete(`/auth/user/${id}/`)
  }

  public adminTableExport = async ():Promise<AxiosResponse> => this.post(`${this.endpoint}export/`, {}, {
    responseType: 'blob'
  })
}

export default new AdminsService()
