import { useTranslation } from 'react-i18next'

const fontSizes = {
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl'
}

const colors = {
  black: 'text-primary-black',
  gray: 'text-dark-gray',
  light: 'text-white'
}

const fontWeights = {
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold'
}

interface Props {
  text: string
  color: 'black' | 'light' | 'gray'
  size?: 'sm' | 'base' | 'lg' | 'xl'
  weight?: 'normal' | 'medium' | 'semibold' | 'bold'
  [key:string]: any
}
export const Text = (props:Props) => {
  const { text, size = 'base', color, weight = 'normal', ...restProps } = props
  const { t } = useTranslation()

  return <p className={`${fontSizes[size]} ${colors[color]} ${fontWeights[weight]}`} {...restProps}>{t(text)}</p>
}
