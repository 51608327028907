import { QueryClient } from '@tanstack/react-query'
import { defer } from 'react-router-dom'
import { driversQuery } from 'features/drivers'
import { ridesQuery } from 'features/rides'
import { authRedirect } from 'utils'
import { isAlreadyAuthenticated } from 'features/authentication'
import { analyticsQuery, employerAnalyticsQuery } from './queries'
import { isAdmin } from '../../authentication/utils/user-utils'

export const loader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated()) return authRedirect()
  return defer({
    analytics: isAdmin()
      ? queryClient.ensureQueryData(analyticsQuery())
      : queryClient.ensureQueryData(employerAnalyticsQuery()),
    drivers: queryClient.ensureQueryData(driversQuery()),
    rides: queryClient.ensureQueryData(ridesQuery())
  })
}
