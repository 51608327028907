import { messagesService } from '../index'

export const messagesQuery = () => ({
  queryKey: ['messages'],
  queryFn: () => messagesService.getMessages()
})

export const cancellationMessagesQuery = () => ({
  queryKey: ['cancellationMessages'],
  queryFn: () => messagesService.getCancellationMessages()
})
