import { SectionHeader } from 'components/ui'
import { useTranslation } from 'react-i18next'

export const MessagingHeader = () => {
  const { t } = useTranslation()
  return (
    <>
      <SectionHeader
        title="messaging"
        showSearch={false}
        marginBottom="mb-5"
      />
      <p className="mb-10">{t('defineTheStandardCommunication')}</p>
    </>
  )
}
