import { ReactTable, TableActions } from 'components/table'
import { MouseEvent, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OriginalRow, RowValue } from 'interfaces'
import { routePaths } from 'routes'
import { useNavigate } from 'react-router-dom'
import { StarRating, Switch } from 'components/ui'
import { ConfirmationModal, useModalContext } from 'features/common'
import { AxiosResponse } from 'axios'
import { Driver, DriverStatus } from '../../interfaces/driver'
import driversService from '../../services/drivers-service'
import { DriverStatusIndicator } from '../ui/DriverStatusIndicator'
import { isAdmin } from '../../../authentication/utils/user-utils'

interface Props {
  drivers: Driver[]
  path?: string
  children?: (props: { setGlobalFilter: (value: string) => void }) =>ReactNode
  employerQueryKey?: string[]
  exportAction?: () => Promise<AxiosResponse>
}
export const DriversTable = (props:Props) => {
  const { drivers = [], path, children, employerQueryKey, exportAction } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onOpen } = useModalContext()
  const columns = useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id'
      },
      {
        Header: t('firstname'),
        accessor: 'user.first_name',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('lastname'),
        accessor: 'user.last_name',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('employer'),
        accessor: (row: Driver) => (
          row?.employers.length > 0
            ? `${row?.employers?.[0]?.user.first_name} ${row?.employers?.[0]?.user.last_name}`
            : '-')
      },
      {
        Header: t('email'),
        accessor: 'user.email'
      },
      {
        Header: t('telephone'),
        accessor: 'user.phone_number',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('rating'),
        accessor: 'driver_rating.overall_rating',
        Cell: ({ value }: RowValue<null | number>) => (
          <StarRating rating={value} />
        )
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }: RowValue<DriverStatus>) => (
          <DriverStatusIndicator status={value} />
        )
      },
      {
        Header: isAdmin() ? t('block') : ' ',
        accessor: 'is_blocked',
        Cell: ({ row: { original } }:OriginalRow<Driver>) => (isAdmin()
          ? (
            <div className="flex items-center gap-2">
              <Switch
                queryKey={employerQueryKey ? ['drivers', ...employerQueryKey] : ['drivers']}
                id={original.id}
                isChecked={original.is_blocked}
                apiCall={driversService.blockDriver}
                onConfirmation={(action: () => void) => onOpen({
                  message: original.is_blocked ? 'unblockMessage' : 'blockMessage',
                  resourceId: original.id,
                  callback: action
                })}
              />
              {original.is_blocked && <p className="text-sm">{t('blocked')}</p>}
            </div>
          )
          : null)
      },
      {
        Header: ' ',
        Cell: ({ row: { original } }:OriginalRow<Driver>) => (
          isAdmin() ? (
            <TableActions
              onEditHandler={original.is_accepted ? (event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                navigate(`${routePaths.drivers}/${routePaths.editDriver}/${original.id}`)
              } : undefined}
              onDeleteHandler={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                onOpen({
                  message: 'deleteDriverMessage',
                  resourceId: original.user.id,
                  userType: 'Driver'
                })
              }}
            />
          ) : null
        )
      }
    ],
    [t, navigate, onOpen, employerQueryKey]
  )
  return (
    <>
      {!employerQueryKey ? <ConfirmationModal /> : null}
      <ReactTable
        path={path}
        columns={columns}
        data={drivers}
        exportAction={exportAction}
      >
        {children}
      </ReactTable>
    </>
  )
}
