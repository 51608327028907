import { Await, useLoaderData, useParams } from 'react-router-dom'
import { Suspense } from 'react'
import { LocalApiError, SinglePageIdWrapper } from 'components/ui'
import { Driver } from '../../interfaces/driver'
import { DisplaySingleDriver } from './DisplaySingleDriver'
import { useDriver } from '../../hooks/useDriver'
import { DriversHeader } from '../DriversHeader'

export const SingleDriverLoader = () => {
  const params = useParams()
  const { driver } = useLoaderData() as { driver: Driver }
  const { driver: data } = useDriver({ id: params.id!, initialData: driver })

  return (
    <Suspense>
      <div className="flex  gap-5 justify-between flex-wrap">
        <SinglePageIdWrapper id={data?.id}>
          <DriversHeader label="driver" showSearch={false} />
        </SinglePageIdWrapper>

        <div id="block-driver" />
      </div>
      <Await resolve={driver} errorElement={<LocalApiError message="failedToLoadDriver" />}>
        <DisplaySingleDriver driver={data} />
      </Await>
    </Suspense>
  )
}
