import { useTranslation } from 'react-i18next'
import { RenderIf } from '../../../../components/ui'

interface Props {
  total: string
  amountBeforeDiscount: string | null
  discount: string | null
  receiptLink: string | null
}
export const RideCost = (props:Props) => {
  const { total, discount, amountBeforeDiscount, receiptLink } = props
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-5 lg:px-10 lg:border-r lg:border-r-[#a9a9a9] last:border-0 w-full">
      <div className="flex gap-5 justify-between flex-wrap h-max">
        <h3 className="text-[20px] font-bold">{t('costBreakdown')}</h3>
        <RenderIf isTrue={!!receiptLink}>
          <a
            href={receiptLink!}
            rel="noreferrer"
            target="_blank"
            className="text-sm underline"
            type="button"
          >
            {t('viewTransactionInvoice')}
          </a>
        </RenderIf>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <p className="text-gray ">{t('total')}</p>
          <p className="text-[20px] font-bold">
            &euro;
            {total}
          </p>
        </div>
        <RenderIf isTrue={!!discount}>
          <div className="flex items-center justify-between text-sm">
            <p className="text-gray">{t('promo')}</p>
            <p className="text-sm text-dark-gray">
              - &euro;
              {discount}
            </p>
          </div>
        </RenderIf>
        <RenderIf isTrue={!!amountBeforeDiscount}>
          <div className="flex items-center justify-between text-sm">
            <p className="text-gray">{t('farePrice')}</p>
            <p className="text-sm text-dark-gray">
              &euro;
              {amountBeforeDiscount}
            </p>
          </div>
        </RenderIf>
      </div>

    </div>
  )
}
