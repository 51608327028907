import { TabBar, TabLink } from 'features/navigation'
import { routePaths } from 'routes'
import { useTranslation } from 'react-i18next'

interface Props {
  driversCount: number
}
export const EmployerTabs = (props:Props) => {
  const { driversCount = 0 } = props
  const { t } = useTranslation()
  return (
    <TabBar className="mt-10">
      <TabLink path="" label="companyAndLicenseDetails" />
      <TabLink path={routePaths.employerDrivers} label={`${t('drivers')} (${driversCount})`} />
    </TabBar>
  )
}
