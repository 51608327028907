import { Formik } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { FormGroup } from 'components/form'
import { UploadDocumentButton } from '../UploadDocumentButton'

interface Props<T> extends Formik<T> {
}
export const ProofOfIdFields = <T extends unknown> (props:Props<T>) => {
  const { formik } = props
  const { t } = useTranslation()
  return (
    <div>
      <div>
        <FormGroup
          label="proofOfId"
          placeholder={t<string>('proofOfId')}
          hasError={!!(formik.touched.identification_card_number
                    && formik.errors.identification_card_number)}
          errorText={formik.errors.identification_card_number}
          {...formik.getFieldProps('identification_card_number')}
        />
      </div>
      <div className="mt-5">
        <UploadDocumentButton
          label="uploadDocument"
          formik={formik}
          formikKey="identification_card"
          acceptedFormats=".jpg, .jpeg, .png, .pdf"
          supportedFilesText="supportedFile"
        />
      </div>
    </div>
  )
}
