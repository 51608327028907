import { Card } from 'components/ui'
import moment from 'moment'
import { Transaction } from '../../interfaces/transaction'
import { TransactionStatusTitle } from './TransactionStatusTitle'
import { TransactionCost } from './TransactionCost'
import { TransactionRideInfo } from './TransactionRideInfo'
import TransactionActionButtons from './TransactionActionButtons'

interface Props {
  transaction: Transaction
}
export const SingleTransaction = (props:Props) => {
  const { transaction } = props

  return (
    <>
      <Card className="p-7 mb-[30px] flex flex-col gap-9">
        <div className="flex items-center justify-between flex-wrap gap-5 w-full">
          <TransactionStatusTitle
            transactionId={transaction.transaction_id}
            verified={transaction.verified}
          />
          <p className="whitespace-nowrap text-gray text-lg font-['Space_Grotesk']">
            {
            transaction.created
              ? moment(transaction.created).format('MMM.DD, YY - HH:mm')
              : '-'
}
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-10 md:gap-0">
          <TransactionCost
            total={(transaction.amount / 100).toFixed(2)}
            cardType={transaction.card_type}
            paymentMethod={transaction.payment_method}
          />
          <TransactionRideInfo
            rideId={transaction.route}
            clientFullName={transaction.full_name}
            clientsEmail={transaction.transaction_email}
          />
        </div>
      </Card>
      <TransactionActionButtons receiptLink={transaction?.receipt_link} />

    </>
  )
}
