import { useQuery } from '@tanstack/react-query'
import { Ride } from '../interfaces/ride'
import { ridesQuery } from '../helpers/queries'

interface Args {
  initialData: Ride[];
}
export const useRides = ({ initialData }:Args) => {
  const { data: rides } = useQuery({
    ...ridesQuery(),
    initialData
  })

  return { rides }
}
