import moment from 'moment'

export const getPromotionStatus = (startDate:string, endDate: string | null) => {
  let circleColor: string
  let status: string

  switch (true) {
    case moment().isAfter(endDate):
      circleColor = 'bg-[#EE9292] shadow-expired'
      status = 'expired'
      break
    case moment().isBefore(startDate):
      circleColor = 'bg-[#E6AC25] shadow-expired'
      status = 'scheduled'
      break
    default:
      circleColor = 'bg-[#2FA10E] shadow-active'
      status = 'active'
  }

  return { circleColor, status }
}
