import { IslandDropdown, PrimaryButton, RenderIf, Title } from './index'
import { isAdmin } from '../../features/authentication/utils/user-utils'

interface Props {
  title: string
  buttonText? : string
  showSearch?: boolean
  onClickHandler?: () => void
  marginBottom?: string
  showIslandDropdown?: boolean
}
export const SectionHeader = (props:Props) => {
  const { title, buttonText, showSearch = true, onClickHandler, marginBottom = 'mb-5 sm:mb-10', showIslandDropdown = false } = props
  return (
    <header className={`flex items-center justify-between gap-5 sm:gap-10 flex-wrap sm:flex-nowrap  ${marginBottom}`}>
      <div className="flex items-center gap-3 w-full">
        <Title title={title} size="md" />

        {/* Placeholder to render here the table search */}
        <div id="table-search" className={`w-full sm:ml-10 w-full h-[54px] ${showSearch ? 'block' : 'hidden'}`} />

      </div>
      <RenderIf isTrue={!!buttonText && !!onClickHandler}>
        <PrimaryButton text={buttonText!} type="button" onClickHandler={onClickHandler} size="md" hasIcon />
      </RenderIf>
      <RenderIf isTrue={showIslandDropdown && isAdmin()}>
        <IslandDropdown />
      </RenderIf>
    </header>
  )
}
