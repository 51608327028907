import { FaRegStar, FaStar } from 'react-icons/fa'

interface Props {
  rating: number | null;
}
export const StarRating = (props:Props) => {
  const { rating } = props
  return (
    <div className="flex items-center gap-2 font-['Space_Grotesk']">
      <p>
        {!!rating && <FaStar />}
        {!rating && <FaRegStar />}
      </p>
      <p>{rating?.toFixed(1) ?? '-'}</p>
    </div>
  )
}
