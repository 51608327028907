import { routePaths } from 'routes'
import { Children } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { useLogout } from 'hooks'
import { NavMenu } from '../interfaces/nav-menu'
import { paths } from '../helpers/paths'
import { NavigationLink } from './NavigationLink'

interface Props extends Children {
  onClickHandler: () => void

}
const Button = (props:Props) => {
  const { onClickHandler, children } = props
  return <button type="button" onClick={onClickHandler}>{children}</button>
}

export const NavigationMobile = (props: NavMenu) => {
  const { isEmployer, isOpen, setIsOpen } = props
  const { t } = useTranslation()
  const { logout } = useLogout()
  const isOpenClass = isOpen ? 'translate-x-0' : 'translate-x-full'

  const closeMenu = () => {
    setIsOpen(false)
  }
  return (
    <div className={`text-xl flex gap-5 overflow-y-auto flex-col items-center fixed justify-center md:hidden inset-0 top-[50px] bg-white ${isOpenClass} transition-transform duration-300 z-10`}>
      {paths.filter((path) => (
        isEmployer ? !path.isAdminOnly : true))
        .map((path) => (
          <Button key={path.id} onClickHandler={closeMenu}>
            <NavigationLink
              icon={path.icon}
              activeIcon={path.activeIcon}
              path={path.path}
              label={path.label}
            />
          </Button>
        ))}
      <Button onClickHandler={closeMenu}>
        <NavigationLink
          path={routePaths.manageUsers}
          label="manageUsers"
        />
      </Button>
      <Button onClickHandler={logout}>
        {t('logout')}
      </Button>
    </div>
  )
}
