import { BaseApi } from 'services'
import { UserAnalytics } from '../interfaces/analytics'
import { DashboardServiceInterface } from './dashboard-service.interface'

class DashboardService extends BaseApi implements DashboardServiceInterface {
  public async getAnalytics(): Promise<UserAnalytics> {
    return this.get<UserAnalytics>('/auth/admin/get-statistics/')
  }

  public async getEmployerAnalytics(): Promise<UserAnalytics> {
    return this.get<UserAnalytics>('/auth/employer/get-statistics/')
  }
}

export default new DashboardService()
