const inputSizes = {
  sm: 'py-[10px]',
  lg: 'py-[17px]'
}

interface Props {
  [key:string]: any
  hasError: boolean
  size?: keyof typeof inputSizes
  className?:string
}
export const FormInput = (props:Props) => {
  const { hasError, size = 'sm', className, ...restProps } = props
  const errorClass = hasError ? 'border-dark-red focus:outline-dark-red-500' : 'border-transparent focus:outline-dark-gray'
  return (
    <input
      className={`${errorClass} 
       disabled:cursor-not-allowed disabled:opacity-50 disabled:
      bg-white w-full rounded-[10px] px-7 ${inputSizes[size]} shadow-input border outline-primary-black transition-all duration-200 hover:border-dark-gray placeholder:text-[#96989A] placeholder:text-sm placeholder:font-['Space_Grotesk'] ${className}`}
      {...restProps}
    />
  )
}
