import { Await, useLoaderData } from 'react-router-dom'
import { Ride, RidesTable } from 'features/rides'
import { useQuery } from '@tanstack/react-query'
import { Card, LocalApiError } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { Suspense } from 'react'
import { Client } from '../../../interfaces/client'
import { clientRideHistoryQuery } from '../../../helpers/queries'
import { TableSkeleton } from '../../../../../components/skeletons'
import { routePaths } from '../../../../../routes'

interface Props {
  client: Client
}
export const ClientRideHistoryLoader = (props:Props) => {
  const { client } = props
  const { t } = useTranslation()
  const { rideHistory } = useLoaderData() as { rideHistory: Ride[] }
  const { data } = useQuery({
    ...clientRideHistoryQuery(client.id.toString()),
    initialData: rideHistory
  })
  return (
    <Card className="p-7">
      <h3 className="text-xl font-semibold text-gray mb-6">{t('rideHistory')}</h3>
      <Suspense fallback={<TableSkeleton />}>
        <Await resolve={rideHistory} errorElement={<LocalApiError message="failedToLoadRides" />}>
          <RidesTable rides={data} path={routePaths.rides} />
        </Await>
      </Suspense>
    </Card>
  )
}
