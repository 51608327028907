import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { useQuery } from '@tanstack/react-query'
import { LocalApiError } from 'components/ui'
import { TableSkeleton } from 'components/skeletons'
import { Zone } from '../interfaces/zones'
import { ZonesTable } from './ZonesTable'
import { zonesQuery } from '../helpers/queries'

export const FeesLoader = () => {
  const { zones } = useLoaderData() as { zones: Zone[] }
  const { data } = useQuery({
    ...zonesQuery(),
    initialData: zones
  })

  return (
    <div>
      <Suspense fallback={<TableSkeleton />}>
        <Await resolve={zones} errorElement={<LocalApiError message="failedToLoadZones" />}>
          <ZonesTable zones={data} />
        </Await>
      </Suspense>
    </div>
  )
}
