import { Await, useLoaderData, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import React, { Suspense } from 'react'
import { Employer } from '../../interfaces/employer'
import { employerQuery } from '../../helpers/queries'
import { LocalApiError, SinglePageIdWrapper } from '../../../../components/ui'
import { SingleEmployer } from './SingleEmployer'
import { EmployersHeader } from '../EmployersHeader'

export const EmployerLoader = () => {
  const { employer } = useLoaderData() as { employer: Employer }
  const { id } = useParams<{ id: string }>()
  const { data } = useQuery({
    ...employerQuery(id!),
    initialData: employer,
    enabled: !!id
  })

  return (
    <Suspense>
      <SinglePageIdWrapper id={data?.id}>
        <EmployersHeader label="employer" showSearch={false} />
      </SinglePageIdWrapper>

      <Await resolve={employer} errorElement={<LocalApiError message="failedToLoadEmployer" />}>
        {() => <SingleEmployer employer={data} />}
      </Await>
    </Suspense>
  )
}
