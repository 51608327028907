import React from 'react'

export const IconEmployerOutlined = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.837" height="20.193" viewBox="0 0 19.837 20.193">
    <g id="employee-line" transform="translate(-19.028 -14.338)">
      <g id="Layer_3" data-name="Layer 3" transform="translate(19.028 14.338)">
        <path
          id="Path_2162"
          data-name="Path 2162"
          d="M54.8,23.413a4.538,4.538,0,1,1,4.538-4.538A4.538,4.538,0,0,1,54.8,23.413Zm0-7.727a3.241,3.241,0,1,0,3.241,3.241A3.241,3.241,0,0,0,54.8,15.686Z"
          transform="translate(-46.463 -14.338)"
        />
        <path
          id="Path_2163"
          data-name="Path 2163"
          d="M30.975,92.5a16.472,16.472,0,0,0-10.45,1.083,2.632,2.632,0,0,0-1.5,2.386V99.83a.648.648,0,1,0,1.3,0V95.973a1.3,1.3,0,0,1,.752-1.206A14.852,14.852,0,0,1,27.365,93.4a15.286,15.286,0,0,1,3.611.428Z"
          transform="translate(-19.028 -82.645)"
        />
        <path
          id="Path_2164"
          data-name="Path 2164"
          d="M118.006,146.1h3.98V147h-3.98Z"
          transform="translate(-105.968 -130.07)"
        />
        <path
          id="Path_2165"
          data-name="Path 2165"
          d="M96.411,102.871H93.059v1.3h2.7v5.426H86.577v-5.426h4.084v.272a.648.648,0,1,0,1.3,0v-2.522a.648.648,0,0,0-1.3,0v.953H85.928a.648.648,0,0,0-.648.648v6.723a.648.648,0,0,0,.648.648H96.411a.648.648,0,0,0,.648-.648v-6.723A.648.648,0,0,0,96.411,102.871Z"
          transform="translate(-77.222 -90.697)"
        />
      </g>
    </g>
  </svg>
)
