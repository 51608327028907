import { FaRegUserCircle } from 'react-icons/fa'

interface Props {
  width?: string;
  height?: string;
  src: string | null;
  size?: number;
  color?: '#f2f1f1' | '#707070';
}
export const UserProfileIcon = (props:Props) => {
  const { width = 'w-[75px]', height = 'h-[75px]', src, size = 75, color = '#f2f1f1' } = props
  return (
    src ? (
      <div className={`${width} ${height} rounded-full`}>
        <img src={src} className="object-cover w-full h-full rounded-full" alt="" />
      </div>
    ) : <FaRegUserCircle color={color} size={size} />

  )
}
