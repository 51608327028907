import { useTranslation } from 'react-i18next'
import { CardTitle } from 'components/ui'

interface Props {
  transactionId: string | null
  verified: boolean | null
}
export const TransactionStatusTitle = (props:Props) => {
  const { transactionId, verified } = props
  const { t } = useTranslation()
  return (
    <div className="flex items-center gap-5 sm:gap-10 flex-wrap">
      <CardTitle title={`${t('transaction')} #${transactionId ?? '-'}`} />
      <div className="flex items-center gap-3">
        <div className={`rounded-full h-[18px] w-[18px] ${verified ? 'bg-online shadow-online' : 'bg-dark-red shadow-dark-red'}`} />
        <p className={verified ? 'text-online' : 'text-dark-red'}>
          {t(verified ? 'settled' : 'unsettled')}
        </p>
      </div>
    </div>
  )
}
