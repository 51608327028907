import { useTranslation } from 'react-i18next'
import { FormGroup } from 'components/form'
import { Formik } from 'interfaces'
import { DriversProfileImageUpload, ProofOfIdFields } from '../../../drivers'
import { EmployerFormValues } from '../../interfaces/employer-form'

interface Props extends Formik<EmployerFormValues> {
  method?: 'post' | 'patch'
}

export const EmployerDetailsFields = (props:Props) => {
  const { formik, method } = props
  const { t } = useTranslation()

  return (
    <div>
      <h2 className="text-xl mb-5">{t('details')}</h2>
      <div className="driver-form-child-container items-start">
        <FormGroup
          label="firstname"
          placeholder={t<string>('employersFirstName')}
          hasError={!!(formik.touched.first_name && formik.errors.first_name)}
          errorText={formik.errors.first_name}
          {...formik.getFieldProps('first_name')}
        />
        <FormGroup
          label="lastname"
          placeholder={t<string>('employersLastName')}
          hasError={!!(formik.touched.last_name && formik.errors.last_name)}
          errorText={formik.errors.last_name}
          {...formik.getFieldProps('last_name')}
        />
        <FormGroup
          label="email"
          placeholder={t<string>('employersEmail')}
          hasError={!!(formik.touched.email && formik.errors.email)}
          errorText={formik.errors.email}
          disabled={method === 'patch'}
          {...formik.getFieldProps('email')}
        />
        <FormGroup
          label="telephone"
          placeholder={t<string>('employersPhone')}
          hasError={!!(formik.touched.phone_number && formik.errors.phone_number)}
          errorText={formik.errors.phone_number}
          {...formik.getFieldProps('phone_number')}
        />
        <FormGroup
          label="residency"
          placeholder={t<string>('employersResidency')}
          hasError={!!(formik.touched.residency && formik.errors.residency)}
          errorText={formik.errors.residency}
          {...formik.getFieldProps('residency')}
        />
        <FormGroup
          label="address"
          placeholder={t<string>('employersAddress')}
          hasError={!!(formik.touched.address && formik.errors.address)}
          errorText={formik.errors.address}
          {...formik.getFieldProps('address')}
        />
        <DriversProfileImageUpload<EmployerFormValues> formik={formik} formikKey="photo" />
        <FormGroup
          label="password"
          type="password"
          disabled={method === 'patch'}
          placeholder={t<string>('employersPassword')}
          hasError={!!(formik.touched.password && formik.errors.password)}
          errorText={formik.errors.password}
          {...formik.getFieldProps('password')}
        />

        <ProofOfIdFields<EmployerFormValues> formik={formik} />
      </div>
    </div>
  )
}
