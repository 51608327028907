import { createContext } from 'react'
import { ModalCtx } from './modal-ctx.interface'

export const modalCtx = createContext<ModalCtx>({
  showModal: false,
  message: '',
  resourceId: null,
  userType: null,
  callback: null,
  onOpen: () => {},
  onClose: () => {}
})
