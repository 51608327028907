import { BaseApi } from 'services'
import jwt_decode from 'jwt-decode'
import { AxiosResponse } from 'axios'
import { User } from 'interfaces'
import { IAuthService } from './auth-service.interface'
import { LoginFormData } from '../interfaces/login-form-data'
import { DecodedToken, Token, tokenUtils, userUtils } from '../index'

class AuthService extends BaseApi implements IAuthService {
  public async login({
    email,
    password
  }: LoginFormData): Promise<AxiosResponse | void> {
    const response = await this.post('/token/', { email, password })
    const token: Token = response?.data
    tokenUtils.storeTokens(token)

    const decodedToken: DecodedToken = jwt_decode(token.access)
    const user = await this.getAuthUser(decodedToken.user_type_id, decodedToken.user_type)
    if (user) userUtils.storeUser(user)

    return response
  }

  public async forgotPassword(email: string): Promise<AxiosResponse> {
    return await this.post('/auth/user/forgot-password-request/', {
      email
    })
  }

  public async resetPassword(code: string, password: string): Promise<AxiosResponse> {
    return await this.post('/auth/user/forgot-code-validation/', {
      code,
      new_password: password
    })
  }

  public async getAuthUser(id: number, role: 'Employer' | 'Admin'): Promise<User | void> {
    return await this.get<User>(`/auth/${role?.toLowerCase()}/${id}/`)
  }

  public async sendCaptcha(captchaToken: FormDataEntryValue) {
    return await this.post('/auth/captcha/hcaptcha/', {
      captcha_challenge: captchaToken
    })
  }
}

export default new AuthService()
