import { Await, useLoaderData } from 'react-router-dom'
import { Driver, useDrivers } from 'features/drivers'
import { Suspense } from 'react'
import { LocalApiError } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { DisplayNewDriversSkeleton } from 'components/skeletons'
import DisplayNewDrivers from './DisplayNewDrivers'
import { isAdmin } from '../../../authentication/utils/user-utils'

export const NewDriversLoader = () => {
  const { drivers } = useLoaderData() as { drivers: Driver[] }
  const { drivers: data } = useDrivers({ initialData: drivers })
  const { t } = useTranslation()

  return (
    <div>
      <h2 className="mb-6">{t(isAdmin() ? 'newDrivers' : 'yourDrivers')}</h2>
      <Suspense fallback={<DisplayNewDriversSkeleton />}>
        <Await resolve={drivers} errorElement={<LocalApiError message="failedToLoadDrivers" />}>
          <DisplayNewDrivers drivers={data} />
        </Await>
      </Suspense>
    </div>
  )
}
