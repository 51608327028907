import logoMenu from 'assets/images/logo-menu.png'
import logoMark from 'assets/images/logo-mark.png'
import { Link } from 'react-router-dom'
import { routePaths } from 'routes'

export const LogoMenu = () => (
  <Link to={routePaths.home}>
    <img src={logoMenu} alt="" width={218} className="hidden 2xl:inline" />
    <img src={logoMark} alt="" className="2xl:hidden" />
  </Link>
)
