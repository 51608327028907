import { QueryClient } from '@tanstack/react-query'
import { defer, LoaderFunctionArgs } from 'react-router-dom'
import { employerQuery, employersQuery } from './queries'
import { isAlreadyAuthenticated } from '../../authentication'
import { authRedirect } from '../../../utils'
import { isAdmin } from '../../authentication/utils/user-utils'

export const employersLoader = (queryClient:QueryClient) => () => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()
  return defer({
    employers: queryClient.ensureQueryData(employersQuery())
  })
}

export const employerLoader = (queryClient:QueryClient) => ({ params }:LoaderFunctionArgs) => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  const { id } = params as { id: string }
  return defer({
    employer: queryClient.ensureQueryData(employerQuery(id!))
  })
}
