const MessageSkeletonCard = () => (
  <div className="rounded-[10px] bg-white shadow-card animate-pulse flex items-center justify-between px-7 py-5 text-gray font-bold">
    <div className="bg-light-gray rounded-[10px] h-6 max-w-[300px] w-full animate-pulse" />
    <div className="bg-light-gray rounded-[10px] h-6 max-w-[46px] w-full animate-pulse" />
  </div>
)
export const MessagingSkeleton = () => (
  <>
    <div className="mb-1 h-6 max-w-[100px] w-full bg-white rounded-[10px] animate-pulse" />
    <div className="flex items-end gap-3">
      <div className="flex items-center max-w-[425px] h-[60px] px-7 w-full bg-white shadow-card rounded-[10px] animate-pulse">
        <div className="bg-light-gray rounded-[10px] h-4 w-20 animate-pulse" />
      </div>
      <div className="flex gap-3 px-6 items-center justify-center max-w-[116.21px] h-[60px] w-full bg-white shadow-card rounded-[10px] animate-pulse">
        <div className="bg-light-gray rounded-[10px] h-4 w-10  animate-pulse" />
        <div className="bg-light-gray rounded-[10px] h-4 w-16  animate-pulse" />
      </div>
    </div>
    <div className="mt-2 h-4 max-w-[330px] w-full bg-white rounded-[10px] animate-pulse" />
    <div className="mt-6 grid gap-5">
      <MessageSkeletonCard />
      <MessageSkeletonCard />
      <MessageSkeletonCard />
    </div>
  </>
)
