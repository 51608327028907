import ReactDOM from 'react-dom'
import { Children } from 'interfaces'

interface Props extends Children {
  showModal: boolean
  onClose: () => void
  maxWidth?: string
}
export const Modal = (props:Props) => {
  const { children, showModal, onClose, maxWidth = 'max-w-[450px]' } = props
  const modalRoot = document.getElementById('modal') as HTMLElement

  const backDropClasses: string = showModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses: string = showModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div onClick={onClose} className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.2)] transition-all duration-300 ${backDropClasses}`} />
      <div className={`fixed ${maxWidth} w-[90%] md:w-full z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 origin-center transition-all duration-300 ${modalClasses}`}>{children}</div>
    </>,
    modalRoot
  )
}
