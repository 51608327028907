import { Card } from '../ui'

export const SingleRideSkeleton = () => (
  <Card className="animate-pulse p-7 mb-[30px] flex flex-col gap-9 min-h-[329px]">
    <div className="flex items-center justify-between flex-wrap gap-5">
      <div className="flex items-center gap-5 sm:gap-10 flex-wrap">
        <div className="w-[220px] h-[45px] rounded-[10px] animate-pulse bg-light-gray" />
        <div className="w-[101px]  h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
      </div>
      <div className="max-w-[101px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
    </div>
    <div className="grid md:grid-cols-3 gap-10 md:gap-0">
      <div className="flex flex-col gap-7 lg:pr-10 lg:border-r lg:border-r-[#a9a9a9] last:border-0 w-full">
        <div>
          <div className="max-w-[167px] w-full h-[30px] rounded-[10px] animate-pulse bg-light-gray" />
          <div className="mt-1.5 max-w-[80px] w-full h-[18px] rounded-[10px] animate-pulse bg-light-gray" />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 flex-wrap">
            <div className="max-w-[110px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
            <div className="max-w-[110px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
          </div>
          <div className="flex gap-1 flex-wrap">
            <div className="max-w-[110px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
            <div className="max-w-[110px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-7 lg:px-10 lg:border-r lg:border-r-[#a9a9a9] last:border-0 w-full">
        <div className="max-w-[167px] w-full h-[30px] rounded-[10px] animate-pulse bg-light-gray" />
        <div className="flex items-center justify-between">
          <div className="max-w-[38px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
          <div className="max-w-[68px] w-full h-[30px] rounded-[10px] animate-pulse bg-light-gray" />
        </div>
      </div>
      <div className="md:pl-10 flex flex-col gap-7">
        <div>
          <div className="max-w-[167px] w-full h-[30px] rounded-[10px] animate-pulse bg-light-gray" />
          <div className="mt-1.5 max-w-[80px] w-full h-[18px] rounded-[10px] animate-pulse bg-light-gray" />
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-1 flex-wrap">
            <div className="max-w-[110px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
            <div className="max-w-[110px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
          </div>
          <div className="flex gap-1 flex-wrap">
            <div className="max-w-[110px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
            <div className="max-w-[110px] w-full h-[24px] rounded-[10px] animate-pulse bg-light-gray" />
          </div>
        </div>
      </div>
    </div>
  </Card>
)
