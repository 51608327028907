import { Await, useLoaderData } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Suspense } from 'react'
import { Driver } from '../../interfaces/driver'
import { uncheckedDriversQuery } from '../../helpers/queries'
import { UncheckedDrivers } from './UncheckedDrivers'
import { LocalApiError, RenderIf } from '../../../../components/ui'

export const UncheckedDriversLoader = () => {
  const { uncheckedDrivers } = useLoaderData() as { uncheckedDrivers: Driver[] }
  const { data } = useQuery({
    ...uncheckedDriversQuery(),
    initialData: uncheckedDrivers
  })

  return (
    <Suspense>
      <Await resolve={uncheckedDrivers} errorElement={<LocalApiError message="failedToLoadUncheckedDrivers" />}>
        <RenderIf isTrue={data?.length > 0}>
          <UncheckedDrivers
            uncheckedDrivers={data}
          />
        </RenderIf>
      </Await>
    </Suspense>
  )
}
