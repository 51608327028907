import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

interface Props {
  path: string
  label: string
}
export const TabLink = (props:Props) => {
  const { path, label } = props
  const { t } = useTranslation()
  return (
    <NavLink
      className={({ isActive }) => `${isActive ? 'border-b-2' : 'text-[#8B8B8B]'} font-normal hover:opacity-80 transition-opacity duration-300`}
      to={path}
      replace
      end
    >
      {t(label)}
    </NavLink>
  )
}
