import * as yup from 'yup'

export const validationSchema = {
  promotion: yup.object({
    title: yup.string().trim().required('titleRequired'),
    startDate: yup.string().trim().required('startDateRequired'),
    discount: yup.string().trim().required('discountRequired'),
    usesLimit: yup.number().test('unlimited', 'usesLimitRequired', function test(value) {
      const { unlimited } = this.parent
      return unlimited === false ? value !== undefined : true
    }),
    endDate: yup.string().test('noEndDate', 'endDateRequired', function test(value) {
      const { noEndDate } = this.parent
      return noEndDate === false ? value !== undefined : true
    }).test('endDate', 'endDateCannotBeLowerThanStartDate', function test(value) {
      const { startDate } = this.parent
      if (value && startDate) {
        return value >= startDate
      }
      return true
    })
  })
}
