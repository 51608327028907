import { ReactTable, TableActions } from 'components/table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OriginalRow } from 'interfaces'
import { Zone } from '../interfaces/zones'
import { PriceInput } from './PriceInput'
import { feesService } from '../index'

interface Props {
  zones: Zone[]
}
export const ZonesTable = (props:Props) => {
  const { zones = [] } = props
  const { t } = useTranslation()
  const columns = useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id'
      },
      {
        Header: t('zones'),
        Cell: ({ row: { original } }:OriginalRow<Zone>) => (
          <div>
            <p>{`${t('zone')} ${original.zone_1} ${t('to')} ${original.zone_2}`}</p>
          </div>
        )
      },
      {
        Header: `${t('price')} (€)`,
        accessor: 'price',
        Cell: ({ row: { original } }:OriginalRow<Zone>) => (
          <div className="flex items-center w-full">
            {/* Used render props pattern to lift the ref object from
            Price input and pass it to TableActions Handlers */}
            <PriceInput id={original.id} value={original.price}>
              {(ref) => (
                <TableActions
                  onEditHandler={() => {
                    ref.current?.focus()
                  }}
                />
              )}
            </PriceInput>

          </div>
        )
      }
    ],
    [t]
  )

  return (
    <ReactTable
      columns={columns}
      data={zones}
      exportAction={feesService.zonesTableExport}
    />
  )
}
