import { useQuery } from '@tanstack/react-query'
import { driverQuery } from '../helpers/queries'
import { Driver } from '../interfaces/driver'

interface Args {
  id: string
  initialData:Driver
}
export const useDriver = ({ id, initialData }:Args) => {
  const { data: driver } = useQuery({
    ...driverQuery(id),
    initialData
  })
  return { driver }
}
