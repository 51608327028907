import { useLocation } from 'react-router-dom'
import { RenderIf } from 'components/ui'
import { routePaths } from 'routes'
import { HeaderPath } from './HeaderPath'

export const HeaderRoutes = () => {
  const { pathname } = useLocation()
  const routes = pathname.split('/').filter((route) => Boolean(route) && !route.match(/^\d+$/))

  const removeDashHandler = (label: string): string => label.replaceAll('-', ' ')
  return (
    <div className="flex gap-1">
      <HeaderPath path={routePaths.home} label="home" />
      <RenderIf isTrue={routes.length === 0}>
        <HeaderPath path={routePaths.home} label="dashboard" showIcon={false} />
      </RenderIf>
      <RenderIf isTrue={routes.length > 0}>
        {routes.map((path, index) => <HeaderPath key={path} path={`/${path}`} label={removeDashHandler(path)} showIcon={index !== routes.length - 1} />)}
      </RenderIf>
    </div>
  )
}
