import React from 'react'
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa'

interface Props {
  overallRating: number | null
  starSize: number
}
export const RatingStars = (props:Props) => {
  const { overallRating, starSize } = props
  return (
    <div className="flex items-center gap-1">
      {Array.from({ length: 5 }).map((_, index) => {
        const rating = index + 1
        const sumRating = overallRating ?? 0
        if (sumRating >= rating) {
          return <FaStar key={rating} size={starSize} />
        }
        if (sumRating >= rating - 0.5) {
          return <FaStarHalfAlt key={rating} size={starSize} />
        }
        return <FaRegStar key={rating} size={starSize} />
      })}
    </div>
  )
}
