import { BaseApi } from 'services'
import { MessageHistory } from 'features/messages'
import { Ride } from 'features/rides'
import { Transaction } from 'features/transactions'
import { AxiosResponse } from 'axios'
import { ClientsServiceInterface } from './clients-service.interface'
import { Client } from '../interfaces/client'

class ClientsService extends BaseApi implements ClientsServiceInterface {
  readonly endpoint = '/auth/client/'

  public async getClients():Promise<Client[]> {
    return await this.get<Client[]>(this.endpoint)
  }

  public async getClient(id:string):Promise<Client> {
    return await this.get<Client>(`${this.endpoint}${id}/`)
  }

  public async getClientMessages(id: number):Promise<MessageHistory[]> {
    return await this.get<MessageHistory[]>(`${this.endpoint}${id}/get-messages/`)
  }

  public async getClientRideHistory(id: string):Promise<Ride[]> {
    return await this.get<Ride[]>(`${this.endpoint}${id}/get-routes/`)
  }

  public async getClientTransactionsHistory(id: string):Promise<Transaction[]> {
    return await this.get<Transaction[]>(`${this.endpoint}${id}/get-payments/`)
  }

  public clientsTableExport = async ():Promise<AxiosResponse> => this.post(`${this.endpoint}export/`, {}, {
    responseType: 'blob'
  })
}

export default new ClientsService()
