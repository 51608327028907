import { useTranslation } from 'react-i18next'
import { RatingStars } from '../../ui/RatingStars'

interface Props {
  overallRating: number | null
  reviewsCount: number
}
export const DriverOverallRating = (props:Props) => {
  const { overallRating, reviewsCount } = props
  const { t } = useTranslation()
  return (
    <div className="px-7 flex justify-between text-gray pb-6 border-b border-b-light-gray">
      <h3 className="text-xl font-semibold ">{t('rating')}</h3>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-1">
          <RatingStars overallRating={overallRating} starSize={21} />
          <p className="ml-2 text-[22px] font-bold">{overallRating ? overallRating?.toFixed(1) : '-'}</p>
        </div>
        <p className="self-end text-sm font-['Space_Grotesk']">{`${t('basedOn')} ${reviewsCount} ${t(reviewsCount === 1 ? 'review' : 'reviews')}`}</p>
      </div>
    </div>
  )
}
