import { AxiosResponse } from 'axios'
import { BaseApi } from '../../../services'
import { Promotion, PromotionRequestData } from '../interfaces/promotion'
import { PromotionServiceInterface } from './promotions-service.interface'

class PromotionsService extends BaseApi implements PromotionServiceInterface {
  readonly endpoint = '/promotions/promotion/'

  public async getPromotions(): Promise<Promotion[]> {
    return await this.get<Promotion[]>(this.endpoint)
  }

  public async getPromotion(id: string): Promise<Promotion> {
    return await this.get<Promotion>(`${this.endpoint}${id}/`)
  }

  public async createPromotion(data: PromotionRequestData):Promise<AxiosResponse> {
    return await this.post(this.endpoint, data)
  }

  public async editPromotion(data: PromotionRequestData, id: string):Promise<AxiosResponse> {
    return await this.patch(`${this.endpoint}${id}/`, data)
  }

  public async deletePromotion(id:string): Promise<AxiosResponse> {
    return await this.delete(`${this.endpoint}${id}/`)
  }

  public promotionsTableExport = async ():Promise<AxiosResponse> => this.post(`${this.endpoint}export/`, {}, {
    responseType: 'blob'
  })
}

export default new PromotionsService()
