import React from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { HiLocationMarker } from 'react-icons/hi'

export const IslandDropdown = () => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center justify-between gap-5 cursor-not-allowed rounded-[10px] bg-white px-6 py-3 max-w-[286px] w-full text-gray shadow-search">

      <div className="flex items-center gap-[13px]">
        <HiLocationMarker
          size={30}
        />
        <div>
          <p className="font-bold">{t('island')}</p>
          <p className="text-sm text-dark-gray font-['Space_Grotesk']">{t('mykonos')}</p>
        </div>
      </div>
      <BiChevronDown size={30} />
    </div>
  )
}
