import { Outlet } from 'react-router-dom'
import { Driver } from '../../interfaces/driver'
import { DriverApplicationPersonalDetails } from '../driver-application/DriverApplicationPersonalDetails'
import { SingleDriverTabs } from './SingleDriverTabs'
import { SingleDriverActions } from './SingleDriverActions'
import { BlockDriver } from './BlockDriver'
import { RenderIf } from '../../../../components/ui'
import { isAdmin } from '../../../authentication/utils/user-utils'

interface Props {
  driver: Driver
}

export const DisplaySingleDriver = (props:Props) => {
  const { driver } = props
  return (
    <div className="grid">
      <RenderIf isTrue={isAdmin()}>
        <BlockDriver driverId={driver.id} isBlocked={driver.is_blocked} />
      </RenderIf>
      <DriverApplicationPersonalDetails driver={driver} />
      <SingleDriverTabs />
      <div className="overflow-x-auto">
        <Outlet context={driver} />
      </div>
      <SingleDriverActions driverId={driver.id} driverUserId={driver.user.id} />
    </div>
  )
}
