import { useQuery } from '@tanstack/react-query'
import { Admin } from 'interfaces'
import { adminsQuery } from '../helpers/queries'

interface Args {
  initialData: Admin[]
}
export const useAdmins = ({ initialData = [] } :Args) => {
  const { data: admins } = useQuery(
    {
      ...adminsQuery(),
      initialData
    }
  )
  return { admins }
}
