import { RideStatus } from '../interfaces/ride'

export const RideStatusColor = (status: RideStatus) => {
  switch (status) {
    case 'Pending':
      return 'bg-pending shadow-pending'
    case 'Paid':
      return 'bg-paid shadow-paid'
    case 'Accepted':
      return 'bg-blue shadow-light-blue'
    case 'Started':
      return 'bg-started shadow-started'
    case 'Cancelled':
      return 'bg-dark-red shadow-dark-red'
    case 'Pick up':
      return 'bg-pickup shadow-pickup'
    case 'Finished':
      return 'bg-online shadow-online'
    default:
      return ''
  }
}

export const RideStatusTextColor = (status: RideStatus) => {
  switch (status) {
    case 'Pending':
      return 'text-pending'
    case 'Paid':
      return 'text-paid'
    case 'Accepted':
      return 'text-blue'
    case 'Started':
      return 'text-started'
    case 'Cancelled':
      return 'text-dark-red'
    case 'Pick up':
      return 'text-pickup'
    case 'Finished':
      return 'text-online'
    default:
      return ''
  }
}
