import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface Props {
  label: string;
  path: string;
}
export const UnderlineLink = (props:Props) => {
  const { label, path } = props
  const { t } = useTranslation()
  return (
    <Link to={path} className="text-primary-black inline-block underline text-sm hover:opacity-80 transition-opacity duration-300">
      {t(label)}
    </Link>
  )
}
