import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { exception } from 'utils'
import { axiosInstance } from './axios-instance'

export abstract class BaseApi {
  protected async get<T>(url: string): Promise<T> {
    try {
      const response = await axiosInstance.get(url)
      return response.data as T
    } catch (error) {
      this.handleRequestError(error)
      throw error
    }
  }

  protected async post(
    url: string,
    data: unknown,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    try {
      return await axiosInstance.post(url, data, options)
    } catch (error) {
      this.handleRequestError(error)
      throw error
    }
  }

  protected async patch(
    url: string,
    data: unknown
  ): Promise<AxiosResponse> {
    try {
      return await axiosInstance.patch(url, data)
    } catch (error) {
      this.handleRequestError(error)
      throw error
    }
  }

  protected async delete(url: string): Promise<AxiosResponse> {
    try {
      return await axiosInstance.delete(url)
    } catch (error) {
      this.handleRequestError(error)
      throw error
    }
  }

  /**
   * Handles errors that occur during an HTTP request.
   * @param error The error that occurred during the HTTP request.
   */
  private handleRequestError(error: any): void {
    const axiosError = error as AxiosError
    if (axios.isAxiosError(axiosError)) {
      exception(axiosError)
    }
  }
}
