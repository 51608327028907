import { useMemo } from 'react'
import { ConfirmationModal } from 'features/common'
import { Message, MessageUserType } from '../../interfaces/message'
import { MessageCard } from './MessageCard'
import { MessageForm } from './MessageForm'

interface Props {
  messages: Message[]
  userType: MessageUserType
}
export const MessagingList = (props:Props) => {
  const { messages = [], userType } = props
  const filteredMessage = useMemo(
    () => messages.filter((message) => message.owner_user_type === userType),
    [messages, userType]
  )
  return (
    <>
      <MessageForm helperText="predefinedMessage">
        {(handleUpdateMessage) => (
          <div className="mt-6 grid gap-5">
            {filteredMessage.map((message) => (
              <MessageCard
                key={message.id}
                message={message}
                handleUpdateMessage={handleUpdateMessage}
              />
            ))}
          </div>
        )}
      </MessageForm>
      <ConfirmationModal />
    </>
  )
}
