import { useTranslation } from 'react-i18next'

export const TableMessage = () => {
  const { t } = useTranslation()
  return (
    <div className="flex justify-center items-center font-bold mt-5">
      <p>{t('noDataFound')}</p>
    </div>
  )
}
