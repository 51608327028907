import { useEffect, useState } from 'react'
import { UserProfileIcon } from 'components/ui'
import { FormLabel } from 'components/form'
import { Formik } from 'interfaces'
import { UploadDocumentButton } from './UploadDocumentButton'

interface Props<T> extends Formik<T> {
  formikKey: 'image' | 'photo'
}
export const DriversProfileImageUpload = <T extends unknown> (props:Props<T>) => {
  const { formik, formikKey } = props
  const [imagePreview, setImagePreview] = useState<string | null>(null)

  // [formikKey] is a computed property name and we rename it to image
  const { values: { [formikKey]: image } } = formik

  useEffect(() => {
    if (image && ((image as any) instanceof File)) {
      const imageSrc = URL.createObjectURL(image as unknown as Blob)
      setImagePreview(imageSrc)
    } else if (image && typeof image === 'string') {
      setImagePreview(image)
    } else {
      setImagePreview(null)
    }
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview)
      }
    }
    // Don't add imagePreview as a dependency because it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  return (
    <div className="flex items-center gap-5">
      <div className="grid gap-1">
        <FormLabel label="photo" />
        <UserProfileIcon color="#707070" src={imagePreview} size={80} width="w-[80px]" height="h-[80px]" />
      </div>
      <UploadDocumentButton<T>
        label="uploadImage"
        formik={formik}
        formikKey={formikKey}
        acceptedFormats=".png, .jpg, .jpeg"
        supportedFilesText="recommendedImage"
      />
    </div>
  )
}
