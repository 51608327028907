import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Formik } from '../../../../interfaces'
import { DriverFormValues } from '../../interfaces/driver-form'
import { FormReactSelect } from '../../../../components/form'
import { useEmployers } from '../../../../hooks/useEmployers'

interface Props extends Formik<DriverFormValues> {}
export const DriverEmployerInfo = (props:Props) => {
  const { formik } = props
  const { t } = useTranslation()
  const { data: employers } = useEmployers()

  const transformedEmployers = useMemo(() => employers?.map((employer) => ({
    value: employer.id,
    label: `${employer.user.first_name} ${employer.user.last_name}`
  })) ?? [], [employers])

  return (
    <div>
      <h2 className="text-xl mb-5">{t('employerInfo')}</h2>
      <div className="driver-form-child-container">
        <FormReactSelect
          options={transformedEmployers}
          value={transformedEmployers.find((
            employer
          ) => employer.value.toString() === formik.values.employers)}
          placeholder={t('pickUpAnEmployerFromTheList')}
          onChange={(value) => {
            const newValue = value as { value: number, label: string }
            formik.setFieldValue('employers', newValue.value)
          }}
        />
      </div>
    </div>
  )
}
