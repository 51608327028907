import { Outlet } from 'react-router-dom'
import { LogoLogin } from '../../../components/ui'

export const AuthPageLayout = () => (
  <section className="min-h-full sm:h-[100svh] flex flex-col-reverse sm:flex-row">
    <Outlet />
    <div className="p-5 flex justify-center flex-1 items-center sm:w-1/2">
      <LogoLogin />
    </div>
  </section>
)
