import { useTranslation } from 'react-i18next'

const errorSpaceSize = {
  auto: 'top-auto',
  xs: 'top-12',
  sm: 'top-20',
  lg: 'top-24',
  xl: 'top-28'
}
interface Props {
  text: string
  errorSpace?: keyof typeof errorSpaceSize
}
const ErrorText = (props:Props) => {
  const { text, errorSpace = 'sm' } = props
  const { t } = useTranslation()

  return (
    <p className={`absolute ${errorSpaceSize[errorSpace]} text-xs text-dark-red`}>{t(text)}</p>
  )
}

export default ErrorText
