import { useTranslation } from 'react-i18next'
import { Await, useLoaderData } from 'react-router-dom'
import { Transaction, TransactionsTable } from 'features/transactions'
import { useQuery } from '@tanstack/react-query'
import { Suspense } from 'react'
import { Card, LocalApiError } from 'components/ui'
import { TableSkeleton } from 'components/skeletons'
import { Driver } from '../../../interfaces/driver'
import { driverTransactionsHistoryQuery } from '../../../helpers/queries'
import { routePaths } from '../../../../../routes'

interface Props {
  driver: Driver
}
export const DriverTransactionsHistoryLoader = (props:Props) => {
  const { driver } = props
  const { t } = useTranslation()
  const { transactionsHistory } = useLoaderData() as { transactionsHistory: Transaction[] }
  const { data } = useQuery({
    ...driverTransactionsHistoryQuery(driver.id.toString()),
    initialData: transactionsHistory
  })

  return (
    <Card className="p-7">
      <h3 className="text-xl font-semibold text-gray mb-6">{t('transactionsHistory')}</h3>
      <Suspense fallback={<TableSkeleton />}>
        <Await resolve={transactionsHistory} errorElement={<LocalApiError message="failedToLoadTransactions" />}>
          <TransactionsTable transactions={data} path={routePaths.transactions} />
        </Await>
      </Suspense>
    </Card>
  )
}
