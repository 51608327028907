import { useEffect, useState } from 'react'
import { Navigation } from 'features/navigation'
import { Outlet } from 'react-router-dom'
import { Header } from 'components/header'
import { useTranslation } from 'react-i18next'
import { useLogout } from '../../hooks'
import { responseInterceptor } from '../../services/axios-instance'

export const Layout = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const { logout } = useLogout()

  // register response interceptor to handle session expiry
  // it will log out the user and show a toast message
  useEffect(() => {
    responseInterceptor({ logout, toastMsg: t('sessionExpired') })
  })

  return (
    <main className="flex h-screen bg-main-bg">
      <Navigation isOpen={isOpen} setIsOpen={setIsOpen} />
      <section className="flex flex-col h-full w-full md:w-[calc(100%-55px)] lg:w-full ">
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="p-5 2xl:p-12 h-full overflow-x-auto">
          <Outlet />
        </div>
      </section>
    </main>
  )
}
