import { useTranslation } from 'react-i18next'
import { RenderIf } from '../ui'

interface Props {
  id: string
  label?:string
  [key: string]: any
}
export const FormCheckbox = (props:Props) => {
  const { id, label, ...restProps } = props
  const { t } = useTranslation()
  return (
    <div className="flex items-center me-4">
      <input
        {...restProps}
        id={id}
        type="checkbox"
        className="accent-black w-4 h-4 bg-gray-100 border-gray-300 rounded focus:ring-black focus:ring-2"
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <RenderIf isTrue={!!label}>
        <label htmlFor={id} className="ms-2 text-sm text-dark-gray">{t(label!)}</label>
      </RenderIf>
    </div>
  )
}
