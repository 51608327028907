import { Await, useLoaderData } from 'react-router-dom'
import { Admin } from 'interfaces'
import { Suspense } from 'react'
import { ModalProvider } from 'features/common'
import { LocalApiError } from 'components/ui'
import { TableSkeleton } from 'components/skeletons'
import { DisplayUsersTable, useAdmins } from '../index'

export const UsersLoader = () => {
  const { admins } = useLoaderData() as { admins: Admin[] }

  const { admins: data } = useAdmins({ initialData: admins })

  return (
    <Suspense fallback={<TableSkeleton />}>
      <Await resolve={admins} errorElement={<LocalApiError message="failedToLoadUsers" />}>
        <ModalProvider>
          <DisplayUsersTable admins={data} />
        </ModalProvider>
      </Await>
    </Suspense>
  )
}
