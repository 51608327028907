import { Await, useLoaderData } from 'react-router-dom'
import { Transaction, TransactionsTable } from 'features/transactions'
import { useQuery } from '@tanstack/react-query'
import { Card, LocalApiError } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { Suspense } from 'react'
import { TableSkeleton } from 'components/skeletons'
import { Client } from '../../../interfaces/client'
import { clientTransactionsHistoryQuery } from '../../../helpers/queries'
import { routePaths } from '../../../../../routes'

interface Props {
  client: Client
}
export const ClientTransactionsHistoryLoader = (props:Props) => {
  const { client } = props
  const { t } = useTranslation()
  const { transactionsHistory } = useLoaderData() as { transactionsHistory: Transaction[] }
  const { data } = useQuery({
    ...clientTransactionsHistoryQuery(client.id.toString()),
    initialData: transactionsHistory
  })
  return (
    <Card className="p-7">
      <h3 className="text-xl font-semibold text-gray mb-6">{t('transactionsHistory')}</h3>
      <Suspense fallback={<TableSkeleton />}>
        <Await resolve={transactionsHistory} errorElement={<LocalApiError message="failedToLoadTransactions" />}>
          <TransactionsTable transactions={data} path={routePaths.transactions} />
        </Await>
      </Suspense>
    </Card>
  )
}
