const MessageSkeleton = ({ isClient }: { isClient?: boolean }) => {
  const containerClasses = isClient ? 'justify-self-start' : 'justify-self-end flex-row-reverse'
  const messageClasses = isClient ? 'rounded-tl-none' : 'rounded-tr-none self-end'
  const infoClasses = isClient ? 'justify-start' : 'justify-end'
  const triangleClasses = isClient ? '-left-[19px] message-triangle' : '-right-5  message-triangle rotate-180'
  return (
    <div className={`${containerClasses} flex gap-5`}>
      <div className="rounded-full w-[58px] h-[58px] bg-light-gray animate-pulse" />
      <div className="flex flex-col">
        <div className={`flex gap-2 items-baseline ${infoClasses} mb-0.5`}>
          <div className="rounded-[10px] bg-light-gray animate-pulse w-[120px] h-[20px]" />
          <div className="rounded-[10px] bg-light-gray animate-pulse w-[80px] h-[20px]" />
        </div>
        <div className={`flex relative w-[250px] h-[60px] py-[10px] px-5 rounded-[10px] bg-light-gray animate-pulse ${messageClasses}`}>
          <div className={`absolute z-10 flex flex-col gap-2 bg-light-gray animate-pulse top-0 w-5 h-5 ${triangleClasses}`}>
            <div className="h-[1px] -rotate-[24deg] bg-light-gray animate-pulse" />
            <div className="h-[1px] rotate-[24deg] bg-light-gray animate-pulse" />
          </div>
        </div>
      </div>
    </div>
  )
}

export const CommunicationSkeleton = () => (
  <>
    <MessageSkeleton isClient />
    <MessageSkeleton />
    <MessageSkeleton isClient />
    <MessageSkeleton />
  </>
)
