import { IconType } from 'react-icons'
import { useTranslation } from 'react-i18next'

interface Props {
  icon: IconType
  label: string
  contact: string
  type: 'mailto' | 'tel' | 'address'
}
export const ContactInfo = (props:Props) => {
  const { icon: Icon, label, contact, type } = props
  const { t } = useTranslation()
  return (
    <div className="flex flex-wrap items-center gap-2 sm:text-xl">
      <p className="font-bold">
        {t(label)}
        :
      </p>
      <div className="flex items-center gap-2">

        {type === 'address' && <p className="capitalize">{contact}</p>}
        {type !== 'address' && <a className="lowercase" href={`${type}:${contact}`}>{contact}</a>}
        <div className="w-[45px] h-[45px] flex items-center justify-center bg-[#F2F1F1] rounded-full">
          <Icon size={28} className="text-dark-gray" />
        </div>
      </div>

    </div>
  )
}
