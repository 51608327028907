import ErrorText from 'components/ui/ErrorText'
import { RenderIf } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { FormLabel } from './FormLabel'
import { FormInput } from './FormInput'

interface Props {
  label?:string
  hasError:boolean
  errorText?:string
  helperText?: string
  [key:string]: any
  inputSizes?: 'sm' | 'lg',
  labelSizes?: 'sm' | 'lg'
  errorSpacing?: 'auto' | 'xs' | 'sm' | 'lg' | 'xl'
  className?: string
}
export const FormGroup = (props:Props) => {
  const {
    label, hasError, errorText, helperText, errorSpacing, className, inputSizes = 'sm', labelSizes = 'sm', ...restProps
  } = props
  const { t } = useTranslation()
  return (
    <div className="relative flex flex-col gap-1 w-full">
      {label ? <FormLabel label={label} size={labelSizes} /> : null}
      <div className="flex items-center gap-3">
        <FormInput {...restProps} hasError={hasError} size={inputSizes} className={className} />
        <RenderIf isTrue={!!helperText}>
          <small className="text-[#96989A] text-[10px] hidden lg:inline-block">{t(helperText!)}</small>
        </RenderIf>
      </div>
      <RenderIf isTrue={hasError}>
        <ErrorText errorSpace={errorSpacing ?? inputSizes} text={errorText!} />
      </RenderIf>
    </div>
  )
}
