import jwt_decode from 'jwt-decode'
import { User } from 'interfaces'
import { UserUtils } from '../interfaces/user'
import { tokenUtils } from './token-utils'
import { DecodedToken } from '../interfaces/token'

export const userUtils: UserUtils = {
  storeUser: (user: User) => {
    localStorage.setItem('user', JSON.stringify(user))
  },
  getUser: (): User | null => {
    const user = localStorage.getItem('user')
    return user ? JSON.parse(user) as User : null
  },
  deleteUser: () => {
    localStorage.removeItem('user')
  },
  isAlreadyAuthenticated(): boolean {
    const storedUser = localStorage.getItem('user')
    const parsedUser = storedUser ? JSON.parse(storedUser) as User : null
    const token = tokenUtils.accessToken()

    if (!!parsedUser && !!token) {
      const decodedToken: DecodedToken = jwt_decode(token)
      return decodedToken.user_type_id === parsedUser.id
    }
    return false
  },
  isAdmin(): boolean {
    const storedUser = localStorage.getItem('user')
    const parsedUser = storedUser ? JSON.parse(storedUser) as User : null
    return parsedUser?.user.user_type === 'Admin'
  }
}

export const { isAlreadyAuthenticated, isAdmin } = userUtils
