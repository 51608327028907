import { BaseApi } from 'services'
import { MessageHistory } from 'features/messages'
import { AxiosResponse } from 'axios'
import { RidesServiceInterface } from './rides-service.interface'
import { Ride } from '../interfaces/ride'

class RidesService extends BaseApi implements RidesServiceInterface {
  readonly endpoint = '/routes/route/'

  public async getRides():Promise<Ride[]> {
    return await this.get<Ride[]>(`${this.endpoint}finished-or-cancelled/`)
  }

  public async getActiveRides():Promise<Ride[]> {
    return await this.get<Ride[]>(`${this.endpoint}current-route/`)
  }

  public async getScheduledRides():Promise<Ride[]> {
    return await this.get<Ride[]>(`${this.endpoint}scheduled/`)
  }

  public async getRide(id:string):Promise<Ride> {
    return await this.get<Ride>(`${this.endpoint}${id}/`)
  }

  public async getRideMessages(id: number):Promise<MessageHistory[]> {
    return await this.get<MessageHistory[]>(`${this.endpoint}${id}/get-messages/`)
  }

  public ridesTableExport = async (type: 'history' | 'active' | 'scheduled'):Promise<AxiosResponse> => this.post(`${this.endpoint}export/`, {
    type
  }, {

    responseType: 'blob'
  })
}

export default new RidesService()
