import { Await, useLoaderData, useParams } from 'react-router-dom'
import { Suspense } from 'react'
import { LocalApiError, RenderIf } from 'components/ui'
import { DriverApplicationSkeleton } from 'components/skeletons'
import { useTranslation } from 'react-i18next'
import { Driver } from '../../interfaces/driver'
import { DriverApplication } from './DriverApplication'
import { useDriver } from '../../hooks/useDriver'
import { DriversHeader } from '../DriversHeader'

export const DriverApplicationLoader = () => {
  const params = useParams()
  const { driver } = useLoaderData() as { driver: Driver }
  const { driver: data } = useDriver({ id: params.id!, initialData: driver })
  const { t } = useTranslation()
  return (
    <Suspense fallback={<DriverApplicationSkeleton />}>
      <div className="flex gap-4">
        <DriversHeader label="reviewNewDriverApplication" showSearch={false} />
        <RenderIf isTrue={!!data}>
          <p className="text-[#707070] mt-1">
            {`(${t('driverId')} #${data?.id})`}
          </p>
        </RenderIf>
      </div>
      <Await resolve={driver} errorElement={<LocalApiError message="failedToLoadDriver" />}>
        <DriverApplication driver={data} />
      </Await>
    </Suspense>
  )
}
