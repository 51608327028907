import moment from 'moment'
import { RatingHistory } from '../../../interfaces/rating-history'
import { RatingStars } from '../../ui/RatingStars'

interface Props {
  ratingHistory: RatingHistory
}
export const DriverRatingItem = (props:Props) => {
  const { ratingHistory } = props

  return (
    <div className="flex items-end justify-between gap-2 text-gray ">
      <div className="flex flex-col gap-1">
        <p className="font-bold">{ratingHistory.client_name}</p>
        <div className="flex items-center gap-2">
          <RatingStars starSize={18} overallRating={ratingHistory.rating} />
          <p className="font-['Space_Grotesk']">{ratingHistory.rating ? ratingHistory.rating.toFixed(1) : '-'}</p>
        </div>
      </div>
      <p className="font-['Space_Grotesk']">{moment(ratingHistory.date).format('MMM.DD, YY - HH:mm')}</p>
    </div>
  )
}
