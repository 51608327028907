import { RideStatus } from 'features/rides'
import { Card, StarRating } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { transformDateTime } from 'utils'
import { Link } from 'react-router-dom'
import { newDriverStatusColor } from '../../utils/new-driver-status-color'
import { routePaths } from '../../../../routes'

interface Props {
  rideId:number
  arrivedToPickUp: string | null
  arrivedToDropOff: string | null
  status: RideStatus
  dropOffAddress: string | null
  pickUpAddress: string | null
  rating: number | null
  driverFullName: string
  price: string
}
export const NewRideCard = (props:Props) => {
  const {
    rideId,
    arrivedToPickUp,
    pickUpAddress,
    dropOffAddress,
    arrivedToDropOff,
    status,
    driverFullName,
    price,
    rating } = props
  const { t } = useTranslation()

  return (
    <Link to={`${routePaths.rides}/${rideId}`}>
      <Card className="p-5 hover:bg-[rgba(0,0,0,0.01)] transition-colors duration-300 cursor-pointer">
        <div className="flex justify-between items-center flex-wrap gap-3">
          <div>
            <p className="font-semibold">{`${transformDateTime(arrivedToPickUp)} - ${transformDateTime(arrivedToDropOff)}`}</p>
            <p className="text-dark-gray text-sm">
              {`${pickUpAddress ?? '-'} - ${dropOffAddress ?? '-'}`}
            </p>
          </div>
          <div>
            <p className="font-semibold">
              &euro;
              {price}
            </p>
          </div>
        </div>
        <div className="flex justify-between items-center border-t border-t-[#f6f6f6] pt-3 mt-3">
          <div className="flex items-center gap-[10px]">
            <p className="text-sm text-dark-gray capitalize">
              {driverFullName}
            </p>
            <StarRating rating={rating} />
          </div>
          <p className={`text-[11px] text-center rounded-xl py-[4px] mt-1 w-[80px] capitalize ${newDriverStatusColor(status)}`}>{t(status)}</p>
        </div>
      </Card>
    </Link>
  )
}
