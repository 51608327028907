import { Formik } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { FormGroup } from 'components/form'
import { DriverFormValues } from '../../../interfaces/driver-form'
import { UploadDocumentButton } from '../UploadDocumentButton'

interface Props extends Formik<DriverFormValues> {}

export const VehicleInsuranceContractFields = (props:Props) => {
  const { formik } = props
  const { t } = useTranslation()
  return (
    <>
      <div className="driver-form-child-container mt-6">
        <FormGroup
          label="vehicleInsuranceContractNumber"
          placeholder={t<string>('drivingLicenseNumber')}
          hasError={!!(formik.touched.vehicle_insurance_contract_number
                  && formik.errors.vehicle_insurance_contract_number)}
          errorText={formik.errors.vehicle_insurance_contract_number}
          {...formik.getFieldProps('vehicle_insurance_contract_number')}
        />
        <FormGroup
          label="company"
          placeholder={t<string>('company')}
          hasError={!!(formik.touched.vehicle_insurance_company
                      && formik.errors.vehicle_insurance_company)}
          errorText={formik.errors.vehicle_insurance_company}
          {...formik.getFieldProps('vehicle_insurance_company')}
        />
        <div className="grid sm:grid-cols-2 gap-5">
          <FormGroup
            type="date"
            label="dateOfIssue"
            placeholder={t<string>('vehicleInsuranceDateOfIssue')}
            hasError={!!(formik.touched.vehicle_insurance_date_of_issue
                      && formik.errors.vehicle_insurance_date_of_issue)}
            errorText={formik.errors.vehicle_insurance_date_of_issue}
            {...formik.getFieldProps('vehicle_insurance_date_of_issue')}
          />
          <FormGroup
            type="date"
            label="dateOfExpiry"
            placeholder={t<string>('vehicleInsuranceDateOfExpiry')}
            hasError={!!(formik.touched.vehicle_insurance_date_of_expiry
                      && formik.errors.vehicle_insurance_date_of_expiry)}
            errorText={formik.errors.vehicle_insurance_date_of_expiry}
            {...formik.getFieldProps('vehicle_insurance_date_of_expiry')}
          />
        </div>
      </div>
      <div className="mt-5">
        <UploadDocumentButton
          label="uploadDocument"
          formik={formik}
          formikKey="vehicle_insurance_contract"
          acceptedFormats=".jpg, .jpeg, .png, .pdf"
          supportedFilesText="supportedFile"
        />
      </div>
    </>
  )
}
