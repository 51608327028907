import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { QueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { errorToastMessage, successToastMessage } from '../../../utils'
import { Translation } from '../../../interfaces'
import promotionsService from '../services/promotions-service'
import { PromotionFormValues, PromotionRequestData } from '../interfaces/promotion'
import { routePaths } from '../../../routes'

export const promotionAction = (queryClient:QueryClient, t:Translation) => async (
  { request, params }:ActionFunctionArgs
) => {
  const formData = await request.formData()
  const values = JSON.parse(formData.get('values') as string) as PromotionFormValues
  const { method } = request
  const data: PromotionRequestData = {
    title: values.title,
    discount: values.discount,
    promotion_start_date: values.startDate,
    promotion_end_date: values.endDate,
    redemption_limit: values.usesLimit,
    promotion_client: values.promotion_client
  }

  try {
    if (values.promotion_client?.length === 0) {
      delete data.promotion_client
    }
    if (values.noEndDate) {
      data.promotion_end_date = null
    }
    if (values.unlimited) {
      data.redemption_limit = -1
    }

    if (method === 'POST') {
      await promotionsService.createPromotion(data)
      successToastMessage(t('promotionHasBeenCreated'))
    }

    if (method === 'PATCH') {
      const { id } = params as { id: string }
      await promotionsService.editPromotion(data, id)
      await queryClient.invalidateQueries(['promotion', id])
      successToastMessage(t('promotionHasBeenUpdated'))
    }

    await queryClient.invalidateQueries(['promotions'])
    return redirect(routePaths.promotions)
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}
export const deletePromotionAction = (queryClient:QueryClient, t:Translation) => async (
  { request }:ActionFunctionArgs
) => {
  const data = await request.formData()
  const id = data.get('id') as string
  try {
    await promotionsService.deletePromotion(id)
    await queryClient.invalidateQueries(['promotions'])
    successToastMessage(t('promotionHasBeenDeleted'))
    return {
      response: 'ok'
    }
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}
