import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import { useActionData, useNavigate, useNavigation, useSubmit } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Modal } from '../../../common'
import { PrimaryButton } from '../../../../components/ui'
import { FormReactSelect } from '../../../../components/form'
import { routePaths } from '../../../../routes'
import { driversQuery } from '../../../drivers'
import { Employer } from '../../interfaces/employer'

interface Props {
  addDriverModal: boolean
  setAddDriverModal: Dispatch<SetStateAction<boolean>>
  employer:Employer
}
export const AddDriverModal = (props:Props) => {
  const { employer, addDriverModal, setAddDriverModal } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const submit = useSubmit()
  const navigation = useNavigation()
  const isSubmitting = navigation.state === 'submitting'
  const actionData = useActionData() as { response: string }

  const onClose = useCallback(() => setAddDriverModal(false), [setAddDriverModal])

  const formik = useFormik({
    initialValues: {
      driver: ''
    },
    onSubmit: (values) => {
      if (!formik.values.driver) {
        formik.setFieldError('driver', ' ')
        return
      }

      submit({
        id: values.driver
      }, { method: 'patch' })
    }
  })

  const { data: drivers } = useQuery({
    ...driversQuery()
  })

  const transformedDrivers = useMemo(() => drivers?.filter((
    driver
  ) => {
    const uniqueIds = new Set(employer.drivers.map((el) => el.id))
    return !uniqueIds.has(driver.id)
  }).map(
    (driver) => ({
      value: driver.id,
      label: `${driver.user.first_name} ${driver.user.last_name}`
    })
  ) ?? [], [drivers, employer])

  useEffect(() => {
    if (actionData?.response === 'ok') {
      onClose()
    }
  }, [actionData, onClose])

  return (
    <Modal showModal={addDriverModal} onClose={onClose} maxWidth="max-w-[740px]">
      <div className="relative  w-full ">
        <form onSubmit={formik.handleSubmit} className="relative bg-white rounded-xl shadow dark:bg-gray-700 px-4">
          <div className="flex items-center justify-between p-4 md:p-5 border-b border-b-[#a9a9a9]">
            <h3 className="text-3xl text-black font-semibold">
              {t('addDriver')}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
          <div className="p-4 md:p-5  flex flex-col sm:flex-row sm:items-center gap-5">
            <div>
              <p className="mb-3">{t('createNewDriver')}</p>
              <PrimaryButton size="md" text="newDriver" type="button" hasIcon onClickHandler={() => navigate(`${routePaths.drivers}/${routePaths.newDriver}`)} />
            </div>
            <p className="text-xl font-bold sm:translate-y-3">{t('or')}</p>
            <div className="w-full">
              <p className="mb-3">{t('selectExistingDriverToAddToEmployer')}</p>
              <FormReactSelect
                hasError={!!formik.errors.driver}
                options={transformedDrivers}
                placeholder={t('pickUpADriverFromTheList')}
                onChange={(value) => {
                  const newValue = value as { value: number, label: string }
                  formik.setFieldValue('driver', newValue.value)
                }}
              />
            </div>
          </div>
          <div className="flex justify-end p-4 md:p-5 border-t border-t-[#a9a9a9]  dark:border-gray-600">
            <PrimaryButton
              text="save"
              type="submit"
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}
