import { Await, useLoaderData } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Suspense } from 'react'
import { Promotion } from '../interfaces/promotion'
import { promotionsQuery } from '../helpers/queries'
import { TableSkeleton } from '../../../components/skeletons'
import { LocalApiError } from '../../../components/ui'
import PromotionsTable from './PromotionsTable'
import { ModalProvider } from '../../common'
import { Client } from '../../clients'

export const PromotionsLoader = () => {
  const { promotions } = useLoaderData() as { promotions: Promotion[], clients: Client[] }
  const { data } = useQuery({
    ...promotionsQuery(),
    initialData: promotions
  })

  return (
    <Suspense fallback={<TableSkeleton />}>
      <Await resolve={promotions} errorElement={<LocalApiError message="failedToLoadPromotions" />}>
        <ModalProvider>
          <PromotionsTable promotions={data} />
        </ModalProvider>
      </Await>
    </Suspense>
  )
}
