import { useWidth } from 'hooks'

const NewDriversCardSkeleton = () => {
  const { width } = useWidth()
  const classes = 'bg-light-gray h-full w-full max-w-[150px] animate-pulse rounded-[10px]'
  return (
    <div className="p-5 h-[68px] bg-white shadow-card rounded-[10px] gap-5 grid grid-cols-3 sm:grid-cols-4 items-center justify-between animate-pulse">
      <div className={classes} />
      <div className={classes} />
      <div className={classes} />
      {width && width >= 640 && <div className={classes} />}
    </div>
  )
}
export const DisplayNewDriversSkeleton = () => (
  <div className="flex flex-col gap-5">
    <NewDriversCardSkeleton />
    <NewDriversCardSkeleton />
    <NewDriversCardSkeleton />
    <NewDriversCardSkeleton />
    <NewDriversCardSkeleton />
  </div>
)
