import { BackButton, PrimaryButton, RenderIf } from '../../../../components/ui'

interface Props {
  receiptLink: string | null
}
const TransactionActionButtons = (props:Props) => {
  const { receiptLink } = props
  return (
    <div className="grid sm:flex sm:items-center sm:justify-between gap-5">
      <BackButton />
      <RenderIf isTrue={!!receiptLink}>
        <PrimaryButton text="viewReceipt" as="a" href={receiptLink!} />
      </RenderIf>
    </div>
  )
}

export default TransactionActionButtons
