import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { TableSkeleton } from 'components/skeletons'
import { LocalApiError } from 'components/ui'
import { useQuery } from '@tanstack/react-query'
import { Ride } from '../../interfaces/ride'
import { RidesTable } from './RidesTable'
import { activeRidesQuery } from '../../helpers/queries'
import { ridesService } from '../../index'

export const ActiveRidesLoader = () => {
  const { activeRides } = useLoaderData() as { activeRides: Ride[] }
  const { data } = useQuery({
    ...activeRidesQuery(),
    initialData: activeRides
  })

  return (
    <Suspense fallback={<TableSkeleton />}>
      <Await resolve={activeRides} errorElement={<LocalApiError message="failedToLoadRides" />}>
        <RidesTable
          rides={data}
          exportAction={() => ridesService.ridesTableExport('active')}
        />
      </Await>
    </Suspense>
  )
}
