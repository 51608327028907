import { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { LocalApiError } from '../../../../../components/ui'
import { RatingHistory } from '../../../interfaces/rating-history'
import { Driver } from '../../../interfaces/driver'
import { driverRatingHistoryQuery } from '../../../helpers/queries'
import { DriverRatingHistory } from './DriverRatingHistory'

interface Props {
  driver: Driver
}
export const DriverRatingHistoryLoader = (props:Props) => {
  const { driver } = props
  const { ratingHistory } = useLoaderData() as { ratingHistory:RatingHistory[] }
  const { data } = useQuery({
    ...driverRatingHistoryQuery(driver.id.toString()),
    initialData: ratingHistory
  })

  return (
    <Suspense>
      <Await resolve={ratingHistory} errorElement={<LocalApiError message="failedToLoadRatingHistory" />}>
        <DriverRatingHistory ratingHistory={data} driver={driver} />
      </Await>
    </Suspense>
  )
}
