import { useMemo } from 'react'
import { Ride } from 'features/rides'
import { UnderlineLink } from 'components/ui'
import { routePaths } from 'routes'
import { NewRideCard } from './NewRideCard'

interface Props {
  rides: Ride[]
}
export const DisplayNewRides = (props:Props) => {
  const { rides } = props
  const newRides = useMemo(() => rides.slice(0, 3), [rides])

  return (
    <>
      <div className="flex flex-col gap-5">
        {newRides.map((ride) => (
          <NewRideCard
            key={ride.id}
            rideId={ride.id}
            arrivedToPickUp={ride.arrived_to_pick_up}
            arrivedToDropOff={ride.arrived_to_drop_off}
            status={ride.status}
            dropOffAddress={ride.drop_off_address}
            pickUpAddress={ride.pick_up_address}
            rating={ride.driver_rating}
            price={ride.price}
            driverFullName={`${ride.driver?.user?.first_name ?? '-'} ${ride.driver?.user?.last_name ?? '-'}`}
          />
        ))}
      </div>
      <div className="flex justify-end mt-3">
        <UnderlineLink path={`${routePaths.rides}`} label="viewAll" />
      </div>
    </>
  )
}
