import { BaseApi } from 'services'
import { AxiosResponse } from 'axios'
import { Message, MessageUserType } from '../interfaces/message'
import { MessagesServiceInterface } from './messages-service.interface'
import { CancellationMessage } from '../interfaces/cancellation-message'

class MessagesService extends BaseApi implements MessagesServiceInterface {
  readonly endpoint = '/routes'

  public async getMessages(): Promise<Message[]> {
    return this.get<Message[]>(`${this.endpoint}/default-message/`)
  }

  public async createMessage(message:string, userType: MessageUserType): Promise<AxiosResponse> {
    const data = {
      text: message,
      owner_user_type: userType
    }
    return this.post(`${this.endpoint}/default-message/`, data)
  }

  public async updateMessage(id:string, message:string, userType: MessageUserType):
  Promise<AxiosResponse> {
    const data = {
      text: message,
      owner_user_type: userType
    }
    return this.patch(`${this.endpoint}/default-message/${id}/`, data)
  }

  public async deleteMessage(id:string): Promise<AxiosResponse> {
    return this.delete(`${this.endpoint}/default-message/${id}/`)
  }

  public async getCancellationMessages(): Promise<CancellationMessage[]> {
    return this.get<CancellationMessage[]>(`${this.endpoint}/cancellation-reason/`)
  }

  public async createCancellationMessages(message:string): Promise<AxiosResponse> {
    return this.post(`${this.endpoint}/cancellation-reason/`, { name: message })
  }

  public async updateCancellationMessages(id:string, message:string): Promise<AxiosResponse> {
    return this.patch(`${this.endpoint}/cancellation-reason/${id}/`, { name: message })
  }

  public async deleteCancellationMessages(id:string): Promise<AxiosResponse> {
    return this.delete(`${this.endpoint}/cancellation-reason/${id}/`)
  }
}

export default new MessagesService()
