import { useTranslation } from 'react-i18next'

interface Props {
  label: string;
  value: string | null;
}

export const DetailsKeyValue = (props:Props) => {
  const { label, value } = props
  const { t } = useTranslation()
  return (
    <div className="flex gap-1">
      <p className="font-normal">
        {t(label)}
        :
      </p>
      <p>{t(value ?? '-')}</p>
    </div>
  )
}
