import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { routePaths } from 'routes'
import { useLogout } from 'hooks'
import { MdLogout } from 'react-icons/md'
import { BsPeople } from 'react-icons/bs'
import { RenderIf } from '../ui'
import { isAdmin } from '../../features/authentication/utils/user-utils'

interface Props {
  text: string
  icon: JSX.Element
  onClickHandler: () => void
}

const HeaderButton = (props:Props) => {
  const { text, icon, onClickHandler } = props
  const { t } = useTranslation()
  return (
    <button type="button" onClick={onClickHandler} className="flex min-h-full items-center gap-2 border-l border-l-white text-sm font-semibold text-white px-7 hover:opacity-80 transition-opacity duration-200">
      {icon}
      <span>{t(text)}</span>
    </button>
  )
}

export const HeaderButtons = () => {
  const navigate = useNavigate()
  const { logout } = useLogout()
  return (
    <div className="hidden ml-auto md:flex">
      <RenderIf isTrue={isAdmin()}>
        <HeaderButton text="manageUsers" icon={<BsPeople size={21} />} onClickHandler={() => navigate(routePaths.manageUsers)} />
      </RenderIf>
      <HeaderButton text="logout" icon={<MdLogout size={21} />} onClickHandler={logout} />
    </div>
  )
}
