import { Card } from 'components/ui'
import { TableActions } from 'components/table'
import { useModalContext } from 'features/common'
import { Message } from '../../interfaces/message'

interface Props {
  message: Message
  handleUpdateMessage: (selectedMessage: string, id: number) => void
}
export const MessageCard = (props:Props) => {
  const { message, handleUpdateMessage } = props
  const { onOpen } = useModalContext()
  return (
    <Card className="flex items-center justify-between px-7 py-5 text-gray font-bold">
      <p>{message.text}</p>
      <TableActions
        onEditHandler={() => {
          handleUpdateMessage(message.text, message.id)
        }}
        onDeleteHandler={() => onOpen({
          message: 'deleteMessage',
          resourceId: message.id
        })}
      />
    </Card>
  )
}
