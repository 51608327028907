import { useState } from 'react'
import { Driver } from '../../interfaces/driver'
import { UncheckedDriversCard } from './UncheckedDriversCard'
import { UncheckedDriversRangeButton } from './UncheckedDriversRangeButton'
import { RenderIf } from '../../../../components/ui'

interface Props {
  uncheckedDrivers: Driver[]
}

export const UncheckedDrivers = (props:Props) => {
  const { uncheckedDrivers = [] } = props
  const [isShownAll, setIsShownAll] = useState<boolean>(false)
  const onClickViewAll = () => setIsShownAll((prev) => !prev)
  const isShownAnimated = isShownAll ? 'max-h-screen' : 'max-h-[120px]'
  return (
    <div className="mb-5 sm:mb-10">
      <div className={` ${isShownAnimated} overflow-x-auto flex flex-col gap-3 transition-all duration-300`}>
        {uncheckedDrivers.map((driver) => (
          <UncheckedDriversCard
            key={driver.id}
            id={driver.id}
            firstName={driver.user.first_name}
            lastName={driver.user.last_name}
            email={driver.user.email}
            phone={driver.user.phone_number}
          />
        ))}
      </div>
      <RenderIf isTrue={uncheckedDrivers.length > 2}>
        <div className={`flex justify-center transition-all duration-300 ${isShownAll ? 'mt-3' : 'mt-0'}`}>
          <UncheckedDriversRangeButton onClick={onClickViewAll} label={isShownAll ? 'viewLess' : 'viewAll'} />
        </div>
      </RenderIf>
    </div>
  )
}
