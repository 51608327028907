import { IoClose } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { useModalContext } from 'features/common'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { clientsService } from 'features/clients'
import { ridesService } from 'features/rides'
import { MessageUserType } from 'features/messages'
import { UserProfileIcon } from 'components/ui'
import moment from 'moment'
import { useMemo } from 'react'
import { CommunicationSkeleton } from 'components/skeletons'
import { Modal } from './Modal'

interface MessageProps {
  image: string | null
  message: string
  created: string
  userType: MessageUserType
  firstName: string
  lastName: string
}

const Message = (props:MessageProps) => {
  const { image, message, created, userType, firstName, lastName } = props
  const isClient = userType === 'Client'

  const containerClasses = isClient ? 'justify-self-start' : 'justify-self-end flex-row-reverse'
  const messageClasses = isClient ? 'bg-white rounded-tl-none' : 'bg-[#E7E7E7] rounded-tr-none self-end'
  const infoClasses = isClient ? 'justify-start' : 'justify-end'
  const triangleClasses = isClient ? '-left-5 bg-white top-[3px]' : '-right-5 -top-[1px] message-triangle bg-[#E7E7E7] h-5 rotate-180'

  return (
    <div className={`${containerClasses} flex gap-5`}>
      <UserProfileIcon src={image} width="w-[58px]" height="h-[58px]" size={58} />
      <div className="flex flex-col">
        <div className={`font-['Space_Grotesk'] flex gap-2 items-baseline ${infoClasses} mb-0.5`}>
          <p className="capitalize text-sm">{`${firstName} ${lastName}`}</p>
          <p className="text-xs text-gray">{moment(created).format('DD/MM/YY, HH:mm')}</p>
        </div>
        <div className={`flex relative max-w-[450px] w-max py-[10px] px-5 rounded-[10px] border border-[#E7E7E7] ${messageClasses}`}>
          {isClient && <div className="absolute z-10 bg-white w-4 h-4 -left-1 top-0 " />}
          <div className={`absolute z-10 flex flex-col gap-2 w-5  ${triangleClasses}`}>
            <div className="h-[1px] -rotate-[24deg] bg-[#E7E7E7]" />
            <div className="h-[1px] rotate-[24deg] bg-[#E7E7E7]" />
          </div>
          <p className={isClient ? 'ml-auto w-max' : ''}>
            {message}
          </p>
        </div>
      </div>
    </div>
  )
}

export const MessagesModal = () => {
  const { showModal, onClose, resourceId } = useModalContext()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const isClient = pathname.includes('clients')
  const messagesType = isClient ? 'clientMessages' : 'rideMessages'

  const { data: messages, isLoading, isError } = useQuery({
    queryKey: [messagesType, resourceId],
    queryFn: isClient ? () => clientsService.getClientMessages(resourceId!)
      : () => ridesService.getRideMessages(resourceId!),
    enabled: !!resourceId
  })
  useMemo(() => messages?.sort((a, b) => a.created.localeCompare(b.created)), [messages])
  return (
    <Modal showModal={showModal} onClose={onClose} maxWidth="max-w-[695px]">
      <div className="flex flex-col bg-white shadow-md rounded-xl p-7 max-h-[90svh] overflow-y-auto">
        <div className="h-8 w-8 rounded-full flex items-center justify-center mb-3 ml-auto bg-[#F5F5F5] hover:opacity-70 transition-opacity duration-300">
          <IoClose onClick={onClose} size={25} cursor="pointer" />
        </div>
        <div className="border-b border-b-[#d4d4d4] pb-6">
          <h2 className="text-3xl font-semibold">{t('communicationHistory')}</h2>
        </div>
        <div className="mt-10 grid gap-4">
          {isError && <p className="text-lg font-semibold">{t('failedToLoadMessages')}</p>}
          {isLoading && <CommunicationSkeleton />}
          {!isLoading && messages?.map((message) => (
            <Message
              key={message.id}
              image={message.sender.image}
              message={message.message}
              created={message.created}
              userType={message.sender.user_type}
              firstName={message.sender.first_name}
              lastName={message.sender.last_name}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}
