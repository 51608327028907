const AnalyticsCardSkeleton = () => (
  <div className="w-full gap-10 2xl:gap-14 p-10 pr-3 flex shadow-card rounded-[10px] items-center animate-pulse bg-white">
    <div className="h-[40px] w-[50px] rounded-[10px]  bg-light-gray animate-pulse" />
    <div className="flex flex-col gap-2 w-[100px] ">
      <div className="h-[44px] rounded-[10px]  bg-light-gray animate-pulse" />
      <div className="h-[24px] rounded-[10px]  bg-light-gray animate-pulse" />
    </div>
  </div>
)
export const AnalyticsCardsSkeletons = () => (
  <div className="xl:flex xl:justify-between grid md:grid-cols-2 gap-5 sm:gap-8">
    <AnalyticsCardSkeleton />
    <AnalyticsCardSkeleton />
    <AnalyticsCardSkeleton />
    <AnalyticsCardSkeleton />
  </div>
)
