import { RideStatus } from 'features/rides'

export const newDriverStatusColor = (status: RideStatus) => {
  switch (status) {
    case 'Accepted':
      return 'text-onRoute bg-[rgba(245,201,26,0.4)]'
    case 'Started':
      return 'text-started bg-[rgba(172,51,233,0.4)]'
    case 'Cancelled':
      return 'text-dark-red bg-[rgba(156,58,21,0.4)]'
    case 'Pending':
      return 'text-pending bg-[rgba(228,127,11,0.4)] '
    case 'Pick up':
      return 'text-pickup bg-[rgba(39,110,241,0.4)]'
    case 'Finished':
      return 'text-online bg-[rgba(21,156,30,0.4)]'
    default:
      return ''
  }
}
