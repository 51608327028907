import { useTranslation } from 'react-i18next'
import { FormGroup } from '../../../../components/form'
import { Formik } from '../../../../interfaces'
import { DriverFormValues } from '../../interfaces/driver-form'

interface Props extends Formik<DriverFormValues> {}

export const DriverCompanyFields = (props: Props) => {
  const { formik } = props
  const { t } = useTranslation()
  return (
    <div>
      <h2 className="text-xl mb-5">{t('companyInfo')}</h2>
      <div className="driver-form-child-container">
        <FormGroup
          label="companyName"
          placeholder={t<string>('companyName')}
          hasError={!!(formik.touched.company_name && formik.errors.company_name)}
          errorText={formik.errors.company_name}
          {...formik.getFieldProps('company_name')}
        />
        <FormGroup
          label="companyTaxId"
          placeholder={t<string>('companyTaxId')}
          hasError={!!(formik.touched.company_tax_id && formik.errors.company_tax_id)}
          errorText={formik.errors.company_tax_id}
          {...formik.getFieldProps('company_tax_id')}
        />
        <FormGroup
          label="taxDepartment"
          placeholder={t<string>('taxDepartment')}
          hasError={!!(formik.touched.company_type && formik.errors.company_type)}
          errorText={formik.errors.company_type}
          {...formik.getFieldProps('company_type')}
        />
        <FormGroup
          label="companyNumber"
          placeholder={t<string>('companyNumber')}
          hasError={!!(formik.touched.company_number && formik.errors.company_number)}
          errorText={formik.errors.company_number}
          {...formik.getFieldProps('company_number')}
        />
        <FormGroup
          label="companyAddress"
          placeholder={t<string>('companyAddress')}
          hasError={!!(formik.touched.company_address && formik.errors.company_address)}
          errorText={formik.errors.company_address}
          {...formik.getFieldProps('company_address')}
        />
      </div>
    </div>
  )
}
