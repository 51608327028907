import { Text } from 'components/ui'
import { HamburgerMenu, NavMenu } from 'features/navigation'
import { HeaderRoutes } from './HeaderRoutes'
import { HeaderButtons } from './HeaderButtons'

export const Header = (props:NavMenu) => {
  const { isOpen, setIsOpen } = props
  return (
    <header className="grid">
      <div className="flex gap-5 bg-primary-black pl-5 md:pl-10 2xl:pl-[68px] h-[50px] md:w-[calc(100%+20px)] md:-translate-x-5 pr-5 md:pr-0">
        <div className="my-auto"><Text text="dropOffAdmin" size="sm" color="light" weight="semibold" /></div>

        {/* display on desktops */}
        <HeaderButtons />

        {/* display on mobile screen min-width:768px */}
        <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div className="flex items-center border-b border-b-white-100 pl-5 2xl:pl-12 h-[50px]">
        <HeaderRoutes />
      </div>
    </header>
  )
}
