import { QueryClient } from '@tanstack/react-query'
import { LoaderFunctionArgs, defer } from 'react-router-dom'
import { isAlreadyAuthenticated } from 'features/authentication'
import { authRedirect } from 'utils'
import { ridesQuery, rideQuery, activeRidesQuery, scheduledRidesQuery } from './queries'

export const ridesLoader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated()) return authRedirect()

  return defer({
    rides: queryClient.ensureQueryData(ridesQuery())
  })
}

export const activeRidesLoader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated()) return authRedirect()

  return defer({
    activeRides: queryClient.ensureQueryData(activeRidesQuery())
  })
}

export const scheduledRidesLoader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated()) return authRedirect()

  return defer({
    scheduledRides: queryClient.ensureQueryData(scheduledRidesQuery())
  })
}

export const rideLoader = (queryClient: QueryClient) => ({ params }:LoaderFunctionArgs) => {
  if (!isAlreadyAuthenticated()) return authRedirect()
  return defer({
    ride: queryClient.ensureQueryData(rideQuery(params.id!))
  })
}
