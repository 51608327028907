import { Form, FormActions } from 'components/form'
import { useFormik } from 'formik'
import { useNavigation, useSubmit } from 'react-router-dom'
import { Driver } from '../../interfaces/driver'
import { DriversDetailsFields } from './DriversDetailsFields'
import { DriverFormValues } from '../../interfaces/driver-form'
import { validationSchema } from '../../utils/validation-schema'
import { DriverCompanyFields } from './DriverCompanyFields'
import { DriverIbanFields } from './DriverIbanFields'
import { DriverLicenseInfoFields } from './DriverLicenseInfoFields/DriverLicenseInfoFields'
import { DriverEmployerInfo } from './DriverEmployerInfo'

interface Props {
  method: 'post' | 'patch'
  driver?: Driver
}

type FormikFilesKeys = keyof DriverFormValues
export const formikFilesKeys: FormikFilesKeys[] = ['driving_license', 'vehicle_registration_license', 'vehicle_insurance_contract', 'identification_card', 'driving_license', 'iban_file']
export const DriversForm = (props:Props) => {
  const { driver, method } = props
  const submit = useSubmit()
  const navigation = useNavigation()

  const formik = useFormik<DriverFormValues>({
    initialValues: {
      // Personal Info
      first_name: driver?.user.first_name ?? '',
      last_name: driver?.user.last_name ?? '',
      email: driver?.user.email ?? '',
      phone_number: driver?.user.phone_number ?? '',
      father_name: driver?.father_name ?? '',
      residency: driver?.residency ?? '',
      address: driver?.address ?? '',

      // License Info
      vehicle_registration_license_number: driver?.vehicle_registration_license_number ?? '',
      registration_plate_number: driver?.registration_plate_number ?? '',
      car_model: driver?.car_model ?? '',
      vehicle_registration_license: driver?.vehicle_registration_license ?? '',

      driving_license_number: driver?.driving_license_number ?? '',
      license_category: driver?.license_category ?? '',
      license_city_of_issue: driver?.license_city_of_issue ?? '',
      license_date_of_issue: driver?.license_date_of_issue ?? '',
      license_date_of_expiry: driver?.license_date_of_expiry ?? '',
      driving_license: driver?.driving_license ?? '',

      identification_card_number: driver?.identification_card_number ?? '',
      identification_card: driver?.identification_card ?? '',

      vehicle_insurance_contract_number: driver?.vehicle_insurance_contract_number ?? '',
      vehicle_insurance_company: driver?.vehicle_insurance_company ?? '',
      vehicle_insurance_date_of_issue: driver?.vehicle_insurance_date_of_issue ?? '',
      vehicle_insurance_date_of_expiry: driver?.vehicle_insurance_date_of_expiry ?? '',
      vehicle_insurance_contract: driver?.vehicle_insurance_contract ?? '',

      // Payment Info
      iban: driver?.iban ?? '',
      iban_name: driver?.iban_name ?? '',
      iban_file: driver?.iban_file ?? '',
      bic: driver?.bic ?? '',

      // Company Info
      company_name: driver?.company_name ?? '',
      company_tax_id: driver?.company_tax_id ?? '',
      company_address: driver?.company_address ?? '',
      company_number: driver?.company_number ?? '',
      company_type: driver?.company_type ?? '',

      image: driver?.image ?? '',
      password: driver ? '********' : '',
      employers: driver?.employers?.[0]?.id.toString() ?? ''
    },
    validationSchema: method === 'post' ? validationSchema.addNewDriver : undefined,
    onSubmit: (values) => {
      submit(values, { method, encType: 'multipart/form-data' })
    }
  })

  return (
    <Form
      method={method}
      onSubmit={formik.handleSubmit}
      className="grid gap-12"
    >
      <DriversDetailsFields formik={formik} method={method} />
      <DriverLicenseInfoFields formik={formik} />
      <DriverIbanFields formik={formik} />
      <DriverCompanyFields formik={formik} />
      <DriverEmployerInfo formik={formik} />
      <FormActions<DriverFormValues>
        resetForm={formik.resetForm}
        isLoading={navigation.state === 'submitting'}
      />
    </Form>
  )
}
