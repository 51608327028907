import { Await, useLoaderData } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Suspense } from 'react'
import { LocalApiError } from 'components/ui'
import { TableSkeleton } from 'components/skeletons'
import { TransactionsTable } from './TransactionsTable'
import { transactionsQuery } from '../../helpers/queries'
import { Transaction } from '../../interfaces/transaction'

export const TransactionsLoader = () => {
  const { transactions } = useLoaderData() as { transactions: Transaction[] }
  const { data } = useQuery({ ...transactionsQuery(), initialData: transactions })
  return (
    <Suspense fallback={<TableSkeleton />}>
      <Await resolve={transactions} errorElement={<LocalApiError message="failedToLoadTransactions" />}>
        <TransactionsTable transactions={data} />
      </Await>
    </Suspense>
  )
}
