export const routePaths = {
  home: '/',
  drivers: '/drivers',
  clients: '/clients',
  rides: '/rides',
  transactions: '/transactions',
  fees: '/fees',
  messaging: '/messaging',
  promotions: '/promotions',
  manageUsers: '/manage-users',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  employers: '/employers',
  newAdmin: 'new-admin',
  editAdmin: 'edit-admin',
  newDriver: 'add-new-driver',
  newEmployer: 'add-new-employer',
  editEmployer: 'edit-employer',
  editDriver: 'edit-driver',
  reviewDriver: 'driver-application',
  active: 'active',
  scheduled: 'scheduled',
  driverCancellationReplies: 'driver-cancellation-replies',
  driverToClient: 'driver',
  driverCompanyAndPaymentInfo: 'company-and-payment-info',
  driverLicenseDetails: 'license-details',
  driverRatingHistory: 'rating-history',
  rideHistory: 'ride-history',
  transactionsHistory: 'payments-history',
  newPromo: 'add-new-promo',
  editPromo: 'edit-promo',
  employerDrivers: 'employer-drivers',
  deniedDrivers: 'denied'
}
