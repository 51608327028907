import { defer } from 'react-router-dom'
import { QueryClient } from '@tanstack/react-query'
import { authRedirect } from 'utils'
import { isAlreadyAuthenticated } from '../../authentication'
import { zonesQuery } from './queries'
import { isAdmin } from '../../authentication/utils/user-utils'

export const feesLoader = ((queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    zones: queryClient.ensureQueryData(zonesQuery())
  })
})
