import { useNavigation, useSubmit, Link } from 'react-router-dom'
import { Form, FormGroup } from 'components/form'
import { PrimaryButton, Text, Title } from 'components/ui'
import { routePaths } from 'routes'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { validationSchema } from '../utils/validation-schema'

export const ForgotPasswordForm = () => {
  const submit = useSubmit()
  const navigation = useNavigation()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: validationSchema.forgotPasswordForm,
    onSubmit: async (values) => {
      submit(values, { method: 'post' })
    }
  })

  return (
    <div className="p-5 bg-light-gray rounded-t-[40px] sm:w-1/2 sm:rounded-r-[64px] sm:rounded-tl-[0px] flex flex-col items-center justify-center">
      <div className="flex flex-col gap-2 mb-5 sm:mb-14 lg:-translate-x-12">
        <Title title="forgotPassword" size="lg" />
        <Text text="enterEmailToReset" size="xl" color="gray" />
      </div>
      <Form
        method="post"
        onSubmit={formik.handleSubmit}
        className="max-w-[485px] w-full flex flex-col gap-6"
      >
        <FormGroup
          id="email"
          label="email"
          placeholder={t('insertEmail')}
          hasError={!!(formik.touched.email && formik.errors.email)}
          errorText={formik.errors.email}
          labelSizes="lg"
          inputSizes="lg"
          {...formik.getFieldProps('email')}
        />
        <PrimaryButton
          text="submit"
          type="submit"
          isLoading={navigation.state === 'submitting'}
          size="lg"
        />
        <Link
          to={routePaths.login}
          className="mx-auto text-primary-black"
        >
          {t('alreadyHaveAccount')}
        </Link>
      </Form>
    </div>
  )
}
