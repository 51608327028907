import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { bool } from 'yup'
import { transformDateTime } from '../../../../utils'
import { RenderIf } from '../../../../components/ui'
import { calculateRefund } from '../../utils/calculate-refund'

interface Props {
  arrivedToPickUp: string | null
  arrivedToDropOff: string | null
  acceptedTime: string | null
  cancelledTime: string | null
  scheduledTime: string | null
  pickUpAddress: string | null
  dropOffAddress: string | null
  createdTime: string
  paymentTime: string | null
  isScheduled: boolean
  isCancelledByDriver: boolean
}

export const RideAddresses = (props:Props) => {
  const {
    arrivedToPickUp,
    arrivedToDropOff,
    pickUpAddress,
    dropOffAddress, acceptedTime,
    scheduledTime,
    cancelledTime,
    paymentTime,
    createdTime,
    isScheduled,
    isCancelledByDriver } = props
  const { t } = useTranslation()
  return (
    <div className="grid gap-5 lg:pr-10 lg:border-r lg:border-r-[#a9a9a9] last:border-0 lg:max-w-[350px] w-full">
      <div className="flex gap-5 items-center justify-between flex-wrap">
        <div className="flex flex-col gap-1 font-bold">
          <p>
            <span className="text-gray text-sm font-normal mr-1">crtd:</span>
            {`${transformDateTime(createdTime)}`}
          </p>
          <RenderIf isTrue={!!scheduledTime}>
            <p>
              <span className="text-gray text-sm font-normal mr-1">sch:</span>
              {`${transformDateTime(scheduledTime)}`}
            </p>
          </RenderIf>
          <RenderIf isTrue={!!acceptedTime}>
            <p>
              <span className="text-gray text-sm font-normal mr-1">acc:</span>
              {`${transformDateTime(acceptedTime)}`}
            </p>
          </RenderIf>
          <RenderIf isTrue={!!paymentTime}>
            <p>
              <span className="text-gray text-sm font-normal mr-1">paid:</span>
              {`${transformDateTime(paymentTime)}`}
            </p>
          </RenderIf>
          <RenderIf isTrue={!!cancelledTime}>
            <p className="text-dark-red">
              <span className="text-gray text-sm font-normal mr-1">canc:</span>
              {transformDateTime(cancelledTime)}
              {' '}
              <span>{`(${calculateRefund(paymentTime, cancelledTime, isScheduled, isCancelledByDriver)})`}</span>
            </p>
          </RenderIf>
        </div>
      </div>
      <div className="flex gap-8 items-center">
        <div>
          <div className="w-[9px] h-[9px] border bg-white rounded-full" />
          <div className="h-[64px] border-l border-dashed translate-x-1" />
          <div className="w-[9px] h-[9px] border bg-black rounded-full" />
        </div>
        <div className="text-sm w-full">
          <div className="grid">
            <p className="text-dark-gray">{t('pickUpLoc')}</p>
            <div className="flex items-center justify-between gap-3">
              <p
                className="text-base font-semibold"
              >
                {pickUpAddress ?? '-'}
              </p>
              <RenderIf isTrue={moment(arrivedToPickUp).isValid()}>
                <p className="text-gray">{moment(arrivedToPickUp).format('HH:mm')}</p>
              </RenderIf>
            </div>
          </div>
          <div className="grid border-t border-t-light-gray mt-[6px] pt-[6px]">
            <p className="text-dark-gray">{t('dropOff')}</p>
            <div className="flex items-center justify-between gap-3">
              <p className="text-base font-semibold">{dropOffAddress ?? '-'}</p>
              <RenderIf isTrue={moment(arrivedToDropOff).isValid()}>
                <p className="text-gray">{moment(arrivedToDropOff).format('HH:mm')}</p>
              </RenderIf>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
