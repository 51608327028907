import { ReactTable } from 'components/table'
import { RowValue, OriginalRow } from 'interfaces'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MessagesModal, useModalContext } from 'features/common'
import { Client } from '../interfaces/client'
import { clientsService } from '../index'

interface Props {
  clients: Client[]
}
export const ClientsTable = (props:Props) => {
  const { clients = [] } = props
  const { t } = useTranslation()
  const { onOpen } = useModalContext()
  const columns = useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id'
      },
      {
        Header: t('firstname'),
        accessor: 'user.first_name',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('lastname'),
        accessor: 'user.last_name',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('email'),
        accessor: 'user.email'
      },
      {
        Header: t('telephone'),
        accessor: 'user.phone_number',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('communication'),
        accessor: 'message_count',
        Cell: ({ row: { original } }: OriginalRow<Client>) => (
          <button
            disabled={original.message_count === 0}
            onClick={(event) => {
              event.stopPropagation()
              onOpen({ resourceId: original.id, message: '' })
            }}
            type="button"
            className="disabled:cursor-auto hover:opacity-70 transition-opacity duration-300 cursor-pointer"
          >
            {original.message_count}
            {' '}
            {t('messages')}
          </button>
        )
      }
    ],
    [t, onOpen]
  )

  return (
    <>
      <ReactTable
        columns={columns}
        data={clients}
        exportAction={clientsService.clientsTableExport}
      />
      <MessagesModal />
    </>
  )
}
