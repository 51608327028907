import { Token, TokenUtils } from '../interfaces/token'

export const tokenUtils: TokenUtils = {
  accessToken(): Token['access'] | null {
    return localStorage.getItem('access')
  },
  refreshToken(): Token['refresh'] | null {
    return localStorage.getItem('refresh')
  },
  storeTokens(token: Token): void {
    this.storeAccessToken(token)
    this.storeRefreshToken(token)
  },
  storeAccessToken(token: Token): void {
    localStorage.setItem('access', token.access)
  },
  storeRefreshToken(token: Token): void {
    localStorage.setItem('refresh', token.access)
  },
  deleteTokens(): void {
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
  }
}
