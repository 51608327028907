import { QueryClient } from '@tanstack/react-query'
import { createBrowserRouter } from 'react-router-dom'
import { DashboardPage, LoginPage } from 'pages'
import { AuthPageLayout, loginAction, loginLoader } from 'features/authentication'
import { ApplicationError, Layout } from 'components/ui'
import { Translation } from 'interfaces'
import { adminsLoader, adminAction, deleteAdminAction } from 'features/user-management'
import { authenticatedRouteCheck } from 'utils'
import { clientLoader, clientRideHistoryLoader, clientsLoader, clientTransactionHistoryLoader } from 'features/clients'
import {
  deleteDriverAction,
  driversLoader,
  driverLoader,
  driverAction,
  driverRatingHistoryLoader,
  driverRideHistoryLoader,
  driverTransactionsHistoryLoader,
  DriversLoader, uncheckedDriversLoader, deniedDriversLoader, DeniedDriversLoader
} from 'features/drivers'
import { dashboardLoader } from 'features/dashboard'
import { feesLoader } from 'features/fees'
import ClientsPage from 'pages/ClientsPage'
import { transactionLoader, transactionsLoader } from 'features/transactions'
import {
  CancellationMessagesLoader,
  cancellationMessagesLoader,
  messageAction,
  messagesLoader,
  MessagingLoader
} from 'features/messages'
import { ActiveRidesLoader, rideLoader, RidesLoader, ridesLoader, ScheduledRidesLoader } from 'features/rides'
import { routePaths } from './route-paths'
import { cancellationMessageAction } from '../features/messages/helpers/actions'
import { promotionAction, deletePromotionAction, promotionsLoader, promotionLoader } from '../features/promotions'
import {
  employersLoader,
  deleteEmployerAction,
  employerAction,
  employerLoader,
  addDriverToEmployerAction
} from '../features/employers'
import { adminRoleRouteCheck } from '../utils/authenticated-route-check'
import { isAdmin } from '../features/authentication/utils/user-utils'
import { forgotPasswordAction, resetPasswordAction } from '../features/authentication/helpers/actions'
import { activeRidesLoader, scheduledRidesLoader } from '../features/rides/helpers/loaders'

interface Props {
  queryClient: QueryClient
  t: Translation
}

export const routes = ({ queryClient, t }:Props) => createBrowserRouter([
  {
    errorElement: <ApplicationError />,
    children: [
      {
        element: <AuthPageLayout />,
        children: [
          {
            path: routePaths.login,
            element: <LoginPage />,
            action: loginAction(t),
            loader: loginLoader
          },
          {
            path: routePaths.forgotPassword,
            lazy: () => import('../pages/ForgotPasswordPage'),
            action: forgotPasswordAction(t),
            loader: loginLoader
          },
          {
            path: routePaths.resetPassword,
            lazy: () => import('../pages/ResetPasswordPage'),
            action: resetPasswordAction(t),
            loader: loginLoader
          }
        ]
      },
      {
        element: <Layout />,
        children: [
          {
            path: routePaths.home,
            loader: dashboardLoader(queryClient),
            element: <DashboardPage />
          },
          {
            path: routePaths.drivers,
            lazy: () => import('../pages/DriversPage'),
            children: [
              {
                lazy: () => import('../features/drivers/pages/DisplayDriversPage'),
                loader: uncheckedDriversLoader(queryClient),
                children: [
                  {
                    index: true,
                    loader: driversLoader(queryClient),
                    action: deleteDriverAction(queryClient, t, '.'),
                    element: <DriversLoader />
                  },
                  {
                    path: routePaths.deniedDrivers,
                    loader: deniedDriversLoader(queryClient),
                    action: deleteDriverAction(queryClient, t, '.'),
                    element: <DeniedDriversLoader />
                  }

                ]
              },

              {
                path: ':id',
                loader: driverLoader(queryClient),
                action: deleteDriverAction(queryClient, t, '..'),
                lazy: () => import('../features/drivers/pages/DriverPage'),
                children: [
                  {
                    index: isAdmin(),
                    lazy: () => import('../features/drivers/pages/DriverApplicationCompanyDetailsPage')
                  },
                  {
                    path: routePaths.driverLicenseDetails,
                    lazy: () => import('../features/drivers/pages/DriverApplicationFilesPage')
                  },
                  {
                    path: routePaths.driverRatingHistory,
                    loader: driverRatingHistoryLoader(queryClient),
                    lazy: () => import('../features/drivers/pages/DriverRatingHistoryPage')
                  },
                  {
                    path: isAdmin() ? routePaths.rideHistory : undefined,
                    index: !isAdmin(),
                    loader: driverRideHistoryLoader(queryClient),
                    lazy: () => import('../features/drivers/pages/DriverRideHistoryPage')
                  },
                  {
                    path: routePaths.transactionsHistory,
                    loader: driverTransactionsHistoryLoader(queryClient),
                    lazy: () => import('../features/drivers/pages/DriverTransactionsHistoryPage')
                  }
                ]
              },
              {
                path: routePaths.newDriver,
                action: driverAction(queryClient, t),
                loader: adminRoleRouteCheck,
                lazy: () => import('../features/drivers/pages/AddNewDriverPage')
              },
              {
                path: `${routePaths.editDriver}/:id`,
                loader: isAdmin() ? driverLoader(queryClient) : adminRoleRouteCheck,
                action: driverAction(queryClient, t),
                lazy: () => import('../features/drivers/pages/EditDriver')
              },
              {
                path: `${routePaths.reviewDriver}/:id`,
                loader: isAdmin() ? driverLoader(queryClient) : adminRoleRouteCheck,
                lazy: () => import('../features/drivers/pages/ReviewDriverPage')
              }
            ]
          },
          {
            path: routePaths.employers,
            lazy: () => import('../pages/EmployersPage'),
            children: [
              {
                index: true,
                loader: employersLoader(queryClient),
                action: deleteEmployerAction(queryClient, t, '.'),
                lazy: () => import('../features/employers/pages/DisplayEmployersPage')
              },
              {
                path: routePaths.newEmployer,
                action: employerAction(queryClient, t),
                loader: adminRoleRouteCheck,
                lazy: () => import('../features/employers/pages/AddNewEmployerPage')
              },
              {
                path: `${routePaths.editEmployer}/:id`,
                loader: employerLoader(queryClient),
                action: employerAction(queryClient, t),
                lazy: () => import('../features/employers/pages/EditEmployerPage')
              },
              {
                path: ':id',
                loader: employerLoader(queryClient),
                lazy: () => import('../features/employers/pages/EmployerPage'),
                action: deleteEmployerAction(queryClient, t, '..'),
                children: [
                  {
                    index: true,
                    lazy: () => import('../features/employers/pages/EmployerCompanyAndLicensePage')
                  },
                  {
                    path: routePaths.employerDrivers,
                    action: addDriverToEmployerAction(queryClient, t),
                    lazy: () => import('../features/employers/pages/EmployerDriversPage')
                  }
                ]
              }
            ]
          },
          {
            path: routePaths.clients,
            element: <ClientsPage />,
            children: [
              {
                index: true,
                loader: clientsLoader(queryClient),
                lazy: () => import('../features/clients/pages/DisplayClientsPage')
              },
              {
                path: ':id',
                loader: clientLoader(queryClient),
                lazy: () => import('../features/clients/pages/ClientPage'),
                children: [
                  {
                    index: true,
                    loader: clientRideHistoryLoader(queryClient),
                    lazy: () => import('../features/clients/pages/ClientRideHistoryPage')
                  },
                  {
                    path: routePaths.transactionsHistory,
                    loader: clientTransactionHistoryLoader(queryClient),
                    lazy: () => import('../features/clients/pages/ClientTransactionsHistoryPage')
                  }
                ]
              }
            ]
          },
          {
            path: routePaths.rides,
            lazy: () => import('../pages/RidesPage'),
            id: 'rides',
            children: [
              {
                lazy: () => import('../features/rides/pages/DisplayRidesPage'),
                children: [
                  {
                    index: true,
                    element: <RidesLoader />,
                    loader: ridesLoader(queryClient)
                  },
                  {
                    path: routePaths.active,
                    element: <ActiveRidesLoader />,
                    loader: activeRidesLoader(queryClient)
                  },
                  {
                    path: routePaths.scheduled,
                    element: <ScheduledRidesLoader />,
                    loader: scheduledRidesLoader(queryClient)
                  }

                ]
              },
              {
                path: ':id',
                loader: rideLoader(queryClient),
                lazy: () => import('../features/rides/pages/RidePage')
              }
            ]
          },
          {
            path: routePaths.transactions,
            lazy: () => import('../pages/TransactionsPage'),
            children: [
              {
                index: true,
                loader: transactionsLoader(queryClient),
                lazy: () => import('../features/transactions/pages/DisplayTransactionsPage')
              },
              {
                path: ':id',
                loader: transactionLoader(queryClient),
                lazy: () => import('../features/transactions/pages/TransactionPage')
              }
            ]
          },
          {
            path: routePaths.fees,
            lazy: () => import('../pages/FeesPage'),
            loader: feesLoader(queryClient)
          },
          {
            path: routePaths.messaging,
            lazy: () => import('../pages/MessagingPage'),
            action: messageAction(queryClient, t),
            children: [
              {
                index: true,
                loader: messagesLoader(queryClient),
                action: messageAction(queryClient, t),
                element: <MessagingLoader userType="Client" />
              },
              {
                path: routePaths.driverToClient,
                loader: messagesLoader(queryClient),
                action: messageAction(queryClient, t),
                element: <MessagingLoader userType="Driver" />
              },
              {
                path: routePaths.driverCancellationReplies,
                loader: cancellationMessagesLoader(queryClient),
                action: cancellationMessageAction(queryClient, t),
                element: <CancellationMessagesLoader />
              }
            ]
          },
          {
            path: routePaths.promotions,
            lazy: () => import('../pages/PromotionsPage'),
            children: [
              {
                index: true,
                lazy: () => import('../features/promotions/pages/DisplayPromotionsPage'),
                action: deletePromotionAction(queryClient, t),
                loader: promotionsLoader(queryClient)
              },
              {
                path: routePaths.newPromo,
                loader: adminRoleRouteCheck,
                action: promotionAction(queryClient, t),
                lazy: () => import('../features/promotions/pages/CreatePromotionPage')
              },
              {
                path: `${routePaths.editPromo}/:id`,
                loader: promotionLoader(queryClient),
                action: promotionAction(queryClient, t),
                lazy: () => import('../features/promotions/pages/EditPromotionPage')
              }
            ]
          },
          {
            path: routePaths.manageUsers,
            loader: adminsLoader(queryClient),
            action: deleteAdminAction(queryClient, t),
            lazy: () => import('../pages/ManageUsersPage'),
            children: [
              {
                path: routePaths.newAdmin,
                loader: authenticatedRouteCheck || adminRoleRouteCheck,
                action: adminAction(queryClient, t),
                lazy: () => import('../features/user-management/pages/NewAdminPage')
              },
              {
                path: `${routePaths.editAdmin}/:id`,
                loader: authenticatedRouteCheck || adminRoleRouteCheck,
                action: adminAction(queryClient, t),
                lazy: () => import('../features/user-management/pages/EditAdminPage')
              }
            ]
          }
        ]
      }
    ]
  }
])
