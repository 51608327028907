import { useGlobalFilter, usePagination, useTable } from 'react-table'
import { ReactNode } from 'react'
import { AxiosResponse } from 'axios'
import { TableHead } from './TableHead'
import { TableBody } from './TableBody'
import { TablePagination } from './TablePagination'
import { TableSearch } from './TableSearch'
import { RenderIf } from '../ui'
import { TableMessage } from './TableMessage'

interface Props {
  columns: any
  data: any
  perPage?: number
  path?: string
  children?: (props: { setGlobalFilter: (value: string) => void }) =>ReactNode
  exportAction?: () => Promise<AxiosResponse>
}
export const ReactTable = (props:Props) => {
  const { columns, data, perPage = 7, path, children, exportAction } = props
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    rows,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: perPage
      }
    },
    useGlobalFilter,
    usePagination
  )
  const { pageIndex, pageSize } = state

  return (
    <>
      {!children
        ? (
          <TableSearch
            isNestedSearch={false}
            setFilter={setGlobalFilter}
            exportAction={exportAction}
          />
        )
        : null}

      {children ? children({
        setGlobalFilter
      }) : null}

      <div className="shadow-table rounded-[10px] overflow-x-hidden pb-[25px] bg-white">
        <div className="overflow-x-auto ">
          <table className="w-full min-w-[1100px] md:min-w-[900px] bg-table-gray  shadow-table border-collapse" {...getTableProps()}>
            <TableHead headerGroups={headerGroups} />
            <TableBody
              getTableBodyProps={getTableBodyProps}
              page={page}
              prepareRow={prepareRow}
              path={path}
            />
          </table>
        </div>
        <RenderIf isTrue={rows.length === 0}>
          <TableMessage />
        </RenderIf>
      </div>
      <RenderIf isTrue={rows.length > 0}>
        <TablePagination
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageLength={page.length}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
        />
      </RenderIf>

    </>
  )
}
