import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { AxiosError } from 'axios'
import { errorToastMessage, successToastMessage } from 'utils'
import { Translation } from 'interfaces'
import { AdminFormData } from '../interfaces/admin-form'
import adminsService from '../services/admins-service'

export const adminAction = (queryClient: QueryClient, t: Translation) => async (
  { request }:ActionFunctionArgs
) => {
  const { method } = request
  const formData = await request.formData()
  const values = Object.fromEntries(formData) as AdminFormData

  const toastMessage = method === 'POST' ? 'hasBeenCreated' : 'hasBeenUpdated'

  try {
    if (method === 'POST') {
      await adminsService.createAdmin(values)
    }
    if (method === 'PATCH') {
      delete values.password
      await adminsService.updateAdmin(values)
    }
    await queryClient.invalidateQueries(['admins'])
    successToastMessage(`${values.firstName} ${values.lastName} ${t(toastMessage)}`)
    return redirect('..')
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}

export const deleteAdminAction = (queryClient: QueryClient, t: Translation) => async (
  { request }:ActionFunctionArgs
) => {
  const formData = await request.formData()
  const id = formData.get('id') as string

  try {
    await adminsService.deleteAdmin(id)
    await queryClient.invalidateQueries(['admins'])
    successToastMessage(t('adminHasBeenDeleted'))
    return { response: 'ok' }
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}
