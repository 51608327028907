import { QueryClient } from '@tanstack/react-query'
import { defer, LoaderFunctionArgs } from 'react-router-dom'
import { isAlreadyAuthenticated } from '../../authentication'
import { authRedirect } from '../../../utils'
import { clientQuery, clientRideHistoryQuery, clientsQuery, clientTransactionsHistoryQuery } from './queries'
import { isAdmin } from '../../authentication/utils/user-utils'

export const clientsLoader = (queryClient:QueryClient) => () => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    clients: queryClient.ensureQueryData(clientsQuery())
  })
}

export const clientLoader = (queryClient:QueryClient) => ({ params } :LoaderFunctionArgs) => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    client: queryClient.ensureQueryData(clientQuery(params.id!))
  })
}

export const clientRideHistoryLoader = (queryClient:QueryClient) => ({
  params
} :LoaderFunctionArgs) => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    rideHistory: queryClient.ensureQueryData(clientRideHistoryQuery(params.id!))
  })
}

export const clientTransactionHistoryLoader = (queryClient:QueryClient) => ({
  params
} :LoaderFunctionArgs) => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    transactionsHistory: queryClient.ensureQueryData(clientTransactionsHistoryQuery(params.id!))
  })
}
