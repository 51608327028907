import { AiFillCar, AiOutlineCar } from 'react-icons/ai'
import { MdOutlineDashboard, MdDashboard, MdOutlineLocalOffer, MdLocalOffer } from 'react-icons/md'
import { BsPeopleFill, BsPeople, BsCreditCard2Front, BsCreditCard2FrontFill } from 'react-icons/bs'
import { RiSendPlaneLine, RiSendPlaneFill } from 'react-icons/ri'
import { FaRegListAlt, FaListAlt } from 'react-icons/fa'
import { TbMessageCircle2, TbMessageCircle2Filled } from 'react-icons/tb'
import { routePaths } from '../../../routes/route-paths'
import { IconEmployerOutlined, IconEmployerSolid } from '../../employers'

interface Paths {
  id: number
  label: string
  path: string
  icon: JSX.Element
  activeIcon: JSX.Element
  isAdminOnly?: boolean
}

const ICON_SIZE: number = 23
export const paths: Paths[] = [
  {
    id: 1,
    label: 'dashboard',
    path: routePaths.home,
    icon: <MdOutlineDashboard size={ICON_SIZE} />,
    activeIcon: <MdDashboard size={ICON_SIZE} />
  },
  {
    id: 2,
    label: 'drivers',
    path: routePaths.drivers,
    icon: <AiOutlineCar size={ICON_SIZE} />,
    activeIcon: <AiFillCar size={ICON_SIZE} />
  },
  {
    id: 3,
    label: 'employers',
    path: routePaths.employers,
    icon: <IconEmployerOutlined />,
    activeIcon: <IconEmployerSolid />,
    isAdminOnly: true
  },
  {
    id: 4,
    label: 'clients',
    path: routePaths.clients,
    icon: <BsPeople size={ICON_SIZE} />,
    activeIcon: <BsPeopleFill size={ICON_SIZE} />,
    isAdminOnly: true
  },
  {
    id: 5,
    label: 'rides',
    path: routePaths.rides,
    icon: <RiSendPlaneLine size={ICON_SIZE} />,
    activeIcon: <RiSendPlaneFill size={ICON_SIZE} />
  },
  {
    id: 6,
    label: 'transactions',
    path: routePaths.transactions,
    icon: <FaRegListAlt size={ICON_SIZE} />,
    activeIcon: <FaListAlt size={ICON_SIZE} />,
    isAdminOnly: true
  },
  {
    id: 7,
    label: 'fees',
    path: routePaths.fees,
    icon: <BsCreditCard2Front size={ICON_SIZE} />,
    activeIcon: <BsCreditCard2FrontFill size={ICON_SIZE} />,
    isAdminOnly: true
  },
  {
    id: 8,
    label: 'promotions',
    path: routePaths.promotions,
    icon: <MdOutlineLocalOffer size={ICON_SIZE} />,
    activeIcon: <MdLocalOffer size={ICON_SIZE} />,
    isAdminOnly: true
  },
  {
    id: 9,
    label: 'messaging',
    path: routePaths.messaging,
    icon: <TbMessageCircle2 size={ICON_SIZE} />,
    activeIcon: <TbMessageCircle2Filled size={ICON_SIZE} />,
    isAdminOnly: true
  }
]
