import { Card } from 'components/ui'
import { RatingHistory } from '../../../interfaces/rating-history'
import { Driver } from '../../../interfaces/driver'
import { DriverOverallRating } from './DriverOverallRating'
import { DriverRatingsList } from './DriverRatingsList'

interface Props {
  ratingHistory: RatingHistory[]
  driver: Driver
}
export const DriverRatingHistory = (props:Props) => {
  const { ratingHistory = [], driver } = props
  return (
    <Card className="py-7 ">
      <DriverOverallRating
        overallRating={driver.driver_rating.overall_rating}
        reviewsCount={driver.ratings_count}
      />

      {ratingHistory?.length > 0 ? <DriverRatingsList ratingHistory={ratingHistory} /> : null}
    </Card>
  )
}
