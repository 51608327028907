import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import { ElementType } from 'react'
import { LoadingSpinner } from './LoadingSpinner'

const buttonSizes = {
  sm: 'text-gray h-12 min-w-[136px] px-4',
  md: 'font-medium h-14 px-6',
  normal: 'font-medium h-[60px] px-6',
  lg: 'font-medium h-16 px-6'
}

const buttonLayouts = {
  filled: 'bg-primary-black text-white hover:opacity-80',
  outlined: 'bg-transparent border border-primary-black text-primary-black hover:bg-primary-black hover:text-white',
  destructive: 'bg-transparent border border-red text-red hover:bg-red hover:text-white',
  filledGray: 'bg-dark-gray text-white hover:opacity-90'
}

interface Props {
  text: string
  type?: 'button' | 'submit'
  onClickHandler? : () => void
  isLoading?: boolean
  hasIcon?: boolean
  size?: keyof typeof buttonSizes
  layout?: keyof typeof buttonLayouts
  as?: ElementType
  href?: string
}
export const PrimaryButton = (props:Props) => {
  const { text, type, as, href, isLoading = false, size = 'sm', layout = 'filled', hasIcon = false, onClickHandler } = props
  const { t } = useTranslation()

  const Component = as || 'button'
  const buttonAttrs = {
    type,
    disabled: isLoading,
    onClick: type === 'button' ? onClickHandler : undefined
  }

  const linkAttrs = {
    href,
    target: '_blank',
    rel: 'noreferrer'
  }

  const attributes = as === 'a' ? linkAttrs : buttonAttrs

  return (
    <Component
      {...attributes}
      className={`${buttonSizes[size]} ${buttonLayouts[layout]} ${hasIcon ? 'min-w-max' : ''} flex items-center justify-center text-xl rounded-[10px] transition-all duration-300`}
    >
      {hasIcon ? <MdAdd color="#fff" size={29} className="-translate-x-2" /> : null}
      {isLoading ? <LoadingSpinner /> : t(text) }
    </Component>
  )
}
