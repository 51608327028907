import { QueryClient } from '@tanstack/react-query'
import { defer, LoaderFunctionArgs } from 'react-router-dom'
import { authRedirect } from 'utils'
import { isAlreadyAuthenticated } from 'features/authentication'
import { transactionQuery, transactionsQuery } from './queries'
import { isAdmin } from '../../authentication/utils/user-utils'

export const transactionsLoader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    transactions: queryClient.ensureQueryData(transactionsQuery())
  })
}

export const transactionLoader = (queryClient: QueryClient) => ({ params }: LoaderFunctionArgs) => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    transaction: queryClient.ensureQueryData(transactionQuery(params.id!))
  })
}
