import { NavMenu } from '../interfaces/nav-menu'

export const HamburgerMenu = (props:NavMenu) => {
  const { isOpen, setIsOpen } = props
  const hamburgerLine = 'h-1 w-6 my-1 rounded-full bg-white transition ease transform duration-300'
  return (
    <button
      type="button"
      className="flex 3xl:hidden flex-col h-max w-max justify-center items-center group ml-auto my-auto md:hidden"
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      <div className={`${hamburgerLine} ${isOpen && 'rotate-45 translate-y-3'}`} />
      <div className={`${hamburgerLine} ${isOpen && 'opacity-0'}`} />
      <div className={`${hamburgerLine} ${isOpen && '-rotate-45 -translate-y-3'}`} />
    </button>
  )
}
