import { ridesService } from '../index'

export const ridesQuery = () => ({
  queryKey: ['rides'],
  queryFn: () => ridesService.getRides()
})

export const activeRidesQuery = () => ({
  queryKey: ['activeRides'],
  queryFn: () => ridesService.getActiveRides()
})

export const scheduledRidesQuery = () => ({
  queryKey: ['scheduledRides'],
  queryFn: () => ridesService.getScheduledRides()
})

export const rideQuery = (id: string) => ({
  queryKey: ['ride', id],
  queryFn: () => ridesService.getRide(id)
})
