import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsChevronLeft } from 'react-icons/bs'

export const BackButton = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <button
      type="button"
      onClick={() => navigate(-1)}
      className="flex gap-1 items-center text-gray text-lg"
    >
      <BsChevronLeft />
      {t('back')}
    </button>
  )
}
