import { SectionHeader } from 'components/ui'
import { useNavigate } from 'react-router-dom'
import { routePaths } from 'routes'

export const UsersHeader = () => {
  const navigate = useNavigate()
  return (
    <SectionHeader title="users" buttonText="newUser" onClickHandler={() => navigate(routePaths.newAdmin)} />
  )
}
