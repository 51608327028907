import { useTranslation } from 'react-i18next'

const labelSizes = {
  sm: 'text-base text-gray',
  lg: 'text-xl text-primary-black, font-medium'
}
interface Props {
  label: string
  size?: keyof typeof labelSizes
}
export const FormLabel = (props:Props) => {
  const { label, size = 'sm' } = props
  const { t } = useTranslation()
  return <label htmlFor={label} className={labelSizes[size]}>{t(label)}</label>
}
