import { QueryClient } from '@tanstack/react-query'
import { defer } from 'react-router-dom'
import { isAlreadyAuthenticated } from 'features/authentication'
import { authRedirect } from 'utils'
import { adminsQuery } from './queries'
import { isAdmin } from '../../authentication/utils/user-utils'

export const loader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    admins: queryClient.ensureQueryData(adminsQuery())
  })
}
