import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useLocation, useNavigation, useSubmit } from 'react-router-dom'
import * as yup from 'yup'
import { Form, FormGroup } from 'components/form'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from 'components/ui'
import { routePaths } from 'routes'

interface Props {
  helperText?: string
  children: (
    handleUpdateMessage: (selectedMessage: string, id: number) => void,
  ) => ReactElement
}

const validationSchemaMessage = yup.object({
  message: yup.string().trim().required('messageRequired')
})
export const MessageForm = (props:Props) => {
  const { children, helperText } = props
  const { t } = useTranslation()
  const [method, setMethod] = useState<'post' | 'patch'>('post')
  const submit = useSubmit()
  const navigation = useNavigation()
  const { pathname } = useLocation()
  const formik = useFormik({
    initialValues: {
      id: '',
      message: '',
      userType: ''
    },
    validationSchema: validationSchemaMessage,
    onSubmit: (values) => {
      submit(values, { method })
      formik.setFieldValue('message', '')
      formik.setFieldValue('id', '')
    }
  })

  // Set the userType based on the current path
  const { setFieldValue, values: { message } } = formik
  useEffect(() => {
    if (pathname === routePaths.messaging) {
      setFieldValue('userType', 'Client')
    }
    if (pathname === `${routePaths.messaging}/${routePaths.driverToClient}`) {
      setFieldValue('userType', 'Driver')
    }
  }, [setFieldValue, pathname])

  // Set the method based on the message value
  useEffect(() => {
    if (method !== 'post' && message.trim() === '') {
      setMethod('post')
      setFieldValue('id', '')
    }
  }, [message, method, setFieldValue])

  // Reset the form when the path changes
  useEffect(() => {
    if (pathname) {
      setMethod('post')
      setFieldValue('message', '')
      setFieldValue('id', '')
    }
  }, [pathname, setFieldValue])

  // Pass this function to the children component to update the message
  const handleUpdateMessage = useCallback((selectedMessage: string, id: number) => {
    setMethod('patch')
    setFieldValue('message', selectedMessage)
    setFieldValue('id', id)
  }, [setFieldValue])

  const hasError = !!(formik.touched.message && formik.errors.message)
  return (
    <>
      <Form method={method} onSubmit={formik.handleSubmit} className="flex items-end gap-3">
        <div className="max-w-[425px] w-full">
          <FormGroup
            label="message"
            hasError={hasError}
            inputSizes="lg"
            placeholder={t('message')}
            {...formik.getFieldProps('message')}
            errorText={formik.errors.message}
          />
        </div>
        <div>
          <PrimaryButton
            isLoading={navigation.state === 'submitting'}
            text={method === 'post' ? 'add' : 'update'}
            type="submit"
            hasIcon
            size="normal"
            layout="filledGray"
          />
        </div>
      </Form>
      {helperText ? (
        <p className={`${hasError ? 'mt-6' : 'mt-2'} text-[#96989A] text-xs`}>
          {t<string>(helperText)}
        </p>
      ) : null}

      {children(handleUpdateMessage)}
    </>
  )
}
