import { Await, useLoaderData, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Suspense } from 'react'
import { Client } from '../../interfaces/client'
import { clientQuery } from '../../helpers/queries'
import { LocalApiError, SinglePageIdWrapper } from '../../../../components/ui'
import { DisplaySingleClient } from './DisplaySingleClient'
import { ClientsHeader } from '../ClientsHeader'

export const SingleClientLoader = () => {
  const { id } = useParams<{ id: string }>()
  const { client } = useLoaderData() as { client: Client }
  const { data } = useQuery({
    ...clientQuery(id!),
    initialData: client
  })
  return (
    <Suspense>
      <SinglePageIdWrapper id={data?.id}>
        <ClientsHeader label="client" showSearch={false} />
      </SinglePageIdWrapper>
      <Await resolve={client} errorElement={<LocalApiError message="failedToLoadClient" />}>
        <DisplaySingleClient client={data} />
      </Await>
    </Suspense>
  )
}
