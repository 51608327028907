import { QueryClient } from '@tanstack/react-query'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Translation } from '../../../interfaces'
import { errorToastMessage, successToastMessage } from '../../../utils'
import employersService from '../services/employers-service'
import { driversService } from '../../drivers'
import { routePaths } from '../../../routes'

export const employerAction = (
  queryClient: QueryClient,
  t: Translation
) => async ({ request, params }:ActionFunctionArgs) => {
  const formData = await request.formData()
  const { method } = request
  try {
    if (method === 'POST') {
      await employersService.createEmployer(formData)
      successToastMessage(t('employerHasBeenCreated'))
    }
    if (method === 'PATCH') {
      const { id } = params as { id: string }
      await employersService.updateEmployer(formData, id)
      successToastMessage('employerHasBeenUpdated')
      await queryClient.invalidateQueries(['employer', id])
    }
    await queryClient.invalidateQueries(['employers'])
    return redirect('..')
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}

export const addDriverToEmployerAction = (
  queryClient: QueryClient,
  t: Translation
) => async ({ request, params }:ActionFunctionArgs) => {
  const formData = await request.formData()
  const id = formData.get('id') as string

  try {
    const paramsId = params.id as string
    formData.append('employers', paramsId)
    await driversService.updateDriver(formData, id)
    await queryClient.invalidateQueries(['employer', paramsId])
    return { response: 'ok' }
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}

export const deleteEmployerAction = (
  queryClient: QueryClient,
  t: Translation,
  path: string
) => async (
  { request, params }:ActionFunctionArgs
) => {
  const formData = await request.formData()
  const id = formData.get('id') as string
  const hasUserType = formData.has('userType')
  try {
    if (hasUserType) {
      const { id: paramsId } = params as { id: string }
      await driversService.deleteDriver(id)
      await queryClient.invalidateQueries(['employer', paramsId])
      await queryClient.invalidateQueries(['drivers'])
      successToastMessage(t('driverHasBeenDeleted'))
      return { response: 'ok', path: `${routePaths.employers}/${paramsId}/employer-drivers` }
    }
    await employersService.deleteEmployer(id)
    await queryClient.invalidateQueries(['employers'])
    successToastMessage(t('employerHasBeenDeleted'))
    return { response: 'ok', path }
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}
