import { TabBar, TabLink } from 'features/navigation'
import { routePaths } from 'routes'

export const MessagingTabs = () => (
  <TabBar>
    <TabLink path={routePaths.messaging} label="clientToDriver" />
    <TabLink path={routePaths.driverToClient} label="driverToClient" />
    <TabLink path={routePaths.driverCancellationReplies} label="driverCancellationReplies" />
  </TabBar>
)
