import { Await, useLoaderData } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Suspense } from 'react'
import { AnalyticsCardsSkeletons } from 'components/skeletons'
import { LocalApiError } from 'components/ui'
import { UserAnalytics } from '../../interfaces/analytics'
import { analyticsQuery, employerAnalyticsQuery } from '../../helpers/queries'
import { DisplayDashboardAnalytics } from './DisplayDashboardAnalytics'
import { isAdmin } from '../../../authentication/utils/user-utils'

export const DashboardAnalyticsLoader = () => {
  const { analytics } = useLoaderData() as { analytics: UserAnalytics }
  const query = isAdmin() ? analyticsQuery : employerAnalyticsQuery
  const { data } = useQuery({ ...query(), initialData: analytics })

  return (
    <Suspense fallback={<AnalyticsCardsSkeletons />}>
      <Await resolve={analytics} errorElement={<LocalApiError message="failedToLoadStatistics" />}>
        <DisplayDashboardAnalytics
          analytics={data}
        />
      </Await>
    </Suspense>
  )
}
