import { FiCopy } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

interface Props {
  code: string;

}
export const CopyPromotionCode = (props:Props) => {
  const { code } = props
  const { t } = useTranslation()
  const onCopy = () => {
    navigator.clipboard.writeText(code)
    toast.success(t('copiedToClipboard'))
  }
  return (
    <FiCopy onClick={onCopy} color="#545454" cursor="pointer" />
  )
}
