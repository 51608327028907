import { CardTitle } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { RideStatusColor, RideStatusTextColor } from '../../utils/ride-status-color'
import { RideStatus } from '../../interfaces/ride'

interface Props {
  rideId: number
  status: RideStatus
}
export const RideStatusTitle = (props:Props) => {
  const { rideId, status } = props
  const { t } = useTranslation()
  return (
    <div className="flex items-center gap-5 sm:gap-10 flex-wrap">
      <CardTitle title={`${t('ride')} #${rideId}`} />
      <div className="flex items-center gap-3">
        <div className={`rounded-full h-[18px] w-[18px] ${RideStatusColor(status)}`} />
        <p className={RideStatusTextColor(status)}>
          {t(status)}
        </p>
      </div>
    </div>
  )
}
