import { BaseApi } from 'services'
import { AxiosResponse } from 'axios'
import { UpdateZonePrice, Zone } from '../interfaces/zones'
import { FeesServiceInterface } from './fees-service.interface'

class FeesService extends BaseApi implements FeesServiceInterface {
  readonly endpoint = '/routes/zone-to-zone-price/'

  public async getZones(): Promise<Zone[]> {
    return await this.get<Zone[]>(this.endpoint)
  }

  public updateZonePrice = async ({ id, price }: UpdateZonePrice)
  : Promise<AxiosResponse> => await this.patch(
    `${this.endpoint}${id}/`,
    {
      price: +price
    }
  )

  public zonesTableExport = async ():Promise<AxiosResponse> => this.post(`${this.endpoint}export/`, {}, {
    responseType: 'blob'
  })
}
export default new FeesService()
