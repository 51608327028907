import { Formik } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { DriverFormValues } from '../../../interfaces/driver-form'
import { UploadDocumentButton } from '../UploadDocumentButton'
import { FormGroup } from '../../../../../components/form'

interface Props extends Formik<DriverFormValues> {}
export const DrivingLicenseFields = (props:Props) => {
  const { t } = useTranslation()
  const { formik } = props
  return (
    <>
      <div className="driver-form-child-container mt-6">
        <FormGroup
          label="drivingLicenseNumber"
          placeholder={t<string>('drivingLicenseNumber')}
          hasError={!!(formik.touched.driving_license_number
                && formik.errors.driving_license_number)}
          errorText={formik.errors.driving_license_number}
          {...formik.getFieldProps('driving_license_number')}
        />
        <div className="grid sm:grid-cols-2 gap-5">
          <FormGroup
            label="category"
            placeholder={t<string>('category')}
            hasError={!!(formik.touched.license_category
                && formik.errors.license_category)}
            errorText={formik.errors.license_category}
            {...formik.getFieldProps('license_category')}
          />
          <FormGroup
            label="cityOfIssue"
            placeholder={t<string>('cityOfIssue')}
            hasError={!!(formik.touched.license_city_of_issue
                && formik.errors.license_city_of_issue)}
            errorText={formik.errors.license_city_of_issue}
            {...formik.getFieldProps('license_city_of_issue')}
          />
        </div>
        <div className="grid sm:grid-cols-2 gap-5">
          <FormGroup
            type="date"
            label="dateOfIssue"
            placeholder={t<string>('dateOfIssue')}
            hasError={!!(formik.touched.license_date_of_issue
                  && formik.errors.license_date_of_issue)}
            errorText={formik.errors.license_date_of_issue}
            {...formik.getFieldProps('license_date_of_issue')}
          />
          <FormGroup
            type="date"
            label="dateOfExpiry"
            placeholder={t<string>('drivingLicenseDateOfExpiry')}
            hasError={!!(formik.touched.license_date_of_expiry
                  && formik.errors.license_date_of_expiry)}
            errorText={formik.errors.license_date_of_expiry}
            {...formik.getFieldProps('license_date_of_expiry')}
          />
        </div>
      </div>
      <div className="mt-5">
        <UploadDocumentButton
          label="uploadDocument"
          formik={formik}
          formikKey="driving_license"
          acceptedFormats=".jpg, .jpeg, .png, .pdf"
          supportedFilesText="supportedFile"
        />
      </div>
    </>
  )
}
