import { useTranslation } from 'react-i18next'
import { FormGroup } from 'components/form'
import { Formik } from 'interfaces'
import { DriverFormValues } from '../../interfaces/driver-form'
import { UploadDocumentButton } from './UploadDocumentButton'

interface Props extends Formik<DriverFormValues> {}

export const DriverIbanFields = (props:Props) => {
  const { formik } = props
  const { t } = useTranslation()
  return (
    <div>
      <h2 className="text-xl mb-5">{t('paymentInfo')}</h2>
      <div className="driver-form-child-container">
        <FormGroup
          label="iban"
          placeholder={t<string>('iban')}
          hasError={!!(formik.touched.iban && formik.errors.iban)}
          errorText={formik.errors.iban}
          {...formik.getFieldProps('iban')}
        />
        <FormGroup
          label="beneficiaryName"
          placeholder={t<string>('beneficiaryName')}
          hasError={!!(formik.touched.iban_name && formik.errors.iban_name)}
          errorText={formik.errors.iban_name}
          {...formik.getFieldProps('iban_name')}
        />

        <FormGroup
          label="bic"
          placeholder={t<string>('bic')}
          hasError={!!(formik.touched.bic && formik.errors.bic)}
          errorText={formik.errors.bic}
          {...formik.getFieldProps('bic')}
        />

      </div>
      <div className="mt-5">
        <UploadDocumentButton
          label="uploadDocument"
          formik={formik}
          formikKey="iban_file"
          acceptedFormats=".jpg, .jpeg, .png, .pdf"
          supportedFilesText="supportedFile"
        />
      </div>
    </div>
  )
}
