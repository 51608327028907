import { QueryClient } from '@tanstack/react-query'
import { defer, LoaderFunctionArgs } from 'react-router-dom'
import { authRedirect } from 'utils'
import { isAlreadyAuthenticated } from 'features/authentication'
import {
  deniedDriversQuery,
  driverQuery,
  driverRatingHistoryQuery,
  driverRideHistoryQuery,
  driversQuery, driverTransactionsHistoryQuery,
  uncheckedDriversQuery
} from './queries'
import { isAdmin } from '../../authentication/utils/user-utils'

export const driversLoader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated()) return authRedirect()

  return defer({
    drivers: queryClient.ensureQueryData(driversQuery())
  })
}

export const deniedDriversLoader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated() || !isAdmin()) return authRedirect()

  return defer({
    deniedDrivers: queryClient.ensureQueryData(deniedDriversQuery())
  })
}

export const uncheckedDriversLoader = (queryClient: QueryClient) => () => {
  if (!isAlreadyAuthenticated()) return authRedirect()
  if (!isAdmin()) return null
  return defer({
    uncheckedDrivers: queryClient.ensureQueryData(uncheckedDriversQuery())
  })
}

export const driverLoader = (queryClient: QueryClient) => ({ params }: LoaderFunctionArgs) => {
  if (!isAlreadyAuthenticated()) return authRedirect()

  return defer({
    driver: queryClient.ensureQueryData(driverQuery(params.id!))
  })
}

export const driverRatingHistoryLoader = (queryClient: QueryClient) => (
  {
    params
  }: LoaderFunctionArgs
) => {
  if (!isAlreadyAuthenticated()) return authRedirect()

  return defer({
    ratingHistory: queryClient.ensureQueryData(driverRatingHistoryQuery(params.id!))
  })
}

export const driverRideHistoryLoader = (queryClient: QueryClient) => (
  {
    params
  }: LoaderFunctionArgs
) => {
  if (!isAlreadyAuthenticated()) return authRedirect()

  return defer({
    rideHistory: queryClient.ensureQueryData(driverRideHistoryQuery(params.id!))
  })
}

export const driverTransactionsHistoryLoader = (queryClient: QueryClient) => (
  {
    params
  }: LoaderFunctionArgs
) => {
  if (!isAlreadyAuthenticated()) return authRedirect()

  return defer({
    transactionsHistory: queryClient.ensureQueryData(driverTransactionsHistoryQuery(params.id!))
  })
}
