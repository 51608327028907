export const IconEmployerSolid = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.84" height="20.19" viewBox="0 0 19.84 20.19">
    <g id="employee-solid" transform="translate(-16.215 -17.429)">
      <g id="Layer_3" data-name="Layer 3" transform="translate(16.215 17.429)">
        <circle
          id="Ellipse_160"
          data-name="Ellipse 160"
          cx="4.267"
          cy="4.267"
          r="4.267"
          transform="translate(4.245)"
        />
        <path
          id="Path_2166"
          data-name="Path 2166"
          d="M111.93,149.24h4.623v.925H111.93Z"
          transform="translate(-100.679 -133.013)"
        />
        <path
          id="Path_2167"
          data-name="Path 2167"
          d="M28.5,103.865v1.981a.661.661,0,0,0,.661.66H40.392a.661.661,0,0,0,.661-.66v-6.6a.661.661,0,0,0-.661-.66H35.765V97.61a.661.661,0,0,0-1.322,0v.971H33.122V96.216a21.24,21.24,0,0,0-3.4-.277,17.192,17.192,0,0,0-7.27,1.579A2.166,2.166,0,0,0,21.213,99.5v4.366Zm11.235,1.321H29.817V99.9h4.626v.277a.661.661,0,0,0,1.322,0V99.9h3.965Z"
          transform="translate(-21.212 -86.317)"
        />
      </g>
    </g>
  </svg>
)
