interface Props {
  rows?: number
}

const TableItemSkeleton = () => (
  <div className="h-[24px] my-[20px] animate-pulse w-full max-w-[150px] rounded-[10px] bg-light-gray" />
)

const TableSkeletonRow = ({ hasBorder = true, paddingY = '' }: { hasBorder?: boolean, paddingY?: string }) => {
  const borderClasses = hasBorder ? 'border-b border-b-border-gray' : ''
  return (
    <div className={`flex gap-5 ${borderClasses} ${paddingY} mx-[25px] justify-between text-left text-gray font-normal`}>
      <TableItemSkeleton />
      <TableItemSkeleton />
      <TableItemSkeleton />
      <TableItemSkeleton />
      <TableItemSkeleton />
    </div>
  )
}

export const TableSkeleton = (props:Props) => {
  const { rows = 7 } = props
  const fillRows = Array.from(Array(rows).keys())

  return (
    <div className="shadow-table rounded-[10px] bg-white animate-pulse ">
      <div className="w-full  bg-table-gray  shadow-table rounded-[10px]">
        <TableSkeletonRow hasBorder={false} paddingY="py-[5px]" />
        <div className="bg-white rounded-[10px] pb-[25px]">
          {fillRows.map((row) => <TableSkeletonRow key={row} />)}
        </div>
      </div>
    </div>
  )
}
