import { Card, CardTitle, ContactInfo, UserProfileIcon } from 'components/ui'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MdEmail, MdLocalPhone } from 'react-icons/md'
import { Client } from '../../interfaces/client'

interface Props {
  client: Client
}
export const SingleClientPersonalDetails = (props:Props) => {
  const { client } = props
  const { t } = useTranslation()
  const fullName = `${client.user.first_name} ${client.user.last_name}`
  return (
    <Card className="p-6">
      <div className="flex capitalize items-center justify-between flex-wrap gap-2">
        <CardTitle title={fullName} />

        <p className="text-lg text-gray font-['Space_Grotesk']">{`${t('addedOn')} ${moment(client.user.date_joined).format('MMM, D')}`}</p>
      </div>
      <div className="flex items-center justify-between mt-5 gap-2 flex-wrap-reverse">
        <div className="flex gap-2 md:gap-5 lg:gap-20 flex-wrap">
          <ContactInfo icon={MdLocalPhone} label="telephone" contact={client.user.phone_number} type="tel" />
          <ContactInfo icon={MdEmail} label="email" contact={client.user.email} type="mailto" />
        </div>
        <UserProfileIcon src={client.image} />
      </div>
    </Card>
  )
}
