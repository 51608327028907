import { useNavigation, useSubmit, Link, useSearchParams } from 'react-router-dom'
import { Form, FormGroup } from 'components/form'
import { PrimaryButton, Text, Title } from 'components/ui'
import { routePaths } from 'routes'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { validationSchema } from '../utils/validation-schema'

export const ResetPasswordForm = () => {
  const submit = useSubmit()
  const navigation = useNavigation()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')

  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      password: '',
      code: ''
    },
    validationSchema: validationSchema.resetPasswordForm,
    onSubmit: async (values) => {
      submit(values, { method: 'post' })
    }
  })
  const onResendCode = () => {
    if (email) {
      submit({ email }, { method: 'post' })
    }
  }
  return (
    <div className="p-5 bg-light-gray rounded-t-[40px] sm:w-1/2 sm:rounded-r-[64px] sm:rounded-tl-[0px] flex flex-col items-center justify-center">
      <div className="flex flex-col gap-2 mb-5 sm:mb-14 lg:-translate-x-24">
        <Title title="resetPassword" size="lg" />
        <Text text="enterNewPassword" size="xl" color="gray" />
      </div>
      <Form
        method="post"
        onSubmit={formik.handleSubmit}
        className="max-w-[485px] w-full flex flex-col gap-6"
      >
        <FormGroup
          id="password"
          label="newPassword"
          type="password"
          placeholder={t('insertNewPassword')}
          hasError={!!(formik.touched.password && formik.errors.password)}
          errorText={formik.errors.password}
          labelSizes="lg"
          inputSizes="lg"
          {...formik.getFieldProps('password')}
        />
        <FormGroup
          id="verificationCode"
          label="verificationCode"
          placeholder={t('insertVerificationCode')}
          hasError={!!(formik.touched.code && formik.errors.code)}
          errorText={formik.errors.code}
          labelSizes="lg"
          inputSizes="lg"
          {...formik.getFieldProps('code')}
        />
        <PrimaryButton
          text="resetPassword"
          type="submit"
          isLoading={navigation.state === 'submitting'}
          size="lg"
        />
        <PrimaryButton
          text="resendCode"
          type="button"
          isLoading={navigation.state === 'submitting'}
          onClickHandler={onResendCode}
          size="lg"
        />
        <Link
          to={routePaths.login}
          className="mx-auto text-primary-black"
        >
          {t('alreadyHaveAccount')}
        </Link>
      </Form>
    </div>
  )
}
