import Select from 'react-select'
import { ComponentProps } from 'react'

type Props = {
  hasError?: boolean
  [key:string]: any
} & ComponentProps<typeof Select>
export const FormReactSelect = ({ hasError, ...restProps }:Props) => (
  <Select
    name="react-select"
    className="shadow-input outline-primary-black transition-all duration-200"
    styles={{
      option: (baseStyles, { isFocused }) => ({
        ...baseStyles,
        backgroundColor: isFocused ? '#ccc' : '#fff',
        '&:hover': {
          color: '#0E0A09'
        },
        color: '#0E0A09'
      }),
      control: (baseStyles) => ({
        ...baseStyles,
        border: hasError ? '1px solid #F21F1F' : '1px solid transparent',
        padding: '4px 0 4px 21px',
        borderRadius: '10px',
        boxShadow: 'none',
        '&:hover': {
          borderColor: '#0E0A09'
        }
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        color: '#96989A',
        fontSize: '14px',
        fontFamily: 'Space Grotesk'
      })
    }}
    components={{
      IndicatorSeparator: () => null
    }}
    {...restProps}
  />
)
