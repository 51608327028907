import { redirect } from 'react-router-dom'
import { routePaths } from 'routes'
import { isAlreadyAuthenticated } from 'features/authentication'
import { isAdmin } from '../features/authentication/utils/user-utils'

export const authRedirect = () => redirect(`${routePaths.login}`)
export const authenticatedRouteCheck = (): Response | null => {
  if (!isAlreadyAuthenticated()) {
    return authRedirect()
  }

  return null
}

export const adminRoleRouteCheck = (): Response | null => {
  if (!isAdmin()) {
    return authRedirect()
  }

  return null
}
