import { Driver } from '../../interfaces/driver'
import { DriverApplicationPersonalDetails } from './DriverApplicationPersonalDetails'
import { DriverApplicationCompanyDetails } from './DriverApplicationCompanyDetails'
import { DriverApplicationFiles } from './DriverApplicationFiles'
import { DriverApplicationActions } from './DriverApplicationActions'

interface Props {
  driver: Driver
}
export const DriverApplication = (props:Props) => {
  const { driver } = props

  return (
    <div className="grid gap-6">
      <DriverApplicationPersonalDetails driver={driver} />
      <DriverApplicationCompanyDetails driver={driver} />
      <DriverApplicationFiles driver={driver} />
      <DriverApplicationActions driverId={driver.id} />
    </div>
  )
}
