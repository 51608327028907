import { useNavigation, useSubmit } from 'react-router-dom'
import { useFormik } from 'formik'
import { Employer } from '../../interfaces/employer'
import { Form, FormActions } from '../../../../components/form'
import { EmployerFormValues } from '../../interfaces/employer-form'
import { EmployerDetailsFields } from './EmployerDetailsFields'
import { validationSchema } from '../../utils/validation-schema'
import { EmployerCompanyFields } from './EmployerCompanyFields'
import { EmployerIbanFields } from './EmployerIbanFields'

interface Props {
  method: 'post' | 'patch'
  employer?: Employer
}
export const EmployersForm = (props:Props) => {
  const { employer, method } = props
  const submit = useSubmit()
  const navigation = useNavigation()

  const formik = useFormik<EmployerFormValues>({
    initialValues: {
      // Personal Info
      first_name: employer?.user.first_name ?? '',
      last_name: employer?.user.last_name ?? '',
      email: employer?.user.email ?? '',
      phone_number: employer?.user.phone_number ?? '',
      residency: employer?.residency ?? '',
      address: employer?.address ?? '',
      password: employer ? '********' : '',
      photo: employer?.photo ?? '',

      // Identification Card
      identification_card_number: employer?.identification_card_number ?? '',
      identification_card: employer?.identification_card ?? '',

      // Company Info
      company_name: employer?.company_name ?? '',
      company_tax_id: employer?.company_tax_id ?? '',
      company_tax_department: employer?.company_tax_department ?? '',
      company_profession: employer?.company_profession ?? '',
      company_address: employer?.company_address ?? '',
      company_extra_info: employer?.company_extra_info ?? '',

      // Iban
      iban: employer?.iban ?? '',
      iban_name: employer?.iban_name ?? '',
      iban_file: employer?.iban_file ?? '',
      bic: employer?.bic ?? ''
    },
    validationSchema: validationSchema.employer,
    onSubmit: (values) => {
      submit(values, { method, encType: 'multipart/form-data' })
    }
  })

  return (
    <Form
      method={method}
      onSubmit={formik.handleSubmit}
      className="grid gap-12"
    >
      <EmployerDetailsFields formik={formik} method={method} />
      <EmployerCompanyFields formik={formik} />
      <EmployerIbanFields formik={formik} />
      <FormActions<EmployerFormValues>
        resetForm={formik.resetForm}
        isLoading={navigation.state === 'submitting'}
      />
    </Form>
  )
}
