import { RatingHistory } from '../../../interfaces/rating-history'
import { DriverRatingItem } from './DriverRatingItem'

interface Props {
  ratingHistory: RatingHistory[]
}
export const DriverRatingsList = (props:Props) => {
  const { ratingHistory = [] } = props
  return (
    <div className="pl-7 pr-5 grid gap-6 mt-6 max-h-[300px] overflow-y-auto">
      {ratingHistory.map((rating) => (
        <DriverRatingItem
          key={rating.route_id}
          ratingHistory={rating}
        />
      ))}
    </div>
  )
}
