import { Card, DetailsKeyValue } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { Employer } from '../../interfaces/employer'

interface Props {
  employer: Employer;
}
export const EmployerCompanyAndLicense = (props:Props) => {
  const { employer } = props
  const { t } = useTranslation()
  return (
    <Card className="grid md:grid-cols-3 gap-6 p-6 font-bold">
      <div className="md:border-r-2 md:border-r-light-gray">
        <h3 className="text-xl text-gray">{t('companyInfo')}</h3>
        <div className="grid gap-y-3 mt-4">
          <DetailsKeyValue label="companyName" value={employer.company_name} />
          <DetailsKeyValue label="type" value={employer.company_tax_department} />
          <DetailsKeyValue label="taxId" value={employer.company_tax_id} />
          <DetailsKeyValue label="address" value={employer.company_address} />
          <DetailsKeyValue label="profession" value={employer.company_profession} />
          <DetailsKeyValue label="extraInfo" value={employer.company_extra_info} />
        </div>
      </div>
      <div className="md:border-r-2 md:border-r-light-gray">
        <h3 className="text-xl text-gray">{t('identificationDetails')}</h3>
        <div className="grid gap-y-3 mt-4">
          <DetailsKeyValue label="IdCardNumber" value={employer.identification_card_number} />
          {employer.identification_card && (
          <a
            className="text-sm underline text-dark-gray font-normal"
            href={employer.identification_card ?? undefined}
            target="_blank"
            rel="noreferrer"
          >
            {t('idCardFile')}
          </a>
          )}
        </div>
      </div>
      <div>
        <h3 className="text-xl text-gray">{t('paymentInfo')}</h3>
        <div className="grid gap-y-3 mt-4">
          <DetailsKeyValue label="iban" value={employer.iban} />
          <DetailsKeyValue label="beneficiaryName" value={employer.iban_name} />
          <DetailsKeyValue label="BIC" value={employer.bic} />
          {employer.iban_file ? (
            <a
              className="text-sm underline text-dark-gray font-normal"
              href={employer.iban_file ?? undefined}
              target="_blank"
              rel="noreferrer"
            >
              {t('bankAccountFile')}
            </a>
          ) : <small className="text-dark-gray font-normal">{t('noBankAccountFileFound')}</small>}
        </div>
      </div>
    </Card>
  )
}
