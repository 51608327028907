import { TabBar, TabLink } from 'features/navigation'
import { routePaths } from 'routes'
import { RenderIf } from '../../../../components/ui'
import { isAdmin } from '../../../authentication/utils/user-utils'

export const SingleDriverTabs = () => (
  <TabBar className="mt-10">
    <RenderIf isTrue={isAdmin()}>
      <TabLink path="" label="companyAndPaymentInfo" />
      <TabLink path={routePaths.driverLicenseDetails} label="licenseDetails" />
    </RenderIf>
    <RenderIf isTrue={isAdmin()}>
      <TabLink path={routePaths.driverRatingHistory} label="rating" />
    </RenderIf>
    <TabLink path={isAdmin() ? routePaths.rideHistory : ''} label="rideHistory" />
    <RenderIf isTrue={isAdmin()}>
      <TabLink path={routePaths.transactionsHistory} label="transactionsHistory" />
    </RenderIf>
  </TabBar>
)
