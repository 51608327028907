import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { RowValue } from 'interfaces'
import { ReactTable } from 'components/table'
import moment from 'moment'
import { Transaction } from '../../interfaces/transaction'
import { transactionsService } from '../../index'

interface Props {
  transactions: Transaction[]
  path?: string
}
export const TransactionsTable = (props:Props) => {
  const { transactions = [], path } = props
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      {
        Header: t('receiptId'),
        accessor: 'receipt_id',
        Cell: ({ value }: RowValue<string>) => (
          <span className="pr-5">
            {value ?? '-'}
          </span>
        )
      },
      {
        Header: t('transactionId'),
        accessor: 'transaction_id',
        Cell: ({ value }: RowValue<string>) => (
          <span className="pr-5">
            {value ?? '-'}
          </span>
        )
      },
      {
        Header: t('orderCode'),
        accessor: 'order_code',
        Cell: ({ value }: RowValue<string>) => (
          <span className="pr-14">
            {value}
          </span>
        )
      },
      {
        Header: t('fullName'),
        accessor: 'full_name'
      },
      {
        Header: t('email'),
        accessor: 'transaction_email',
        Cell: ({ value }: RowValue<string>) => (
          <span className="pr-5">
            {value ?? '-'}
          </span>
        )
      },
      {
        Header: t('amount'),
        accessor: 'amount',
        Cell: ({ value }: RowValue<number>) => (
          <span className="pr-5">
            &euro;
            {(value / 100).toFixed(2)}
          </span>
        )
      },
      {
        Header: t('date'),
        accessor: 'created',
        Cell: ({ value }: RowValue<number>) => (
          <span className="pr-5">
            {moment(value).format('MMM. DD, HH:mm')}
          </span>
        )
      },
      {
        Header: t('status'),
        accessor: 'verified',
        Cell: ({ value }: RowValue<boolean | null>) => (
          <div className="flex items-center gap-3">
            <div className={`rounded-full h-[18px] w-[18px] ${value ? 'bg-online shadow-online' : 'bg-dark-red shadow-dark-red'}`} />
            <p>
              {t(value ? 'settled' : 'unsettled')}
            </p>
          </div>
        )
      }
    ],
    [t]

  )
  return (
    <ReactTable
      columns={columns}
      data={transactions}
      path={path}
      exportAction={transactionsService.transactionsTableExport}
    />
  )
}
