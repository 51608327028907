import { Suspense } from 'react'
import { Await, useLoaderData, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { LocalApiError } from 'components/ui'
import { SingleTransactionSkeleton } from 'components/skeletons'
import { Transaction } from '../../interfaces/transaction'
import { SingleTransaction } from './SingleTransaction'
import { transactionQuery } from '../../helpers/queries'

export const TransactionLoader = () => {
  const params = useParams()
  const { transaction } = useLoaderData() as { transaction: Transaction }
  const { data } = useQuery({ ...transactionQuery(params.id!), initialData: transaction })
  return (
    <Suspense fallback={<SingleTransactionSkeleton />}>
      <Await resolve={transaction} errorElement={<LocalApiError message="failedToLoadTransaction" />}>
        <SingleTransaction transaction={data} />
      </Await>
    </Suspense>
  )
}
