import { DriverStatus } from '../interfaces/driver'

export const driverStatusColor = (status: DriverStatus) => {
  switch (status) {
    case 'Online':
      return 'bg-online shadow-online'
    case 'On Route':
      return 'bg-onRoute shadow-onRoute'
    case 'Offline':
      return 'bg-offline shadow-offline'
    default:
      return ''
  }
}
