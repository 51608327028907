import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { useQuery } from '@tanstack/react-query'
import { LocalApiError } from 'components/ui'
import { MessagingSkeleton } from 'components/skeletons'
import { Message, MessageUserType } from '../../interfaces/message'
import { messagesQuery } from '../../helpers/queries'
import { MessagingList } from './MessagingList'

interface Props {
  userType: MessageUserType
}
export const MessagingLoader = (props:Props) => {
  const { userType } = props
  const { messages } = useLoaderData() as { messages: Message[] }
  const { data } = useQuery({
    ...messagesQuery(),
    initialData: messages
  })
  return (
    <Suspense fallback={<MessagingSkeleton />}>
      <Await
        resolve={messages}
        errorElement={<LocalApiError message="failedToLoadMessages" />}
      >
        <MessagingList messages={data} userType={userType} />
      </Await>
    </Suspense>
  )
}
