import React, { MouseEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ReactTable, TableActions } from '../../../components/table'
import { Promotion } from '../interfaces/promotion'
import { OriginalRow, RowValue } from '../../../interfaces'
import { CouponCode } from './ui/CouponCode'
import { PromotionStatus } from './ui/PromotionStatus'
import { ConfirmationModal, useModalContext } from '../../common'
import { routePaths } from '../../../routes'
import { CopyPromotionCode } from './CopyPromotionCode'
import promotionsService from '../services/promotions-service'

interface Props {
  promotions: Promotion[]
}
const PromotionsTable = (props:Props) => {
  const { promotions = [] } = props
  const { t } = useTranslation()
  const { onOpen } = useModalContext()
  const navigate = useNavigate()
  const columns = useMemo(() => [
    {
      Header: t('id'),
      accessor: 'id'
    },
    {
      Header: t('title'),
      accessor: 'title'
    },
    {
      Header: t('couponCode'),
      accessor: 'coupon_code',
      Cell: ({ value }:RowValue<string>) => (
        <div className="flex items-center gap-2">
          <CouponCode couponCode={value} />
          <CopyPromotionCode code={value} />
        </div>
      )
    },
    {
      Header: t('duration'),
      Cell: ({ row: { original } }:OriginalRow<Promotion>) => (
        <div className="flex items-center gap-1 text-[#96989A]">
          <p>{moment(original.promotion_start_date).format('DD MMM, YYYY')}</p>
          -
          <p>{original.promotion_end_date ? moment(original.promotion_end_date).format('DD MMM, YYYY') : t('noEndDate')}</p>
        </div>
      )
    },
    {
      Header: t('discount'),
      accessor: 'discount',
      Cell: ({ value }: RowValue<string>) => (
        <span>
          {value}
          %
        </span>
      )
    },
    {
      Header: t('uses'),
      accessor: 'redemption_limit',
      Cell: ({ value }: RowValue<number>) => (
        <span>
          {value === -1 ? t('unlimited') : value}
        </span>
      )
    },
    {
      Header: t('status'),
      Cell: (
        { row: { original }
        }:OriginalRow<Promotion>
      ) => (
        <PromotionStatus
          startDate={original.promotion_start_date}
          endDate={original.promotion_end_date}
        />
      )
    },
    {
      id: 'actions',
      Cell: (
        { row: { original }
        }:OriginalRow<Promotion>
      ) => (
        <TableActions
          onEditHandler={(event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            navigate(`${routePaths.editPromo}/${original.id}`)
          }}
          onDeleteHandler={(event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            onOpen({
              message: 'deletePromotionMessage',
              resourceId: original.id
            })
          }}
        />
      )
    }
  ], [t, onOpen, navigate])
  return (
    <>
      <ConfirmationModal />
      <ReactTable
        data={promotions}
        columns={columns}
        exportAction={promotionsService.promotionsTableExport}
      />
    </>

  )
}

export default PromotionsTable
