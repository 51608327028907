import { Outlet } from 'react-router-dom'
import { Employer } from '../../interfaces/employer'
import { EmployerPersonalDetails } from './EmployerPersonalDetails'
import { SingleEmployerActions } from './SingleEmployerActions'
import { EmployerTabs } from './EmployerTabs'
import { ConfirmationModal, ModalProvider } from '../../../common'

interface Props {
  employer: Employer
}

export const SingleEmployer = (props:Props) => {
  const { employer } = props

  return (
    <ModalProvider>
      <div className="grid">
        <EmployerPersonalDetails employer={employer} />
        <EmployerTabs driversCount={employer.driver_count} />
        <div className="overflow-x-auto mb-7">
          <Outlet context={employer} />
        </div>
        <SingleEmployerActions employerId={employer.id} employerUserId={employer.user.id} />
      </div>
      <ConfirmationModal />
    </ModalProvider>
  )
}
