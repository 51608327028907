import promotionsService from '../services/promotions-service'

export const promotionsQuery = () => ({
  queryKey: ['promotions'],
  queryFn: () => promotionsService.getPromotions()
})

export const promotionQuery = (id:string) => ({
  queryKey: ['promotion', id],
  queryFn: () => promotionsService.getPromotion(id)
})
