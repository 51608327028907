import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

interface Props {
  pageSize: number
  pageIndex: number
  pageLength: number
  previousPage: () => void
  canPreviousPage: boolean
  nextPage: () => void
  canNextPage: boolean
  pageOptions: number[]
}

export const TablePagination = (props: Props) => {
  const {
    pageSize,
    pageIndex,
    pageLength,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions
  } = props
  const { t } = useTranslation()
  const pageNumName = pageOptions.length > 1 ? t('pages') : t('page')
  const borderClasses = 'border cursor-pointer disabled:cursor-not-allowed disabled:bg-gray bg-primary-black border-solid border-[#BEBEBE] rounded-md w-[30px] h-[30px] flex items-center justify-center hover:opacity-70 transition-opacity duration-200'
  const borderDisableClasses = 'disabled:border-[#E2E2E2]'

  return (
    <div className="flex justify-center items-center gap-3 mt-10">
      <p>
        {`${pageSize * pageIndex + 1} - ${pageSize * pageIndex + pageLength} ${t('of')} ${pageOptions.length} ${pageNumName}`}
      </p>

      <div className="flex items-center gap-2">
        <button
          className={`${borderClasses} ${borderDisableClasses}`}
          type="button"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <FaChevronLeft
            className={`${
              !canPreviousPage ? 'text-light-gray' : 'text-white'
            }`}
          />
        </button>
        <button
          className={`${borderClasses} ${borderDisableClasses}`}
          type="button"
          onClick={nextPage}
          disabled={!canNextPage}
        >
          <FaChevronRight
            className={`${!canNextPage ? 'text-light-gray' : 'text-white'}`}
          />
        </button>
      </div>
    </div>
  )
}
