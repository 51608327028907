import { SectionHeader } from 'components/ui'

interface Props {
  label: string
  showSearch?: boolean
  btnText?: string
  onClickHandler?: () => void
}
export const ClientsHeader = (props:Props) => {
  const { label, showSearch = true, btnText, onClickHandler } = props

  return (
    <SectionHeader
      title={label}
      showSearch={showSearch}
      buttonText={btnText}
      onClickHandler={onClickHandler}
    />
  )
}
