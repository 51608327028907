import { RenderIf } from 'components/ui'
import { FaRegEdit } from 'react-icons/fa'
import { ImBin } from 'react-icons/im'
import { MouseEvent } from 'react'

interface Props {
  onEditHandler?: (event: MouseEvent<HTMLButtonElement>) => void
  onDeleteHandler?: (event: MouseEvent<HTMLButtonElement>) => void
}

export const TableActions = (props:Props) => {
  const { onEditHandler, onDeleteHandler } = props
  const hoverClasses = 'hover:opacity-80 transition-all duration-300'
  return (
    <div className="ml-auto flex gap-2 justify-end">
      <RenderIf isTrue={!!onEditHandler}>
        <button type="button" onClick={onEditHandler} className={hoverClasses}>
          <FaRegEdit size={20} className="text-dark-gray" />
        </button>
      </RenderIf>
      <RenderIf isTrue={!!onDeleteHandler}>
        <button type="button" onClick={onDeleteHandler} className={hoverClasses}>
          <ImBin size={18} className="text-dark-red" />
        </button>
      </RenderIf>
    </div>
  )
}
