import { ReactTable, TableActions } from 'components/table'
import { Admin, OriginalRow, RowValue } from 'interfaces'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal, useModalContext } from 'features/common'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import moment from 'moment/moment'
import { routePaths } from '../../../routes'
import { adminsService } from '../index'

interface Props {
  admins: Admin[]
}
export const DisplayUsersTable = (props:Props) => {
  const { admins = [] } = props
  const { onOpen } = useModalContext()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id'
      },
      {
        Header: t('firstname'),
        accessor: 'user.first_name',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('lastname'),
        accessor: 'user.last_name',
        Cell: ({ value }: RowValue<string>) => (
          <span>
            {value || '-'}
          </span>
        )
      },
      {
        Header: t('email'),
        accessor: 'user.email'
      },
      {
        Header: t('role'),
        accessor: 'user.user_type'
      },
      {
        Header: t('date'),
        accessor: 'user.date_joined',
        Cell: ({ value }: RowValue<number>) => (
          <span>
            {moment(value).format('MMM. DD, HH:mm')}
          </span>
        )
      },
      {
        Header: ' ',
        Cell: ({ row: { original } }:OriginalRow<Admin>) => (
          <TableActions
            onEditHandler={() => navigate(`${routePaths.editAdmin}/${original.id}`)}
            onDeleteHandler={() => onOpen({
              message: 'deleteAdminMessage',
              resourceId: original.user.id
            })}
          />
        )
      }
    ],
    [
      navigate,
      onOpen,
      t
    ]
  )

  return (
    <>
      <ConfirmationModal />
      <ReactTable
        columns={columns}
        data={admins}
        exportAction={adminsService.adminTableExport}
      />
    </>

  )
}
