import { BsChevronRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RenderIf } from '../ui'

interface Props {
  path: string
  label: string
  showIcon?: boolean
}
export const HeaderPath = (props:Props) => {
  const { path, label, showIcon = true } = props
  const { t } = useTranslation()
  const labelClasses = `text-base text-primary-black capitalize ${showIcon ? 'font-normal' : 'font-bold'}`
  return (
    <div className="flex gap-1 items-center">
      <RenderIf isTrue={showIcon}>
        <Link
          to={path}
          className={labelClasses}
        >
          {t(label)}
        </Link>
      </RenderIf>

      <RenderIf isTrue={!showIcon}>
        <p className={labelClasses}>
          {t(label)}
        </p>
      </RenderIf>

      <RenderIf isTrue={showIcon}>
        <BsChevronRight size={18} />
      </RenderIf>
    </div>
  )
}
