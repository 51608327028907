import { clientsService } from '../index'

export const clientsQuery = () => ({
  queryKey: ['clients'],
  queryFn: () => clientsService.getClients()
})

export const clientQuery = (id:string) => ({
  queryKey: ['client', id],
  queryFn: () => clientsService.getClient(id)
})

export const clientRideHistoryQuery = (id:string) => ({
  queryKey: ['clientRideHistory', id],
  queryFn: () => clientsService.getClientRideHistory(id)
})

export const clientTransactionsHistoryQuery = (id:string) => ({
  queryKey: ['clientTransactionHistory', id],
  queryFn: () => clientsService.getClientTransactionsHistory(id)
})
