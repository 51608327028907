import { RiErrorWarningFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { routePaths } from 'routes'
import { Card } from 'components/ui'

interface Props {
  id: number
  firstName: string
  lastName: string
  email: string
  phone: string
}
export const UncheckedDriversCard = (props:Props) => {
  const { id, firstName, lastName, email, phone } = props
  const { t } = useTranslation()

  return (

    <Link
      to={`${routePaths.reviewDriver}/${id}`}
    >
      <Card className="min-w-[900px] flex items-center w-full px-5 py-3 text-gray text-sm opacity-80 hover:bg-stone-50 transition-colors duration-300">
        <div className="flex items-center gap-5 w-max ">
          <RiErrorWarningFill color="#159C1E" size={20} />
          <h3 className="text-primary-black text-base whitespace-nowrap">{t('newSignUp')}</h3>
          <div className="bg-[#ECEFEF] w-[1px] h-[20px]" />
        </div>

        <div className="flex items-center justify-around w-full">
          <div className="capitalize flex items-center gap-1">
            <p>{firstName}</p>
            <p>{lastName}</p>
          </div>

          <p className="lowercase">{email}</p>
          <p>{phone}</p>
        </div>
        <div className="flex items-center gap-5 whitespace-nowrap">
          <div className="bg-[#ECEFEF] w-[1px] h-[20px]" />
          <p className="text-primary-black underline">{t('review')}</p>
        </div>
      </Card>
    </Link>

  )
}
