import { Card } from 'components/ui'
import { TableActions } from 'components/table'
import { useModalContext } from 'features/common'
import { CancellationMessage } from '../../interfaces/cancellation-message'

interface Props {
  message: CancellationMessage
  handleUpdateMessage: (selectedMessage: string, id: number) => void
}
export const CancellationMessageCard = (props:Props) => {
  const { message, handleUpdateMessage } = props
  const { onOpen } = useModalContext()
  return (
    <Card className="flex items-center justify-between px-7 py-5 text-gray font-bold">
      <p>{message.name}</p>
      <TableActions
        onEditHandler={() => {
          handleUpdateMessage(message.name, message.id)
        }}
        onDeleteHandler={() => onOpen({
          message: 'deleteMessage',
          resourceId: message.id
        })}
      />
    </Card>
  )
}
