import { BaseApi } from 'services'
import { AxiosResponse } from 'axios'
import { TransactionsServiceInterface } from './transactions-service.interface'
import { Transaction } from '../interfaces/transaction'

class TransactionsService extends BaseApi implements TransactionsServiceInterface {
  readonly endpoint = '/routes/payment/'

  public async getTransactions(): Promise<Transaction[]> {
    return await this.get<Transaction[]>(this.endpoint)
  }

  public async getTransaction(id: string): Promise<Transaction> {
    return await this.get<Transaction>(`${this.endpoint}${id}/`)
  }

  public transactionsTableExport = async ():Promise<AxiosResponse> => this.post(`${this.endpoint}export/`, {}, {
    responseType: 'blob'
  })
}

export default new TransactionsService()
