import { QueryClient } from '@tanstack/react-query'
import { Translation } from 'interfaces'
import { ActionFunctionArgs, redirect } from 'react-router-dom'
import { AxiosError } from 'axios'
import { errorToastMessage, successToastMessage } from '../../../utils'
import driversService from '../services/drivers-service'

export const driverAction = (queryClient: QueryClient, t: Translation) => async (
  { request, params }:ActionFunctionArgs
) => {
  const { method } = request
  const formData = await request.formData()

  const toastMessage = method === 'POST' ? 'hasBeenCreated' : 'hasBeenUpdated'

  // remove empty keys from formData
  const formDataEntries = Array.from(formData.entries())
  const filteredFormData = new FormData()
  console.log(formDataEntries)
  formDataEntries.forEach(([key, value]) => {
    if (value) {
      filteredFormData.append(key, value)
    }
  })

  try {
    if (method === 'POST') {
      await driversService.createDriver(filteredFormData)
    }
    if (method === 'PATCH') {
      await driversService.updateDriver(filteredFormData, params.id!)
      await queryClient.invalidateQueries(['driver', params.id!])
    }

    await queryClient.invalidateQueries(['drivers'])
    successToastMessage(`${formData.get('first_name')} ${formData.get('last_name')} ${t(toastMessage)}`)
    return redirect('..')
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}
export const deleteDriverAction = (
  queryClient: QueryClient,
  t: Translation,
  path: string
) => async (
  { request, params }:ActionFunctionArgs
) => {
  const formData = await request.formData()
  const id = formData.get('id') as string

  try {
    await driversService.deleteDriver(id)
    if (request.url.includes('employers')) {
      const paramsId = params.id as string
      await queryClient.invalidateQueries(['employer', paramsId])
      await queryClient.invalidateQueries(['drivers'])
      await queryClient.invalidateQueries(['deniedDrivers'])
    } else {
      await queryClient.invalidateQueries(['drivers'])
      await queryClient.invalidateQueries(['deniedDrivers'])
    }
    successToastMessage(t('driverHasBeenDeleted'))
    return { response: 'ok', path }
  } catch (error) {
    const axiosError = error as AxiosError
    errorToastMessage(t(axiosError.message))
    return null
  }
}
