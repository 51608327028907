import * as yup from 'yup'
import { passwordRegex } from '../../../helpers/password-regex'

export const validationSchema = {
  loginForm: yup.object({
    email: yup.string().email('invalidEmail').required('emailRequired'),
    password: yup.string().required('passwordRequired')
  }),
  forgotPasswordForm: yup.object({
    email: yup.string().email('invalidEmail').required('emailRequired')
  }),
  resetPasswordForm: yup.object({
    password: yup.string().trim().matches(passwordRegex, 'passwordRegex').required('passwordRequired'),
    code: yup.string().required('verificationCodeRequired')
  })
}
