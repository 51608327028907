import { HeaderGroup } from 'react-table'

interface Props {
  headerGroups: HeaderGroup<object>[]
}

export const TableHead = (props:Props) => {
  const { headerGroups } = props
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps()} className="text-left text-gray font-normal first:pl-[25px] last:pr-[25px] py-[25px]">
              {column.render('Header')}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  )
}
