import { Children } from 'interfaces'

interface Props extends Children {
  className?: string
}
export const Card = (props:Props) => {
  const { children, className } = props
  return (
    <div className={`rounded-[10px] bg-white shadow-card ${className}`}>
      {children}
    </div>
  )
}
