import { useQuery } from '@tanstack/react-query'
import { Driver, driversQuery } from 'features/drivers'

interface Args {
  initialData: Driver[]
}
export const useDrivers = ({ initialData }: Args) => {
  const { data: drivers } = useQuery({ ...driversQuery(), initialData })

  return { drivers }
}
