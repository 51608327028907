import { useNavigate } from 'react-router-dom'
import { routePaths } from 'routes'
import { BackButton, PrimaryButton, RenderIf } from 'components/ui'
import { useModalContext } from '../../../common'
import { isAdmin } from '../../../authentication/utils/user-utils'

interface Props {
  driverId: number
  driverUserId: number
}
export const SingleDriverActions = (props:Props) => {
  const { driverId, driverUserId } = props
  const navigate = useNavigate()

  const { onOpen } = useModalContext()

  const onDelete = () => {
    onOpen({
      message: 'deleteDriverMessage',
      resourceId: driverUserId
    })
  }
  return (
    <div className="grid sm:flex sm:items-center gap-3 sm:justify-between border-t-2 border-t-[#d7d7d7] mt-6 pt-6">
      <BackButton />
      <RenderIf isTrue={isAdmin()}>
        <div className="flex flex-col-reverse sm:flex-row sm:items-center gap-3">
          <PrimaryButton layout="outlined" text="delete" type="button" onClickHandler={onDelete} />
          <PrimaryButton
            onClickHandler={() => navigate(`${routePaths.drivers}/${routePaths.editDriver}/${driverId}`)}
            text="edit"
            type="button"
          />
        </div>
      </RenderIf>
    </div>
  )
}
