import { FormikState } from 'formik'
import { BackButton, PrimaryButton } from '../ui'

interface Props<T> {
  resetForm: (nextState?: (Partial<FormikState<T>> | undefined)) => void
  isLoading?: boolean
}
export const FormActions = <T extends unknown> (props: Props<T>) => {
  const { resetForm, isLoading = false } = props
  return (

    <div className="grid sm:flex sm:items-center gap-3 sm:justify-between border-t-2 border-t-[#d7d7d7] pt-6">
      <BackButton />
      <div className="flex flex-col-reverse sm:flex-row sm:items-center gap-3">
        <PrimaryButton layout="outlined" text="discard" type="button" onClickHandler={resetForm} />
        <PrimaryButton isLoading={isLoading} text="save" type="submit" />
      </div>
    </div>
  )
}
