import { Card, CardTitle, ContactInfo, UserProfileIcon } from 'components/ui'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MdEmail, MdHomeFilled, MdLocalPhone } from 'react-icons/md'
import { Driver } from '../../interfaces/driver'
import { DriverStatusIndicator } from '../ui/DriverStatusIndicator'

interface Props {
  driver: Driver
}
export const DriverApplicationPersonalDetails = (props:Props) => {
  const { driver } = props
  const { t } = useTranslation()
  const fullName = `${driver.user.first_name} ${driver.user.last_name}`
  return (
    <Card className="p-6">
      <div className="flex items-center justify-between flex-wrap gap-2">
        <div className="capitalize flex items-baseline gap-4 flex-wrap">
          <CardTitle title={fullName} />
          <p className="capitalize text-lg">{driver.father_name ?? ''}</p>
          {driver.is_accepted ? <DriverStatusIndicator status={driver.status} /> : null}
        </div>

        <p className="text-lg text-gray font-['Space_Grotesk']">{`${t('addedOn')} ${moment(driver.user.date_joined).format('MMM, D')}`}</p>
      </div>
      <div className="flex items-center justify-between mt-5 gap-2 flex-wrap-reverse">
        <div className="flex gap-2 md:gap-5 lg:gap-20 flex-wrap">
          <ContactInfo icon={MdLocalPhone} label="telephone" contact={driver.user.phone_number} type="tel" />
          <ContactInfo icon={MdEmail} label="email" contact={driver.user.email} type="mailto" />
          <ContactInfo icon={MdHomeFilled} label="address" contact={driver.address ?? '-'} type="address" />
        </div>
        <UserProfileIcon src={driver.image} />
      </div>
    </Card>
  )
}
