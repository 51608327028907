import { Children } from 'interfaces'
import { useCallback, useContext, useMemo, useReducer } from 'react'
import { modalCtx } from './modal-ctx'
import { modalReducer } from './modal-ctx.reducer'
import { InitialState, ModalCtx, OnOpen } from './modal-ctx.interface'

interface Props extends Children {}

const initialState: InitialState = {
  showModal: false,
  message: '',
  resourceId: null,
  userType: null,
  callback: () => {}
}

export const useModalContext = (): ModalCtx => useContext(modalCtx)
export const ModalProvider = (props:Props) => {
  const { children } = props
  const [state, dispatch] = useReducer(modalReducer, initialState)

  const onOpen = useCallback(({ resourceId, message, userType, callback }: OnOpen) => {
    dispatch({ type: 'OPEN_MODAL', payload: { message, resourceId, userType, callback } })
  }, [])

  const onClose = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' })
  }, [])

  const ctx = useMemo(() => ({
    showModal: state.showModal,
    message: state.message,
    resourceId: state.resourceId,
    userType: state.userType,
    callback: state.callback,
    onOpen,
    onClose
  }), [
    state.showModal,
    state.message,
    state.resourceId,
    state.userType,
    state.callback,
    onOpen,
    onClose
  ])

  return <modalCtx.Provider value={ctx}>{children}</modalCtx.Provider>
}
