import { useTranslation } from 'react-i18next'
import { IoClose, IoWarningOutline } from 'react-icons/io5'
import { useActionData, useNavigate, useNavigation, useSubmit } from 'react-router-dom'
import { useEffect } from 'react'
import { useModalContext } from '../../context/modal-ctx/ModalProvider'
import { Modal } from './Modal'
import { LoadingSpinner } from '../../../../components/ui'
import { UserType } from '../../../../interfaces'

export const ConfirmationModal = () => {
  const { showModal, callback, onClose, message, resourceId, userType } = useModalContext()
  const { t } = useTranslation()
  const actionData = useActionData() as { response: string, path?: string }
  const submit = useSubmit()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const isSubmitting = navigation.state === 'submitting'

  // Close modal if the response from action is ok
  useEffect(() => {
    if (actionData?.response === 'ok') {
      if (actionData?.path) {
        navigate(actionData.path)
      }
      onClose()
    }
  }, [actionData, onClose, navigate])

  const onSubmit = () => {
    const submitData: { id: string, userType?: UserType } = {
      id: resourceId!.toString()
    }
    if (userType) {
      submitData.userType = userType
    }
    submit(submitData, { method: 'delete' })
  }

  const buttonClasses = 'py-3 w-full rounded-[10px] hover:opacity-80 transition-all duration-300'

  return (
    <Modal showModal={showModal} onClose={onClose}>
      <div className="flex flex-col items-center bg-white shadow-md rounded-xl text-center p-7 ">
        <IoClose onClick={onClose} className="mb-3 ml-auto hover:text-dark-red transition-colors duration-300" size={25} cursor="pointer" />
        <IoWarningOutline size={60} className="text-dark-red" />
        <p className="text-lg my-5">{t(message)}</p>
        <div className="flex gap-3 w-full">
          <button disabled={isSubmitting} className={`bg-light-gray ${buttonClasses}`} onClick={onClose} type="button">{t('noCancel')}</button>
          <button disabled={isSubmitting} className={`bg-dark-red text-white ${buttonClasses}`} onClick={callback ?? onSubmit} type="button">
            {isSubmitting ? <LoadingSpinner width="w-5" height="h-5" /> : t('yesImSure')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
