import { BaseApi } from 'services'
import { AxiosResponse } from 'axios'
import { Ride } from 'features/rides'
import { Transaction } from 'features/transactions'
import { DriversServiceInterface } from './drivers-service.interface'
import { ApplicationStatusTypes, Driver, DriverDocumentFormData } from '../interfaces/driver'
import { formikFilesKeys } from '../components/driver-form/DriversForm'
import { RatingHistory } from '../interfaces/rating-history'

class DriversService extends BaseApi implements DriversServiceInterface {
  readonly endpoint = '/auth/driver/'

  public async getDrivers(): Promise<Driver[]> {
    return await this.get<Driver[]>(this.endpoint)
  }

  public async getDeniedDrivers(): Promise<Driver[]> {
    return await this.get<Driver[]>(`${this.endpoint}denied-drivers/`)
  }

  public async getDriver(id: string): Promise<Driver> {
    const response = await this.get<any>(`${this.endpoint}${id}/`)
    return response.driver as Driver
  }

  public async getUncheckedDrivers(): Promise<Driver[]> {
    return await this.get<Driver[]>(`${this.endpoint}get-unchecked-drivers/`)
  }

  public async createDriver(formData:FormData): Promise<AxiosResponse> {
    // Delete image if it's not present
    if (!formData.get('image')) {
      formData.delete('image')
    }

    return await this.post(`${this.endpoint}create-by-admin/`, formData)
  }

  public async updateDriver(formData:FormData, id:string): Promise<AxiosResponse> {
    formData.delete('password')
    formData.delete('email')
    formikFilesKeys.forEach((key) => {
      if (!(formData.get(key) instanceof File)) formData.delete(key)
    })
    if (!(formData.get('image') instanceof File)) formData.delete('image')

    return await this.patch(`${this.endpoint}${id}/`, formData)
  }

  public async uploadDriverImage(id: number, image: File): Promise<AxiosResponse> {
    const formData = new FormData()
    formData.append('image', image)

    return this.patch(`${this.endpoint}${id}/upload-image/`, formData)
  }

  public async uploadDriverDocuments(id:number, { documentType, documentFile, documentNumber }
  :DriverDocumentFormData): Promise<AxiosResponse> {
    const formData = new FormData()
    formData.append('document_type', documentType)
    formData.append('document', documentFile)
    formData.append('number', documentNumber)

    return this.patch(`${this.endpoint}${id}/upload-document/`, formData)
  }

  public async deleteDriver(id: string):Promise<AxiosResponse> {
    return await this.delete(`/auth/user/${id}/`)
  }

  public blockDriver = async (id: number):Promise<AxiosResponse> => await this.get<AxiosResponse>(`${this.endpoint}${id}/block-unblock-driver/`)

  public acceptDenyDriver = async ({ id, status }: {
    id: number
    status: ApplicationStatusTypes
  }):Promise<AxiosResponse> => this.patch(`${this.endpoint}${id}/admin-check/`, {
    status
  })

  public async getRatingHistory(id: string):Promise<RatingHistory[]> {
    return this.get<RatingHistory[]>(`${this.endpoint}${id}/get-ratings/`)
  }

  public async getDriverRideHistory(id: string):Promise<Ride[]> {
    return this.get<Ride[]>(`${this.endpoint}${id}/get-routes/`)
  }

  public async getDriverTransactionsHistory(id: string):Promise<Transaction[]> {
    return this.get<Transaction[]>(`${this.endpoint}${id}/get-payments/`)
  }

  public driverTableExport = async (driversIds?: number[]):Promise<AxiosResponse> => {
    const data = driversIds ? { driver_ids: driversIds } : {}
    return this.post(`${this.endpoint}export/`, data, {
      responseType: 'blob'
    })
  }
}

export default new DriversService()
