import { useTranslation } from 'react-i18next'
import { PrimaryButton } from './PrimaryButton'
import { RenderIf } from './RenderIf'

interface Props {
  status: number
  statusText: string
  buttonText: string
  errorMessage: string
  errorMessage2?: string
  onClickHandler: () => void
}
export const ErrorUi = (props:Props) => {
  const { status, statusText, buttonText, errorMessage, errorMessage2, onClickHandler } = props
  const { t } = useTranslation()

  return (
    <div className="p-5 h-[100svh] gap-5 flex flex-col text-center items-center justify-center">
      <h1 className="text-3xl sm:text-4xl">
        {status}
        {' '}
        -
        {' '}
        {statusText}
      </h1>
      <p>{t(errorMessage)}</p>
      <RenderIf isTrue={!!errorMessage2}>
        <p>
          {t(errorMessage2!)}
        </p>
      </RenderIf>

      <PrimaryButton text={buttonText} type="button" size="lg" onClickHandler={onClickHandler} />
    </div>
  )
}

export default ErrorUi
