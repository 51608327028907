import { Card, DetailsKeyValue } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { Driver } from '../../interfaces/driver'

interface Props {
  driver: Driver;
}
export const DriverApplicationCompanyDetails = (props:Props) => {
  const { driver } = props
  const { t } = useTranslation()
  return (
    <Card className="grid md:grid-cols-2 gap-6 p-6 font-bold">
      <div className="md:border-r-2 md:border-r-light-gray">
        <h3 className="text-xl text-black">{t('companyInfo')}</h3>
        <div className="grid gap-y-3 mt-4">
          <DetailsKeyValue label="companyName" value={driver.company_name} />
          <DetailsKeyValue label="type" value={driver.company_type} />
          <DetailsKeyValue label="address" value={driver.company_address} />
          <DetailsKeyValue label="taxId" value={driver.company_tax_id} />
        </div>
      </div>
      <div>
        <h3 className="text-xl text-black">{t('paymentInfo')}</h3>
        <div className="grid gap-y-3 mt-4">
          <DetailsKeyValue label="iban" value={driver.iban} />
          <DetailsKeyValue label="beneficiaryName" value={driver.iban_name} />
          <DetailsKeyValue label="BIC" value={driver.bic} />
          {driver.iban_file ? (
            <a
              className="text-sm underline text-dark-gray font-normal"
              href={driver.iban_file ?? undefined}
              target="_blank"
              rel="noreferrer"
            >
              {t('bankAccountFile')}
            </a>
          ) : <small className="text-dark-gray font-normal">{t('noBankAccountFileFound')}</small>}
        </div>
      </div>
    </Card>
  )
}
