import { Await, useLoaderData, useParams } from 'react-router-dom'
import { Suspense } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Ride } from '../../interfaces/ride'
import { LocalApiError } from '../../../../components/ui'
import { rideQuery } from '../../helpers/queries'
import { DisplayRide } from './DisplayRide'
import { SingleRideSkeleton } from '../../../../components/skeletons'

export const RideLoader = () => {
  const { ride } = useLoaderData() as { ride: Ride }
  const params = useParams()
  const { data } = useQuery({
    ...rideQuery(params.id!),
    enabled: !!params.id,
    initialData: ride
  })

  return (
    <Suspense fallback={<SingleRideSkeleton />}>
      <Await resolve={ride} errorElement={<LocalApiError message="failedToLoadRide" />}>
        <DisplayRide ride={data} />
      </Await>
    </Suspense>
  )
}
