import moment from 'moment'
import { boolean } from 'yup'

export const calculateRefund = (
  paymentTime: string | null,
  cancelledTime:string | null,
  isScheduled: boolean,
  isCancelledByDriver: boolean
) => {
  if (!paymentTime) return 'Not Paid'
  if (isCancelledByDriver) return 'Full Refund'
  const timeSincePayment = moment(cancelledTime).diff(moment(paymentTime))

  if (isScheduled) {
    if (moment.duration(timeSincePayment).asMinutes() < 10) {
      return 'Full Refund'
    } if (moment.duration(timeSincePayment).asMinutes() < 60) {
      return '50%'
    }
    return 'Not Refundable'
  }
  if (moment.duration(timeSincePayment).asMinutes() < 5) {
    return '70% Refund'
  } if (moment.duration(timeSincePayment).asMinutes() < 10) {
    return '50% Refund'
  }
  return 'Not Refundable'
}
