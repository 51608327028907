import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { OriginalRow, RowValue } from 'interfaces'
import { ReactTable } from 'components/table'
import moment from 'moment'
import { StarRating } from 'components/ui'
import { MessagesModal, useModalContext } from 'features/common'
import { AxiosResponse } from 'axios'
import { Ride, RideStatus } from '../../interfaces/ride'
import { RideStatusColor, RideStatusTextColor } from '../../utils/ride-status-color'

interface Props {
  rides: Ride[]
  path?: string
  perPage?: number
  isScheduledTable?: boolean
  exportAction?: () => Promise<AxiosResponse>
}
export const RidesTable = (props:Props) => {
  const { rides = [], exportAction, path, perPage = 7, isScheduledTable = false } = props
  const { onOpen } = useModalContext()
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      {
        Header: t('id'),
        accessor: 'id'
      },
      {
        Header: t('dateTime'),
        accessor: 'created',
        Cell: ({ value }: RowValue<number>) => (
          <span>
            {moment(value).format('MMM. DD, HH:mm')}
          </span>
        )
      },
      {
        Header: isScheduledTable ? t('scheduledDateTime') : ' ',
        accessor: 'scheduled_datetime',
        Cell: ({ value }: RowValue<number>) => (isScheduledTable ? (
          <span>
            {moment(value).format('MMM. DD, HH:mm')}
          </span>
        ) : null)
      },
      {
        Header: t('driver'),
        accessor: 'driver.user.first_name',
        Cell: ({ row: { original } }: OriginalRow<Ride>) => (
          <span className="pr-5">
            {`${original.driver?.user?.first_name ?? '-'} ${original.driver?.user?.last_name ?? '-'}`}
          </span>
        )
      },
      {
        Header: t('client'),
        accessor: 'client.user.first_name',
        Cell: ({ row: { original } }: OriginalRow<Ride>) => (
          <span>
            {`${original.client.user?.first_name ?? '-'} ${original.client.user?.last_name ?? '-'}`}
          </span>
        )
      },
      {
        Header: t('rating'),
        accessor: 'driver_rating',
        Cell: ({ value }: RowValue<null | number>) => (
          <StarRating rating={value} />
        )
      },
      {
        Header: t('price'),
        accessor: 'price',
        Cell: ({ value }: RowValue<string>) => (
          <span className="pr-5">
            &euro;
            {value}
          </span>
        )
      },
      {
        Header: t('communication'),
        accessor: 'message_count',
        Cell: ({ row: { original } }: OriginalRow<Ride>) => (
          <button
            disabled={original.message_count === 0}
            onClick={(event) => {
              event.stopPropagation()
              onOpen({ resourceId: original.id, message: '' })
            }}
            type="button"
            className="disabled:cursor-auto hover:opacity-70 transition-opacity duration-300 cursor-pointer"
          >
            {original.message_count}
            {' '}
            {t('messages')}
          </button>
        )
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }: RowValue<RideStatus>) => (
          <div className="flex items-center gap-3">
            <div className={`rounded-full h-[18px] w-[18px] ${RideStatusColor(value)}`} />
            <p className={RideStatusTextColor(value)}>
              {t(value)}
            </p>
          </div>
        )
      }
    ],
    [t, onOpen, isScheduledTable]

  )
  return (
    <>
      <ReactTable
        columns={columns}
        data={rides}
        path={path}
        perPage={perPage}
        exportAction={exportAction}
      />
      <MessagesModal />
    </>

  )
}
