import { useNavigate } from 'react-router-dom'
import { routePaths } from 'routes'
import { BackButton, PrimaryButton } from 'components/ui'
import { useModalContext } from '../../../common'

interface Props {
  employerId: number
  employerUserId: number
}
export const SingleEmployerActions = (props:Props) => {
  const { employerId, employerUserId } = props
  const navigate = useNavigate()
  const { onOpen } = useModalContext()

  const onDelete = () => {
    onOpen({
      message: 'deleteEmployerMessage',
      resourceId: employerUserId
    })
  }
  return (
    <div className="grid sm:flex sm:items-center gap-3 sm:justify-between border-t-2 border-t-[#d7d7d7] mt-6 pt-6">
      <BackButton />
      <div className="flex flex-col-reverse sm:flex-row sm:items-center gap-3">
        <PrimaryButton layout="outlined" text="delete" type="button" onClickHandler={onDelete} />
        <PrimaryButton
          onClickHandler={() => navigate(`${routePaths.employers}/${routePaths.editEmployer}/${employerId}`)}
          text="edit"
          type="button"
        />
      </div>
    </div>
  )
}
