import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import { MdEmail, MdHomeFilled, MdLocalPhone } from 'react-icons/md'
import { Card, CardTitle, ContactInfo, UserProfileIcon } from '../../../../components/ui'
import { Employer } from '../../interfaces/employer'

interface Props {
  employer: Employer
}
export const EmployerPersonalDetails = (props:Props) => {
  const { employer } = props
  const { t } = useTranslation()
  const fullName = `${employer.user.first_name} ${employer.user.last_name}`
  return (
    <Card className="p-6">
      <div className="flex capitalize items-center justify-between flex-wrap gap-2">
        <CardTitle title={fullName} />

        <p className="text-lg text-gray font-['Space_Grotesk']">{`${t('addedOn')} ${moment(employer.user.date_joined).format('MMM, D')}`}</p>
      </div>
      <div className="flex items-center justify-between mt-5 gap-2 flex-wrap-reverse">
        <div className="flex gap-2 md:gap-5 lg:gap-20 flex-wrap">
          <ContactInfo icon={MdLocalPhone} label="telephone" contact={employer.user.phone_number} type="tel" />
          <ContactInfo icon={MdEmail} label="email" contact={employer.user.email} type="mailto" />
          <ContactInfo icon={MdHomeFilled} label="address" contact={employer.address ?? '-'} type="address" />
        </div>
        <UserProfileIcon src={employer.photo} />
      </div>
    </Card>
  )
}
