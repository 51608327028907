import { PrimaryButton } from 'components/ui'
import { BackButton } from 'components/ui/BackButton'
import { useMutateData } from 'hooks'
import { useNavigate } from 'react-router-dom'
import driversService from '../../services/drivers-service'
import { ApplicationStatusTypes } from '../../interfaces/driver'

interface Props {
  driverId: number
}
export const DriverApplicationActions = (props:Props) => {
  const { driverId } = props
  const navigate = useNavigate()
  const { mutate, isLoading } = useMutateData({
    key: ['uncheckedDrivers', 'drivers'],
    mutationFn: driversService.acceptDenyDriver,
    successMessage: 'applicationReviewHasBeenSubmitted'
  })

  const applicationResultHandler = (status:ApplicationStatusTypes) => {
    mutate({ id: driverId, status }, {
      onSuccess: () => {
        navigate(-1)
      }
    })
  }

  return (
    <div className="grid sm:flex sm:items-center gap-3 sm:justify-between border-t-2 border-t-[#d7d7d7] pt-6">
      <BackButton />
      <div className="flex flex-col-reverse sm:flex-row sm:items-center gap-3">
        <PrimaryButton
          onClickHandler={() => applicationResultHandler('DENY')}
          layout="destructive"
          text="deny"
          type="button"
          isLoading={isLoading}
        />
        <PrimaryButton
          onClickHandler={() => applicationResultHandler('ACCEPT')}
          text="accept"
          type="button"
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}
