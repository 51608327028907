import { FiCreditCard } from 'react-icons/fi'

interface Props {
  cardType: string | null
  paymentMethod: string | null
}
export const TransactionPaymentMethod = (props: Props) => {
  const { cardType, paymentMethod } = props

  return (
    <div className="text-dark-gray text-sm flex items-center gap-1.5">
      <FiCreditCard size={16} />
      <p>{paymentMethod ?? '-'}</p>
      <p>{cardType ?? '-'}</p>
    </div>
  )
}
