import { Children } from 'interfaces'
import { useTranslation } from 'react-i18next'
import noImage from 'assets/images/no-image.png'
import fileThumbnail from 'assets/images/file-thumbnail.jpeg'

interface Props extends Children {
  title: string
  driverFileUrl: string | null
}
export const DriverFileInfo = (props:Props) => {
  const { title, driverFileUrl, children } = props
  const { t } = useTranslation()
  return (
    <div className="lg:gap-0 px-6 text-gray lg:border-r-2 lg:border-r-light-gray last:md:border-r-0">
      <h2 title={t<string>(title)} className="line-clamp-1 text-lg 2xl:text-xl font-bold whitespace-nowrap lg:whitespace-normal">{t(title)}</h2>
      <div className="w-full aspect-[3/2] my-6">
        <a href={driverFileUrl ?? undefined} target="_blank" rel="noreferrer">
          <img src={driverFileUrl ? fileThumbnail : noImage} alt="" loading="lazy" className="object-cover w-full h-full" />
        </a>
      </div>
      <div className="grid gap-5">{children}</div>
    </div>
  )
}
