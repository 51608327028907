import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { TableSkeleton } from 'components/skeletons'
import { LocalApiError } from 'components/ui'
import { useQuery } from '@tanstack/react-query'
import { Ride } from '../../interfaces/ride'
import { RidesTable } from './RidesTable'
import { scheduledRidesQuery } from '../../helpers/queries'
import { ridesService } from '../../index'

export const ScheduledRidesLoader = () => {
  const { scheduledRides } = useLoaderData() as { scheduledRides: Ride[] }
  const { data } = useQuery({
    ...scheduledRidesQuery(),
    initialData: scheduledRides
  })

  return (
    <Suspense fallback={<TableSkeleton />}>
      <Await resolve={scheduledRides} errorElement={<LocalApiError message="failedToLoadRides" />}>
        <RidesTable
          rides={data}
          exportAction={() => ridesService.ridesTableExport('scheduled')}
          isScheduledTable
        />
      </Await>
    </Suspense>
  )
}
