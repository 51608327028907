import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useWidth } from 'hooks'
import { RenderIf } from '../../../components/ui'

interface Props {
  icon?: JSX.Element
  activeIcon?: JSX.Element
  path: string
  label: string
}
export const NavigationLink = (props:Props) => {
  const { icon, activeIcon, path, label } = props
  const { width } = useWidth()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isActiveIcon = pathname === path || (path !== '/' && pathname.includes(path))
  return (
    <NavLink
      to={path}
      className={({ isActive }) => `${isActive ? 'font-bold' : 'font-normal'} flex items-center gap-5`}
    >
      <RenderIf isTrue={!!width && width >= 768}>
        {isActiveIcon ? activeIcon : icon}
      </RenderIf>
      <span className="md:hidden 2xl:inline">
        {t(label)}
      </span>
    </NavLink>
  )
}
