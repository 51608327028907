import { useTranslation } from 'react-i18next'
import { FormGroup } from '../../../../components/form'
import { Formik } from '../../../../interfaces'
import { EmployerFormValues } from '../../interfaces/employer-form'

interface Props extends Formik<EmployerFormValues> {}
export const EmployerCompanyFields = (props:Props) => {
  const { formik } = props
  const { t } = useTranslation()
  return (
    <div>
      <h2 className="text-xl mb-5">{t('companyInfo')}</h2>
      <div className="driver-form-child-container">
        <FormGroup
          label="companyName"
          placeholder={t<string>('companyName')}
          hasError={!!(formik.touched.company_name && formik.errors.company_name)}
          errorText={formik.errors.company_name}
          {...formik.getFieldProps('company_name')}
        />
        <FormGroup
          label="companyTaxId"
          placeholder={t<string>('companyTaxId')}
          hasError={!!(formik.touched.company_tax_id && formik.errors.company_tax_id)}
          errorText={formik.errors.company_tax_id}
          {...formik.getFieldProps('company_tax_id')}
        />
        <FormGroup
          label="taxDepartment"
          placeholder={t<string>('taxDepartment')}
          hasError={!!(formik.touched.company_tax_department
              && formik.errors.company_tax_department)}
          errorText={formik.errors.company_tax_department}
          {...formik.getFieldProps('company_tax_department')}
        />
        <FormGroup
          label="companyProfession"
          placeholder={t<string>('companyProfession')}
          hasError={!!(formik.touched.company_profession && formik.errors.company_profession)}
          errorText={formik.errors.company_profession}
          {...formik.getFieldProps('company_profession')}
        />
        <FormGroup
          label="companyAddress"
          placeholder={t<string>('companyAddress')}
          hasError={!!(formik.touched.company_address && formik.errors.company_address)}
          errorText={formik.errors.company_address}
          {...formik.getFieldProps('company_address')}
        />
        <FormGroup
          label="companyExtraInfo"
          placeholder={t<string>('companyExtraInfo')}
          hasError={!!(formik.touched.company_extra_info && formik.errors.company_extra_info)}
          errorText={formik.errors.company_extra_info}
          {...formik.getFieldProps('company_extra_info')}
        />
      </div>
    </div>
  )
}
