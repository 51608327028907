import { useTranslation } from 'react-i18next'
import { TbMessageCircle2 } from 'react-icons/tb'
import { useModalContext } from 'features/common'

interface Props {
  routeId: number
  driversFullName: string
  clientFullName: string
  passengers: number
  luggage: number
  messageCount: number
}

export const RideDetails = (props: Props) => {
  const { routeId, driversFullName, clientFullName, passengers, luggage, messageCount } = props
  const { onOpen } = useModalContext()
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-5 lg:px-10 w-full">
      <div className="flex gap-2">
        <p>{`${t('driversName')}:`}</p>
        <p className="capitalize font-bold">{driversFullName}</p>
      </div>
      <div className="flex gap-2">
        <p>{`${t('clientsName')}:`}</p>
        <p className="capitalize font-bold">{clientFullName}</p>
      </div>
      <div className="text-sm flex">
        <p className="text-dark-gray border-r-2 pr-2 mr-2">
          <span className="text-base font-bold">{passengers}</span>
          {' '}
          {t('passengers')}
        </p>
        <p className="text-dark-gray">
          <span className="text-base font-bold">{luggage}</span>
          {' '}
          {t('luggagePcs')}
        </p>
      </div>
      <button
        disabled={messageCount === 0}
        onClick={() => onOpen({ resourceId: routeId, message: 'message' })}
        type="button"
        className="text-dark-gray w-max flex gap-1 items-center hover:opacity-80 transition-opacity duration-300"
      >
        <TbMessageCircle2 />
        <span>{messageCount}</span>
        <span>{t('messages')}</span>
      </button>
    </div>
  )
}
