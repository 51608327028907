import { Await, useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { LocalApiError } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { DisplayNewRidesSkeleton } from 'components/skeletons'
import { Ride, useRides } from 'features/rides'
import { DisplayNewRides } from './DisplayNewRides'

export const NewRidesLoader = () => {
  const { rides } = useLoaderData() as { rides: Ride[] }
  const { rides: data } = useRides({ initialData: rides })
  const { t } = useTranslation()

  return (
    <div>
      <h2 className="mb-6">{t('latestRides')}</h2>
      <Suspense fallback={<DisplayNewRidesSkeleton />}>
        <Await resolve={rides} errorElement={<LocalApiError message="failedToLoadRides" />}>
          <DisplayNewRides rides={data} />
        </Await>
      </Suspense>
    </div>
  )
}
