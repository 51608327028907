import * as yup from 'yup'
import { passwordRegex } from '../../../helpers/password-regex'

export const validationSchema = {
  employer: yup.object({
    // Personal info
    first_name: yup.string().trim().required('firstNameRequired'),
    last_name: yup.string().trim().required('lastNameRequired'),
    email: yup.string().trim().email('invalidEmail').required('emailRequired'),
    phone_number: yup.string().trim().required('phoneRequired'),
    password: yup.string().trim().matches(passwordRegex, 'passwordRegex').required('passwordRequired')
  })
}
