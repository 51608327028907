import { Await, useLoaderData } from 'react-router-dom'
import { Client, ClientsTable } from 'features/clients'
import { Suspense } from 'react'
import { LocalApiError } from 'components/ui'
import { TableSkeleton } from 'components/skeletons'
import { ModalProvider } from 'features/common'
import { useClients } from '../../../hooks'

export const ClientsLoader = () => {
  const { clients } = useLoaderData() as { clients: Client[] }
  const { data } = useClients(clients)

  return (
    <ModalProvider>
      <Suspense fallback={<TableSkeleton />}>
        <Await resolve={clients} errorElement={<LocalApiError message="failedToLoadClients" />}>
          <ClientsTable clients={data!} />
        </Await>
      </Suspense>
    </ModalProvider>
  )
}
