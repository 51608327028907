import { BsUpload } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { Formik } from 'interfaces'
import { toast } from 'react-toastify'
import { ChangeEvent } from 'react'
import { IoClose } from 'react-icons/io5'
import ErrorText from '../../../../components/ui/ErrorText'
import { RenderIf } from '../../../../components/ui'

interface Props<T> extends Formik<T> {
  label: string
  formikKey: string
  acceptedFormats: string
  supportedFilesText: string
}
export const UploadDocumentButton = <T extends unknown> (props:Props<T>) => {
  const { formik, label, formikKey, acceptedFormats, supportedFilesText } = props
  const { t } = useTranslation()

  const removeFileHandler = () => {
    formik.setFieldValue(formikKey, '')
  }
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files?.[0]
      // File size validation in bytes
      if (file?.size > 1000000) {
        toast.error<string>(t('fileSizeError'))
        return // stop execution if file size is greater than 1MB
      }
      formik.setFieldValue(formikKey, event.currentTarget.files?.[0])
    }
  }

  return (
    <div className="grid gap-2">
      <div className="relative grid grid-cols-1">
        <label htmlFor={formikKey} className="bg-[#EEEEEE] cursor-pointer py-[10px] min-w-max w-max px-3 text-dark-gray flex items-center gap-2 text-sm rounded-[10px] shadow-card hover:opacity-80 transition-opacity duration-300">
          <BsUpload size={20} />
          {t(label)}
          <input
            onClick={(event) => {
              // Reset file list
              const target = event.target as HTMLInputElement
              target.value = ''
            }}
            onChange={handleFileUpload}
            id={formikKey}
            type="file"
            accept={acceptedFormats}
            className="hidden"
          />
        </label>

        <div className="relative top-6">
          <ErrorText text={formik.errors[formikKey]!} errorSpace="auto" />
        </div>
        <RenderIf isTrue={!!formik.values[formikKey]}>
          <div className="flex items-center gap-1 mt-2 text-xs text-[#96989A]">
            {/* @ts-ignore */}
            <p>{ formik.values[formikKey] instanceof File ? formik.values[formikKey]?.name : formik.values[formikKey].split('/').at(-1)}</p>
            <IoClose
              onClick={removeFileHandler}
              size={16}
              className="cursor-pointer hover:opacity-80 transition-opacity duration-300"
            />
          </div>
        </RenderIf>
      </div>
      <p className="flex-col text-[10px] text-[#96989A]">
        { t(supportedFilesText) }
      </p>
    </div>
  )
}
