import { Await, useLoaderData } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Card, LocalApiError } from 'components/ui'
import { Suspense } from 'react'
import { TableSkeleton } from 'components/skeletons'
import { useTranslation } from 'react-i18next'
import { Driver } from '../../../interfaces/driver'
import { Ride, RidesTable } from '../../../../rides'
import { driverRideHistoryQuery } from '../../../helpers/queries'
import { routePaths } from '../../../../../routes'

interface Props {
  driver: Driver
}
export const DriverRideHistoryLoader = (props:Props) => {
  const { driver } = props
  const { t } = useTranslation()
  const { rideHistory } = useLoaderData() as { rideHistory: Ride[] }
  const { data } = useQuery({
    ...driverRideHistoryQuery(driver.id.toString()),
    initialData: rideHistory
  })

  return (
    <Card className="p-7">
      <h3 className="text-xl font-semibold text-gray mb-6">{t('rideHistory')}</h3>
      <Suspense fallback={<TableSkeleton />}>
        <Await resolve={rideHistory} errorElement={<LocalApiError message="failedToLoadRides" />}>
          <RidesTable rides={data} path={routePaths.rides} />
        </Await>
      </Suspense>
    </Card>
  )
}
