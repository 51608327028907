import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { routePaths } from '../../../../routes'

interface Props {
  rideId: number
  clientFullName: string
  clientsEmail: string | null
}
export const TransactionRideInfo = (props:Props) => {
  const { rideId, clientFullName, clientsEmail } = props
  const { t } = useTranslation()
  return (
    <div className="md:pl-10 flex flex-col gap-7">
      <div>
        <h3 className="text-[20px] font-bold">{t('rideInfo')}</h3>
        <Link className="text-sm underline text-dark-gray" to={`${routePaths.rides}/${rideId}`}>{`${t('rideId')} #${rideId}`}</Link>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-1 flex-wrap">
          <p>{`${t('clientsName')}:`}</p>
          <p className="capitalize font-bold">{clientFullName}</p>
        </div>
        <div className="flex gap-1 flex-wrap">
          <p>{`${t('clientsEmail')}:`}</p>
          <a href={clientsEmail ? `mailto:${clientsEmail}` : undefined} className="lowercase font-bold">
            {clientsEmail ?? '-'}
          </a>
        </div>
      </div>

    </div>
  )
}
